import './styles.less';

import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
  message,
} from 'antd';
import { District, School, User } from 'screener19-core';
import React, { useEffect, useState } from 'react';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import DistrictsApi from 'screener19-core/dist/api/districts';
import RolesDropdownSelect from 'components/RolesDropdownSelect';
import axiosInstance from 'util/api-util';
import useAsyncEffect from 'use-async-effect';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props {
  districts?: Array<District>;
  onCancel: () => void;
  onFinish: (values: any) => void;
  visible: boolean;
  loggedInUser?: User;
  user?: User;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const UserEditModal = ({
  districts,
  onFinish,
  onCancel,
  loggedInUser,
  user,
  visible,
}: Props) => {
  const [form] = Form.useForm();
  const [loadingSchools, setLoadingSchools] = useState(false);
  const [schools, setSchools] = useState<Array<any>>([]);
  const [districtId, setDistrictId] = useState<string>();
  const [isTechAdmin, setIsTechAdmin] = useState<boolean>(false);
  const [schoolsDropdownOpen, setSchoolsDropdownOpen] = useState(false);

  useEffect(() => {
    if (visible && user && user.role) {
      if (user.district) {
        setDistrictId(user.district._id);
      }

      form.setFieldsValue({
        role: user.role,
        schools: user.schools.map((s: any) => (s && s._id ? s._id : null)),
        district: user.district ? user.district._id : null,
        isTechAdmin: user.isTechAdmin,
      });
    }
  }, [visible]);

  useAsyncEffect(async () => {
    if (!districtId) return;
    const api = new DistrictsApi(axiosInstance);
    try {
      setLoadingSchools(true);
      const district = await api.getOne(districtId);

      if (district && district.schools) {
        setSchools(district.schools);

        setSchoolsDropdownOpen(true);
      }
    } catch (error) {
      message.error('Error loading schools');
    } finally {
      setLoadingSchools(false);
    }
  }, [districtId]);

  const validateMessages = {
    required: '${label} is required!',
  };

  return (
    <Modal
      title={'Edit User'}
      onCancel={onCancel}
      footer={null}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        {...layout}
        preserve={false}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <RolesDropdownSelect
          includeAdmin={loggedInUser?.permissions?.canViewAdmin}
        />

        {loggedInUser?.permissions?.canViewAdmin && (
          <Form.Item
            name="district"
            label={'District'}
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: 200 }}
              placeholder="Select district"
              onChange={(value: string) => {
                setDistrictId(value);
              }}
            >
              {districts?.map((district: District) => {
                return district ? (
                  <Option value={district._id}>{district.name}</Option>
                ) : null;
              })}
            </Select>
          </Form.Item>
        )}

        {!loggedInUser?.permissions?.canViewAdmin && (
          <Form.Item name="district" rules={[{ required: true }]} noStyle>
            <Input type="hidden" value={user?.district._id} />
          </Form.Item>
        )}

        <Form.Item
          name="schools"
          label={'Schools'}
          rules={[{ required: false }]}
        >
          <Select
            mode="multiple"
            style={{ width: 200 }}
            placeholder="Select schools"
            loading={loadingSchools}
            open={schoolsDropdownOpen}
            onDropdownVisibleChange={(open: boolean) => {
              setSchoolsDropdownOpen(open);
            }}
            dropdownMatchSelectWidth={false}
          >
            {schools?.map((school: School) => {
              return <Option value={school._id}>{school.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="isTechAdmin"
          label="Tech Admin"
          valuePropName="checked"
          initialValue={user?.isTechAdmin}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserEditModal;
