import './styles.css';

import * as H from 'history';
import {
  Route,
  RouteChildrenProps,
  RouteComponentProps,
} from 'react-router-dom';

import { Alert } from 'antd';
import Home from 'components/Home';
import { constants } from 'conf/constants';

interface Props {
  auth?: boolean;
  location?: H.Location;
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  children?:
    | ((props: RouteChildrenProps<any>) => React.ReactNode)
    | React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

const LoggedInRoute = ({ children, auth, path, ...rest }: Props) => {
  if (auth === true) {
    return (
      <Route path={path} {...rest}>
        {children}
      </Route>
    );
  }

  const alert = (
    <Alert
      banner
      message="You must be logged in to access this page"
      description=" "
      type="info"
      showIcon
    />
  );

  // set redirect url for post-login
  window.localStorage.setItem(
    constants.LOGIN_REDIRECT_STORAGE_KEY,
    window.location.pathname
  );

  return <Home alert={alert} />;
};

export default LoggedInRoute;
