import { NoteType } from 'screener19-core';
import { message } from 'antd';

export const showNoteSuccessMessage = (type: NoteType) => {
  if (type === NoteType.incident) {
    message.success('Incident form added');
  }
  if (type === NoteType.note) {
    message.success('Note added to history');
  }
  if (type === NoteType.test) {
    message.success('Test Result added');
  }
};

export const showNoteUpdatedMessage = (type: NoteType) => {
  if (type === NoteType.incident) {
    message.success('Incident form updated');
  }
  if (type === NoteType.note) {
    message.success('Note updated');
  }
  if (type === NoteType.test) {
    message.success('Test Result updated');
  }
};
