import './styles.css';

import { Answer, Question } from 'screener19-core';
import { Button, Collapse, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import ScreenerQuestionFormAnswers from './ScreenerQuestionFormAnswers';
import ScreenerQuestionFormQuestion from './ScreenerQuestionFormQuestion';

const { Panel } = Collapse;
const { TextArea } = Input;

interface Props {
  loading: boolean;
  onCancel: () => void;
  onFinish: (values: any) => void;
  question?: Question;
  title?: string;
  visible: boolean;
}

const layout = {
  labelCol: { span: 20 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { span: 20 },
};

const ScreenerQuestionFormModal = ({
  loading,
  onCancel,
  onFinish,
  question,
  title = 'Add Question',
  visible,
}: Props) => {
  const [form] = Form.useForm();

  const [answers, setAnswers] = useState<Answer[]>([]);

  useEffect(() => {
    if (question) {
      setAnswers(question.answers);
      form.setFieldsValue(question);
    }
  }, [question]);

  return (
    <Modal
      title={title}
      onCancel={onCancel}
      footer={null}
      visible={visible}
      destroyOnClose={true}
      width={800}
      className="screenerquestionsform__modal"
    >
      <Form
        preserve={false}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        layout="vertical"
      >
        <ScreenerQuestionFormQuestion />
        <ScreenerQuestionFormAnswers
          answers={answers}
          onReorderAnswers={(answers) => {
            setAnswers(answers);
            form.setFieldsValue({
              ...question,
              answers: answers,
            });
          }}
        />
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            {!loading ? 'Save Question' : 'Creating...'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ScreenerQuestionFormModal;
