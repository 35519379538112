import './styles.css';

import { Form, message } from 'antd';
import { District, Question, User } from 'screener19-core';
import React, { useState } from 'react';
import AuthRoute from 'components/AuthRoute';
import DashboardContainer from 'components/DashboardContainer';
import DistrictSettingsQuestions from 'components/DistrictSettingsQuestions';
import DistrictsApi from 'screener19-core/dist/api/districts';
import DistrictsSettingsNavMenu from 'components/DistrictsSettingsNavMenu';
import QuestionsApi from 'screener19-core/dist/api/questions';
import axiosInstance from 'util/api-util';
import { connect } from 'react-redux';
import { setQuestions } from 'screener19-core/dist/redux/questions';
import useAsyncEffect from 'use-async-effect';

message.config({
  duration: 2,
  maxCount: 1,
});

interface Props {
  onLogout: () => void;
  user: User;
  questions: Question[];
  setQuestions: (questions: Question[]) => void;
}

const DistrictSettings = ({ user, onLogout, questions }: Props) => {
  const [form] = Form.useForm();
  const api = new DistrictsApi(axiosInstance);
  const questionApi = new QuestionsApi(axiosInstance);
  const [loading, setLoading] = useState<boolean>(true);
  const [district, setDistrict] = useState<District | undefined>();
  const [districtQuestions, setDistrictQuestions] = useState<Question[]>();

  useAsyncEffect(async () => {
    const _district = await api.getOne(user.district._id);
    setDistrict(_district);
    const _questions = await questionApi.get();
    setDistrictQuestions(_district.questions || _questions);
    try {
      form.setFieldsValue(_district);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const onFinish = async (values: any) => {
    try {
      await api.update(
        user.district._id,
        values.name,
        values.enableStaffDashboard
      );
      message.success('District updated');
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <DashboardContainer
      onLogout={onLogout}
      user={user}
      currentMenuKey={'/district/settings'}
    >
      <AuthRoute
        exact
        auth={user.permissions.canViewAdmin || user.isTechAdmin}
        path="/district/settings"
      >
        <DistrictsSettingsNavMenu
          id={district?._id}
          selectedKeys={['/district/settings']}
        />
        <DistrictSettingsQuestions
          district={district}
          baseQuestions={districtQuestions}
          onResetQuestions={() => {
            return new Promise(async (resolve, reject) => {
              try {
                if (district) {
                  await api.resetQuestions(district._id);
                  const _questions = await questionApi.get();
                  setQuestions(_questions);
                  message.success('Questions reset to default');
                  resolve(_questions);
                } else {
                  reject();
                }
              } catch (error) {
                console.log(error);
                message.error(
                  'There was a problem resetting the district questions'
                );
                reject(error);
              }
            });
          }}
          onUpdateQuestions={async (questions: Question[]) => {
            if (district) {
              try {
                await api.updateQuestions(district._id, questions);
                const _questions = await questionApi.get();
                setQuestions(_questions);
                message.success('District settings updated');
              } catch (error) {
                console.log(error);
                message.error(
                  'There was a problem updating the district settings'
                );
              }
            }
          }}
        />
      </AuthRoute>
      <div className="district-settings__container"></div>
    </DashboardContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    dashboard: state.dashboard,
    districts: state.districts,
    questions: state.questions,
    user: state.user,
  };
};

const mapDispatchToProps = { setQuestions };

export default connect(mapStateToProps, mapDispatchToProps)(DistrictSettings);
