import { Checkbox, Divider, Form, Input, Select } from 'antd';

import React from 'react';

const { TextArea } = Input;
const { Option } = Select;

const ScreenerQuestionFormQuestion = () => {
  const handleChange = (value: any) => {
    console.log(`selected ${value}`);
  };

  return (
    <React.Fragment>
      <div className="screenerquestionsform__modal-section-header">
        <Divider>Question</Divider>
      </div>
      <Select style={{ width: 120 }} onChange={handleChange}>
        <Option value="MultipleChoice">Multiple Choice</Option>
        <Option value="TrueFalse">True/False</Option>
      </Select>
      <Form.Item
        name="text"
        label={
          <div>
            <div>Question text</div>
            <small>
              This is the primary text for the question{' '}
              <i>(i.e. Why are you coming to campus today?)</i>
            </small>
          </div>
        }
        rules={[{ required: true }]}
      >
        <TextArea placeholder="Enter the question..." />
      </Form.Item>
      <Form.Item
        name="description"
        label={
          <div>
            <div>Question description</div>
            <small>
              Optional description for the question.{' '}
              <i>(i.e. Choose all that apply)</i>
            </small>
          </div>
        }
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="shortDescription"
        rules={[{ required: true }]}
        label={
          <div>
            <div>Dashboard label</div>
            <small>
              Short label shown in the dashboard column headers.{' '}
              <i>(i.e. Symptoms)</i>
            </small>
          </div>
        }
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="key"
        rules={[{ required: true }]}
        label={
          <div>
            <div>URL key</div>
            <small>
              URL path for question. <i>(i.e. /campus)</i>
            </small>
          </div>
        }
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="exitAnswerText"
        label={
          <div>
            <div>Confirmation text</div>
            <small>
              Shown on confirmation page before submitting screener.{' '}
              <i>(i.e. Coming to campus for:)</i>
            </small>
          </div>
        }
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="showAnswersOnExit"
        valuePropName="checked"
        initialValue={false}
        noStyle
      >
        <Checkbox> Show all answers on confirmation screen</Checkbox>
      </Form.Item>
      <Form.Item>
        <small>
          When checked all answers will be shown on confirmation screen. By
          default only the first selected answer is shown.
        </small>
      </Form.Item>
      <Form.Item
        name="noneIndex"
        label={
          <div>
            <div>None index</div>
            <small>
              You can change which answer is treated as "none". This will
              deselect all other answers. By default this is the last answer in
              the list{' '}
              <i>
                (The index starts at 0, so the second option in the list is 1,
                the third is 2, etc.)
              </i>
            </small>
          </div>
        }
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="isStaffOnly"
        valuePropName="checked"
        initialValue={false}
        noStyle
      >
        <Checkbox> Staff Only Question</Checkbox>
      </Form.Item>
      <Form.Item>
        <small>
          When checked this quesiton will only be shown to staff members.
        </small>
      </Form.Item>
      <Form.Item
        name="isStudentOnly"
        valuePropName="checked"
        initialValue={false}
        noStyle
      >
        <Checkbox> Student Only Question</Checkbox>
      </Form.Item>
      <Form.Item>
        <small>
          When checked this quesiton will only be shown to students.
        </small>
      </Form.Item>
    </React.Fragment>
  );
};

export default ScreenerQuestionFormQuestion;
