import './styles.less';

import {
  format,
  isFuture,
} from 'date-fns';

import React from 'react';
import { Tag } from 'antd';

interface Props {
  color: string;
  end?: Date;
  label: string;
  start?: Date;
  remaining?: string;
  onClick?: () => void;
}

const UserCardStatus = ({
  color,
  end,
  label,
  start,
  remaining,
  onClick,
}: Props) => {
  const daysLeft = () => {
    if (end) {
      if (isFuture(new Date(end))) {
        return `(${remaining})`;
      }
    }
  };

  return (
    <div className="userprofile__tag" onClick={onClick}>
      <div className="userprofile__badge">
        <Tag className="userprofile__badge" color={color}>
          {label}
        </Tag>
      </div>
      <div className="userprofile__tag-range">
        {start && end && (
          <small>
            {start && format(new Date(start), 'LLL do')}
            &nbsp;&#8594;&nbsp;
            {end && format(new Date(end), 'LLL do')} {daysLeft()}
          </small>
        )}
      </div>
    </div>
  );
};

export default UserCardStatus;
