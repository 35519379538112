import './styles.css';

import { District, Question } from 'screener19-core';
import { Layout, message } from 'antd';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import AdminDistrictProfile from 'components/AdminDistrictProfile';
import AuthRoute from 'components/AuthRoute';
import DashboardContainer from 'components/DashboardContainer';
import DistrictLogs from 'components/DistrictLogs';
import DistrictSettingsQuestions from 'components/DistrictSettingsQuestions';
import DistrictsApi from 'screener19-core/dist/api/districts';
import DistrictsNavMenu from 'components/DistrictsNavMenu';
import QuestionsApi from 'screener19-core/dist/api/questions';
import SchoolsApi from 'screener19-core/dist/api/schools';
import axiosInstance from 'util/api-util';
import { connect } from 'react-redux';
import { setQuestions } from 'screener19-core/dist/redux';
import useAsyncEffect from 'use-async-effect';

const { Header, Content, Footer, Sider } = Layout;

const AdminDistrict = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [district, setDistrict] = useState<District>();
  const [districts, setDistricts] = useState<District[]>([]);
  const [districtQuestions, setDistrictQuestions] = useState<Question[]>();

  let location = useLocation();
  const districtApi = new DistrictsApi(axiosInstance);
  const schoolsApi = new SchoolsApi(axiosInstance);
  const questionApi = new QuestionsApi(axiosInstance);

  message.config({
    duration: 2,
    maxCount: 1,
  });

  let { id } = useParams<any>();

  useAsyncEffect(() => {
    fetchDistrict(id);
  }, []);

  const canViewAdmin = () => {
    return (
      !!props.user &&
      !!props.user.permissions &&
      !!props.user.permissions.canViewAdmin
    );
  };

  const fetchDistrict = async (id: string) => {
    setLoading(true);
    try {
      const _district = await districtApi.getOneForAdmin(id);
      setDistrict(_district);

      const _questions = _district.questions?.length
        ? _district.questions
        : props.questions;
      setDistrictQuestions(_questions);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchDistricts = async () => {
    setLoading(true);
    try {
      const _districts = await districtApi.getAll();
      setDistricts(_districts);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCreateSchool = async (values: any) => {
    const school = await schoolsApi.add(values);
    fetchDistrict(id);
  };

  const onDeleteSchool = async (id: string) => {
    const school = await schoolsApi.destroy(id);
    fetchDistrict(id);
  };

  const onDeleteCourses = async (districtId: string) => {
    try {
      const result = await districtApi.deleteDistrictCourses(districtId);
      message.success('All courses and classes have been deleted');
    } catch (error) {
      message.error(error.message);
    }
  };

  const onImportCourses = async (districtId: string) => {
    try {
      const courses = await districtApi.importDistrictCourses(districtId);
      message.success('Done importing courses');
    } catch (error) {
      message.error(error.message);
    }
  };

  const onImportSchools = async (districtId: string) => {
    try {
      const schools = await districtApi.importSchools(districtId);
      message.success('Done importing schools and students');
    } catch (error) {
      message.error(error.message);
    }
  };

  const onImportStaff = async (districtId: string) => {
    try {
      const staff = await districtApi.importStaff(districtId);
      message.success('Done importing staff');
    } catch (error) {
      message.error(error.message);
    }
  };

  const onUpdateSchool = async (id: string, values: any) => {
    try {
      const school = await schoolsApi.update(id, values);
      fetchDistrict(id);
      message.success('School updated');
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <DashboardContainer
      onLogout={props.onLogout}
      user={props.user}
      currentMenuKey={'/admin/districts'}
      pageTitle={district?.name}
    >
      <AuthRoute exact auth={canViewAdmin()} path="/admin/districts/:id">
        <AdminDistrictProfile
          district={district}
          districts={districts}
          loading={loading}
          loadData={fetchDistrict}
          loadDistricts={fetchDistricts}
          onCreateSchool={onCreateSchool}
          onDeleteSchool={onDeleteSchool}
          onDeleteCourses={onDeleteCourses}
          onImportCourses={onImportCourses}
          onImportSchools={onImportSchools}
          onImportStaff={onImportStaff}
          onUpdateSchool={onUpdateSchool}
        />
      </AuthRoute>
      <AuthRoute exact auth={canViewAdmin()} path="/admin/districts/:id/logs">
        <DistrictsNavMenu id={id} selectedKeys={[location.pathname]} />
        <DistrictLogs
          district={district}
          onLogout={props.onLogout}
          user={props.user}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewAdmin()}
        path="/admin/districts/:id/screener"
      >
        <DistrictsNavMenu id={id} selectedKeys={[location.pathname]} />
        <DistrictSettingsQuestions
          district={district}
          baseQuestions={districtQuestions}
          onResetQuestions={() => {
            return new Promise(async (resolve, reject) => {
              try {
                if (district) {
                  await districtApi.resetQuestions(id);
                  const _questions = await questionApi.get();
                  props.setQuestions(_questions);
                  message.success('Questions reset to default');
                  resolve(_questions);
                } else {
                  reject();
                }
              } catch (error) {
                console.log(error);
                message.error(
                  'There was a problem resetting the district questions'
                );
                reject(error);
              }
            });
          }}
          onUpdateQuestions={async (questions: Question[]) => {
            try {
              await districtApi.updateQuestions(id, questions);
              const _questions = await questionApi.get();
              props.setQuestions(_questions);
              message.success('District settings updated');
            } catch (error) {
              console.log(error);
              message.error(
                'There was a problem updating the district settings'
              );
            }
          }}
        />
      </AuthRoute>
    </DashboardContainer>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    questions: state.questions,
    user: state.user,
  };
};

const mapDispatchToProps = {
  setQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDistrict);
