import './styles.css';

import { District } from 'screener19-core';
import React from 'react';

const CustomResults = ({ district }: { district: District }) => {
  // Albany USD
  if (district?._id?.toString() === '6010329248f6b90dcbd9f47c') {
    return (
      <div className="results__custom">
        <div className="results__custom-row results__custom-row-red">
          If the answer is yes to any of the SYMPTOM questions: Isolation
          Instructions
        </div>
        <div className="results__custom-row results__custom-row-green">
          Student/Staff member may return once all the following are true:
        </div>
        <div className="results__custom-row results__custom-row-blue">
          <ol>
            <li>
              At least 10 days have passed since symptom(s) first appeared
            </li>
            <li>
              There have been at least 24 hours with no fever, without the aid
              of taking medicines to lower the fever, such as acetaminophen
              (Tylenol) or ibuprofen (Advil or Motrin)
            </li>
            <li>
              Other symptoms, such as cough or shortness of breath, are
              improving
            </li>
          </ol>
        </div>
        <div className="results__custom-row results__custom-row-green">
          Student/Staff member may return BEFORE 10 days if:
        </div>
        <div className="results__custom-row results__custom-row-blue">
          <ol>
            <li>
              No fever in the past 24 hours without the aid of taking medicines
              to lower a fever, such as acetaminophen (Tylenol) or ibuprofen
              (Advil or Motrin)
            </li>
            <li>
              Feeling better (symptoms do not have to be completely resolved)
            </li>
            <br />
            <span className="results__custom-row-and">
              AND one of the following:
            </span>
          </ol>
        </div>
        <div className="results__custom-row results__custom-row-darkblue">
          <ul>
            <li>
              A letter (from a doctor, nurse practitioner, or certified
              physician assistant) indicating that an alternative diagnosis has
              been made and the person’s symptoms are NOT due to COVID-19.
            </li>
            <li>
              Proof of a negative COVID-19 test result, must be a molecular test
              (PCR, NAA, or NAAT)
            </li>
          </ul>
        </div>
        <div className="results__custom-row results__custom-row-green">
          Household members of a student who have any COVID symptom will need to
          quarantine if they meet one of the below criteria for strong suspicion
          of a positive case (until test results are available):
        </div>
        <div className="results__custom-row results__custom-row-blue">
          <ol>
            <li>
              The suspected case is symptomatic following{' '}
              <b>exposure to a confirmed case.</b>
            </li>
            <li>
              There is no known exposure, but there are{' '}
              <b>
                <u>TWO</u>
              </b>{' '}
              of the following symptoms:
              <ul>
                <li>Fever or chills</li>
                <li>Severe Headache</li>
                <li>Sore throat</li>
                <li>Vomiting or diarrhea</li>
                <li>Fatigue</li>
              </ul>
            </li>
            <li>
              There is no known exposure, but there is{' '}
              <b>
                <u>ONE</u>
              </b>{' '}
              of the following symptoms:
              <ul>
                <li>Cough</li>
                <li>Shortness of breath or difficulty breathing</li>
                <li>New loss of taste or smell</li>
              </ul>
            </li>
          </ol>
        </div>
        <div className="results__custom-row results__custom-row-red">
          If the answer is yes to the EXPOSURE question: Quarantine Instructions
        </div>
        <div className="results__custom-row results__custom-row-green">
          If a Student/Staff member is excluded due to close contact with a
          COVID positive person, they may return:
        </div>
        <div className="results__custom-row results__custom-row-blue">
          <ol>
            <li>
              After 14 days following the close contact with the COVID positive
              person, assuming that the student/staff member does not develop
              symptoms.
            </li>
          </ol>
          <div className="results__custom-appendix">
            ** If the student/staff member develops symptoms of COVID19 during
            quarantine, a COVID-19 test should be obtained and the student may
            return according to the “If the answer is YES to any of the symptom
            questions” table above.
          </div>
          <div className="results__custom-appendix">
            ***If student/staff member is fully vaccinated, they do not have to
            quarantine or test unless they develop symptoms
          </div>
        </div>
      </div>
    );
  }

  return <div></div>;
};

export default CustomResults;
