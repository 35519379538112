import { Dropdown, Menu } from 'antd';

import { DownOutlined } from '@ant-design/icons';
import { User } from 'screener19-core';
import { useHistory } from 'react-router-dom';

interface Props {
  onLogout: () => void;
  user: User;
}

const DashboardUserAccountLinks = (props: Props) => {
  const history = useHistory();
  return (
    <div>
      <Dropdown
        overlay={
          <Menu
            onClick={({ key }) => {
              if (key === 'logout') {
                props.onLogout();
                return;
              }

              history.push(key as string);
            }}
          >
            <Menu.Item key="/account">Account & Classes</Menu.Item>
            <Menu.Item key="/account/history">History</Menu.Item>
            <Menu.Item key="logout">Logout</Menu.Item>
          </Menu>
        }
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          {props.user.displayName} <DownOutlined />
        </a>
      </Dropdown>
    </div>
  );
};

export default DashboardUserAccountLinks;
