import './styles.css';

import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import React from 'react';

interface Props {
  selectedKeys: string[];
  urlKey?: string;
}

const RostersNavMenu = ({ selectedKeys, urlKey = 'students' }: Props) => {
  return (
    <Menu
      theme="light"
      selectedKeys={selectedKeys}
      mode="horizontal"
      className="userlist__menu"
    >
      <Menu.Item key={`/dashboard/users/${urlKey}`}>
        <Link to={`/dashboard/users/${urlKey}`}>All</Link>
      </Menu.Item>
      <Menu.Item key={`/dashboard/users/${urlKey}/review`}>
        <Link to={`/dashboard/users/${urlKey}/review`}>Pending</Link>
      </Menu.Item>
      <Menu.Item key={`/dashboard/users/${urlKey}/healthy`}>
        <Link to={`/dashboard/users/${urlKey}/healthy`}>Healthy</Link>
      </Menu.Item>
      <Menu.Item key={`/dashboard/users/${urlKey}/unknown`}>
        <Link to={`/dashboard/users/${urlKey}/unknown`}>Unknown</Link>
      </Menu.Item>
      <Menu.Item key={`/dashboard/users/${urlKey}/quarantined`}>
        <Link to={`/dashboard/users/${urlKey}/quarantined`}>Quarantined</Link>
      </Menu.Item>
      <Menu.Item key={`/dashboard/users/${urlKey}/isolated`}>
        <Link to={`/dashboard/users/${urlKey}/isolated`}>Isolated</Link>
      </Menu.Item>
      <Menu.Item key={`/dashboard/users/${urlKey}/inactive`}>
        <Link to={`/dashboard/users/${urlKey}/inactive`}>Inactive</Link>
      </Menu.Item>
    </Menu>
  );
};

export default RostersNavMenu;
