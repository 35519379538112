import './styles.less';

import { User } from 'screener19-core';

import React from 'react';
import RostersNavMenu from 'components/RostersNavMenu';
import UsersList from 'components/UsersList';

interface Props {
  appendToUserLink?: string;
  description?: string;
  extraColumns?: any[];
  headerButton?: any;
  loading: boolean;
  location: string;
  includeSchools?: boolean;
  paginationLabel?: string;
  onChangeUsers?: (users: User[]) => void;
  onSearchUsers: (query: string) => void;
  setCurrentUserFilter: (filter: string) => void;
  filter?: string;
  showNames: boolean;
  title?: string;
  urlKey?: string;
  users?: User[];
}

const UsersWithNavMenu = ({
  appendToUserLink,
  description,
  extraColumns,
  filter = 'all',
  headerButton,
  includeSchools,
  loading,
  location,
  onChangeUsers,
  onSearchUsers,
  paginationLabel,
  setCurrentUserFilter,
  showNames,
  title,
  urlKey = 'students',
  users,
}: Props) => {
  return (
    <React.Fragment>
      <RostersNavMenu selectedKeys={[location]} urlKey={urlKey} />
      <div className="dashboard__list">
        <div>
          <UsersList
            appendToUserLink={appendToUserLink}
            description={description}
            loading={loading}
            title={title}
            extraColumns={extraColumns}
            headerButton={headerButton}
            includeId={true}
            includeSchools={includeSchools}
            fetchUsers={() => setCurrentUserFilter(filter)}
            onChange={onChangeUsers}
            onSearchUsers={onSearchUsers}
            paginationLabel={paginationLabel}
            users={users}
            showNames={showNames}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default UsersWithNavMenu;
