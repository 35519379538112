import './styles.less';

import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
} from 'antd';
import { Note, NoteType, User } from 'screener19-core';
import React, { useEffect, useState } from 'react';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

interface Props {
  onCancel: () => void;
  onFinish: (values: any) => void;
  onUpdate: (noteId: string, values: any) => void;
  visible: boolean;
  user?: User;
  note?: Note;
}

const UserAddNoteModal = ({
  onFinish,
  onUpdate,
  onCancel,
  user,
  visible,
  note,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Add Note');
  const [quarantineOrIsolation, setQuarantineIsolation] = useState('no');
  const [hadCovidTest, setCovidTest] = useState<boolean | undefined>();

  useEffect(() => {
    if (visible) {
      setLoading(false);
      if (note) {
        form.setFieldsValue({ ...note });
      }
    }
  }, [visible]);

  const validateMessages = {
    required: 'This field can not be empty',
  };

  const onFinishForm = (values: any) => {
    setLoading(true);

    if (note) {
      if (onUpdate) {
        onUpdate(note._id, values);
      }
      return;
    }

    if (onFinish) {
      onFinish({
        ...values,
        type: NoteType.note,
      });
    }
  };

  const renderButton = () => {
    const label = note ? 'Update Note' : 'Add Note';
    return (
      <Button loading={loading} type="primary" htmlType="submit">
        {label}
      </Button>
    );
  };

  return (
    <Modal
      title={title}
      onCancel={onCancel}
      footer={null}
      visible={visible}
      destroyOnClose={true}
      width={800}
    >
      <Form
        layout="vertical"
        preserve={false}
        form={form}
        name="control-hooks"
        onFinish={onFinishForm}
        validateMessages={validateMessages}
      >
        <Form.Item
          required
          name="text"
          label="Enter a note that will appear in the student's history"
          rules={[{ required: true }]}
        >
          <TextArea placeholder="" autoSize={{ minRows: 5, maxRows: 10 }} />
        </Form.Item>
        <Form.Item>
          <Space>{renderButton()}</Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserAddNoteModal;
