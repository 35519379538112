import './styles.less';

import { Card, Table } from 'antd';
import React from 'react';

interface Props {
  canViewTestResults: boolean;
  loading?: boolean;
  onClickDistrict?: () => void;
  onClickSchool?: () => void;
  positiveCasesInDistrict?: number;
  positiveCasesInSchool?: number;
  recentPositiveCasesInDistrict?: number;
  recentPositiveCasesInSchool?: number;
}

const columns = [
  {
    title: '',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    render: (text: string, record: any, index: number) => {
      return text;
    },
  },
  {
    title: 'This Week',
    dataIndex: 'recent',
    key: 'recent',
    render: (text: string, record: any, index: number) => {
      return <b>{text}</b>;
    },
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (text: string, record: any, index: number) => {
      return <b>{text}</b>;
    },
  },
];

const DashboardTestResults = ({
  canViewTestResults,
  loading,
  onClickDistrict,
  onClickSchool,
  positiveCasesInSchool,
  positiveCasesInDistrict,
  recentPositiveCasesInDistrict,
  recentPositiveCasesInSchool,
}: Props) => {
  const dataSource = [
    {
      type: 'District',
      recent:
        recentPositiveCasesInDistrict !== undefined ? (
          recentPositiveCasesInDistrict
        ) : (
          <>&ndash;</>
        ),
      total:
        positiveCasesInDistrict !== undefined ? (
          positiveCasesInDistrict
        ) : (
          <>&ndash;</>
        ),
    },
    {
      type: 'School',
      recent:
        recentPositiveCasesInSchool !== undefined ? (
          recentPositiveCasesInSchool
        ) : (
          <>&ndash;</>
        ),
      total:
        positiveCasesInSchool !== undefined ? (
          positiveCasesInSchool
        ) : (
          <>&ndash;</>
        ),
    },
  ];

  return (
    <Card title="COVID-19 Positive Results" className="dashboardstats__card">
      <Table
        size="small"
        loading={loading}
        showHeader={true}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowClassName={
          canViewTestResults ? 'dashboardtestresults__table-row' : ''
        }
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (canViewTestResults) {
                if (rowIndex === 0) {
                  onClickDistrict && onClickDistrict();
                }
                if (rowIndex === 1) {
                  onClickSchool && onClickSchool();
                }
              }
            },
          };
        }}
      />
    </Card>
  );
};

export default DashboardTestResults;
