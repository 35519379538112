import './styles.css';

import { Card, Col, Row, Skeleton } from 'antd';
import {
  FrownTwoTone,
  MehTwoTone,
  SmileTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';

import { Link } from 'react-router-dom';
import React from 'react';
import { constants } from 'conf/constants';

interface Props {
  loading: boolean;
  stats: any;
  urlKey?: string;
}

const DashboardStudentStatsRow = ({ icon, label, link, stat }: any) => {
  return (
    <Row className="dashboardstats__row" gutter={5}>
      <Col>{icon}</Col>
      <Col>
        <Link to={link}>
          <table>
            <tr>
              <td width={120}>
                <span className="dashboardhome__stats-name">{label}</span>
              </td>
              <td>
                <b>{stat !== undefined ? stat : <>&ndash;</>}</b>
              </td>
            </tr>
          </table>
        </Link>
      </Col>
    </Row>
  );
};

const DashboardStudentStats = ({
  loading,
  stats,
  urlKey = 'students',
}: Props) => {
  const percent =
    stats && stats.responses && stats.total ? stats.responses / stats.total : 0;
  const percentageResponded =
    percent && percent > 0 ? (percent * 100).toFixed(0) + '%' : '';
  const percentColor = percent > 0.8 ? 'green' : 'red';

  return (
    <Card title="Students" className="dashboardstats__card">
      <Skeleton loading={loading} paragraph={{ rows: 1 }}>
        <DashboardStudentStatsRow
          icon={<WarningTwoTone twoToneColor={constants.PENDING_BLUE_COLOR} />}
          label={'Pending: '}
          link={`/dashboard/users/${urlKey}/review`}
          stat={stats?.pendingUsers?.length}
        />
        <DashboardStudentStatsRow
          icon={<SmileTwoTone twoToneColor={constants.HEALTHY_GREEN_COLOR} />}
          label={'Healthy: '}
          link={`/dashboard/users/${urlKey}/healthy`}
          stat={stats?.healthy}
        />
        <DashboardStudentStatsRow
          icon={<MehTwoTone twoToneColor={'#bbb'} />}
          label={'Unknown: '}
          link={`/dashboard/users/${urlKey}/unknown`}
          stat={stats?.unknown}
        />
        <DashboardStudentStatsRow
          icon={<FrownTwoTone twoToneColor={constants.QUARANTINE_COLOR} />}
          label={'Quarantined: '}
          link={`/dashboard/users/${urlKey}/quarantined`}
          stat={stats?.quarantined}
        />
        <DashboardStudentStatsRow
          icon={<FrownTwoTone twoToneColor={constants.ISOLATION_COLOR} />}
          label={'Isolated: '}
          link={`/dashboard/users/${urlKey}/isolated`}
          stat={stats?.monitored}
        />
      </Skeleton>
    </Card>
  );
};

export default DashboardStudentStats;
