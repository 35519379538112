import './styles.css';

import { Class, School } from 'screener19-core';
import { Link, useLocation, useParams } from 'react-router-dom';

import React from 'react';
import SchoolsNavMenu from 'components/SchoolsNavMenu';
import { Table } from 'antd';
import useAsyncEffect from 'use-async-effect';

interface Props {
  loadClasses: (id: string) => void;
  classes?: Class[];
  school?: School;
  loading: boolean;
}

const AdminSchoolClasses = ({
  classes,
  loadClasses,
  school,
  loading,
}: Props) => {
  let { id } = useParams<any>();

  useAsyncEffect(async () => {
    await loadClasses(id);
  }, []);

  const location = useLocation();

  const columns = [
    {
      title: 'Title',
      dataIndex: 'course.title',
      key: 'title',
      render: (text: string, record: any) => (
        <Link
          to={{
            pathname: `/admin/schools/${id}/classes/${record._id}`,
            state: { class: record },
          }}
        >
          {record.course?.title}
        </Link>
      ),
    },
    {
      title: 'Section',
      dataIndex: 'sectionNumber',
      key: 'section',
    },
  ];

  return (
    <div className="userprofile__container">
      <SchoolsNavMenu id={school?._id} selectedKeys={[location.pathname]} />
      <h2>{school?.name}</h2>

      <div className="admindistrict_table">
        <Table dataSource={classes} columns={columns} />
      </div>
    </div>
  );
};

export default AdminSchoolClasses;
