import { Form, Select } from 'antd';

import React from 'react';

const { Option } = Select;

interface Props {
  includeAdmin?: boolean;
}

const RolesDropdownSelect = ({ includeAdmin = false }: Props) => {
  return (
    <Form.Item name="role" label={'Role'} rules={[{ required: true }]}>
      <Select
        style={{ width: 200 }}
        placeholder="Select a role"
        onChange={(role: string) => {
          // setRole(role);
        }}
      >
        {includeAdmin && <Option value={'administrator'}>Administrator</Option>}
        <Option value={'district'}>District</Option>
        <Option value={'nurse'}>Nurse</Option>
        <Option value={'office'}>Office</Option>
        <Option value={'principal'}>Principal</Option>
        <Option value={'staff'}>Staff</Option>
        <Option value={'user'}>Student</Option>
      </Select>
    </Form.Item>
  );
};

export default RolesDropdownSelect;
