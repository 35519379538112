import './styles.css';
import { Card, Col, Row, Skeleton, Statistic } from 'antd';

import DashboardStudentStats from 'components/DashboardStudentStats';
import DashboardTestResults from 'components/DashboardTestResults';
import React from 'react';

interface Props {
  canViewTestResults: boolean;
  loading: boolean;
  onClickDistrictTests: () => void;
  onClickSchoolTests: () => void;
  stats: any;
  urlKey?: string;
}

const DashboardStats = ({
  canViewTestResults,
  loading,
  onClickDistrictTests,
  onClickSchoolTests,
  stats,
  urlKey,
}: Props) => {
  const percent =
    stats && stats.responses && stats.total ? stats.responses / stats.total : 0;
  const percentageResponded =
    percent && percent > 0 ? (percent * 100).toFixed(0) + '%' : '';
  const percentColor = percent > 0.8 ? 'green' : 'red';

  return (
    <Row gutter={16} className="dashboardhome__stats">
      <Col span={8}>
        <DashboardStudentStats
          loading={loading}
          stats={stats}
          urlKey={urlKey}
        />
      </Col>
      <Col span={8}>
        <DashboardTestResults
          canViewTestResults={canViewTestResults}
          loading={loading}
          onClickDistrict={onClickDistrictTests}
          onClickSchool={onClickSchoolTests}
          positiveCasesInDistrict={stats?.positiveCasesInDistrict}
          positiveCasesInSchool={stats?.positiveCasesInSchool}
          recentPositiveCasesInDistrict={stats?.recentPositiveCasesInDistrict}
          recentPositiveCasesInSchool={stats?.recentPositiveCasesInSchool}
        />
      </Col>
      <Col span={8}>
        <Card title="Today's Responses" className="dashboardstats__card">
          <Skeleton loading={loading} paragraph={{ rows: 1 }}>
            <Row gutter={16}>
              <Col>
                {stats?.responses !== undefined &&
                stats?.total !== undefined ? (
                  <Statistic
                    value={percentageResponded}
                    valueStyle={{ color: percentColor }}
                    suffix={
                      stats && stats.total
                        ? `(${stats.responses}/${stats.total})`
                        : ''
                    }
                  />
                ) : (
                  <>&ndash;</>
                )}
              </Col>
            </Row>
          </Skeleton>
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardStats;
