import axios, { AxiosResponse } from 'axios';
import createAuthRefreshInterceptor, {
  AxiosAuthRefreshRequestConfig,
} from 'axios-auth-refresh';
import {
  logoutAction,
  setJwt,
  setRefreshToken,
} from 'screener19-core/dist/redux';

import { clearTokensFromLocalStorage } from './logout-util';
import { store } from '../redux/store';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

// Next we make an 'instance' of it
let instance = axios.create();

// set the response interceptor to grab the data
instance.interceptors.response.use(({ data }: AxiosResponse) => {
  return data.data;
});

const getToken = () => {
  return store.getState().auth.jwt;
};

// Set the auth token from redux
instance.interceptors.request.use(function (config) {
  let token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Function that will be called to refresh authorization
const refreshAuthLogic = async (failedRequest: any) => {
  try {
    const config: AxiosAuthRefreshRequestConfig = {
      skipAuthRefresh: true,
    };
    let refreshToken = store.getState().auth.refreshToken;
    const tokens = await instance.post(
      '/api/auth/refresh-token',
      { token: refreshToken },
      config
    );

    await store.dispatch(setJwt(tokens.token));
    await store.dispatch(setRefreshToken(tokens.refreshToken));

    return Promise.resolve();
  } catch (error) {
    // logout and clear tokens from localstorage
    await store.dispatch(logoutAction());
    clearTokensFromLocalStorage();
    // redirect to /
    window.location.href = '/';
    return Promise.reject(error);
  }
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;
