import './styles.less';

import { Tooltip } from 'antd';

import React from 'react';

interface ListItemProps {
  children?: any;
  disabled?: boolean;
  disabledMessage?: string;
  isLast?: boolean;
  onPress?: () => void;
  selected?: boolean;
}

const ListItem = ({
  children,
  isLast,
  onPress,
  selected,
  disabled,
  disabledMessage = 'Sorry, this is not an option today',
}: ListItemProps) => {
  let cls = selected ? ['container-li', 'selected'] : ['container-li'];

  if (disabled) {
    cls.push('screener__listitem-disabled');
  }

  if (isLast) {
    cls.push('screener__listitem-last');
  }

  const checkmark = () => {
    if (selected) {
      return (
        <img
          src={`${process.env.REACT_APP_S3_URL}/assets/images/checkmark-on.svg`}
          className="li-checkmark"
        />
      );
    }

    return (
      <img
        src={`${process.env.REACT_APP_S3_URL}/assets/images/checkmark-off.svg`}
        className="li-checkmark-off"
      />
    );
  };

  const onPressItem = () => {
    if (!disabled && onPress) {
      onPress();
    }
  };

  const listItem = (
    <div className={cls.join(' ')} onClick={onPressItem}>
      {children}
      {checkmark()}
    </div>
  );

  if (!disabled) {
    return listItem;
  }

  // disabled, show tooltip
  return (
    <Tooltip
      trigger={['hover', 'click', 'focus']}
      color="blue"
      title={disabledMessage}
    >
      {listItem}
    </Tooltip>
  );
};

export default ListItem;
