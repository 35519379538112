import { UsersRostersProps } from 'screener19-core';

import AuthRoute from 'components/AuthRoute';
import React from 'react';
import UsersIsolated from 'components/UsersIsolated';
import UsersPending from 'components/UsersPending';
import UsersQuarantined from 'components/UsersQuarantined';
import UsersWithNavMenu from 'components/UsersWithNavMenu';

const StudentRosters = ({
  allUsers,
  canViewDashboard,
  canViewResponses,
  healthyUsers,
  isolatedUsers,
  inactiveUsers,
  loading,
  location,
  onChangeAllUsers,
  onChangeHealthyUsers,
  onChangePendingUsers,
  onChangeQuarantinedUsers,
  onChangeIsolatedUsers,
  onChangeInactiveUsers,
  onChangeUnknownUsers,
  onSearchUsers,
  pendingUsers,
  quarantinedUsers,
  questions,
  setCurrentUserFilter,
  users,
  unknownUsers,
  showNames,
}: UsersRostersProps) => {
  return (
    <React.Fragment>
      <AuthRoute exact auth={canViewDashboard} path="/dashboard/users/students">
        <UsersWithNavMenu
          appendToUserLink={'all'}
          description="All students"
          filter={'all'}
          loading={loading}
          location={location}
          onChangeUsers={onChangeAllUsers}
          onSearchUsers={onSearchUsers}
          setCurrentUserFilter={setCurrentUserFilter}
          users={allUsers}
          showNames={showNames}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/students/inactive"
      >
        <UsersWithNavMenu
          appendToUserLink={'inactive'}
          description="Students who are inactive in the SIS"
          filter={'inactive'}
          loading={loading}
          location={location}
          onChangeUsers={onChangeInactiveUsers}
          onSearchUsers={onSearchUsers}
          setCurrentUserFilter={setCurrentUserFilter}
          users={inactiveUsers}
          showNames={showNames}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/students/healthy"
      >
        <UsersWithNavMenu
          appendToUserLink={'healthy'}
          description="Students who have responded today and are healthy"
          filter={'healthy'}
          loading={loading}
          location={location}
          onChangeUsers={onChangeHealthyUsers}
          onSearchUsers={onSearchUsers}
          setCurrentUserFilter={setCurrentUserFilter}
          users={healthyUsers}
          showNames={showNames}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/students/quarantined"
      >
        <UsersQuarantined
          appendToUserLink={'quarantined'}
          description="Students who are currently in quarantine"
          filter={'quarantined'}
          loading={loading}
          location={location}
          setCurrentUserFilter={setCurrentUserFilter}
          onChangeUsers={onChangeQuarantinedUsers}
          onSearchUsers={onSearchUsers}
          users={quarantinedUsers}
          showNames={showNames}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/students/isolated"
      >
        <UsersIsolated
          appendToUserLink={'isolated'}
          description="Students who are currently in isolation"
          filter={'monitored'}
          loading={loading}
          location={location}
          setCurrentUserFilter={setCurrentUserFilter}
          onChangeUsers={onChangeIsolatedUsers}
          onSearchUsers={onSearchUsers}
          users={isolatedUsers}
          showNames={showNames}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/students/unknown"
      >
        <UsersWithNavMenu
          appendToUserLink={'unknown'}
          description="Students who have not responded to the screener today are unknown"
          filter={'unknown'}
          loading={loading}
          location={location}
          onChangeUsers={onChangeUnknownUsers}
          onSearchUsers={onSearchUsers}
          setCurrentUserFilter={setCurrentUserFilter}
          users={unknownUsers}
          showNames={showNames}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/students/review"
      >
        <UsersPending
          appendToUserLink={'pending'}
          canViewResponses={canViewResponses}
          description="Students whose responses have triggered a nursing review"
          filter={'pending'}
          loading={loading}
          location={location}
          questions={questions}
          setCurrentUserFilter={setCurrentUserFilter}
          onChangeUsers={onChangePendingUsers}
          onSearchUsers={onSearchUsers}
          users={pendingUsers}
          showNames={showNames}
        />
      </AuthRoute>
    </React.Fragment>
  );
};

export default StudentRosters;
