import './styles.less';

import {
  Answer,
  History,
  HistoryType,
  Note,
  NoteType,
  Response,
  User,
} from 'screener19-core';
import { Button, Collapse, Space, Table } from 'antd';
import {
  EditTwoTone,
  FrownTwoTone,
  InfoCircleTwoTone,
  PlusOutlined,
  QuestionCircleTwoTone,
  SmileTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Link } from 'react-router-dom';
import { constants } from 'conf/constants';
import { format } from 'date-fns';
import { useState } from 'react';

const { Panel } = Collapse;

interface Props {
  loggedInUser: User;
  loading: boolean;
  userId?: string;
  userHistory?: History[];
  onAddNote: (note?: Note) => void;
  showNames?: boolean;
  showUserName?: boolean;
  title?: string;
}

const UserHistory = ({
  loggedInUser,
  loading,
  userHistory,
  userId,
  onAddNote,
  showNames = true,
  showUserName = false,
  title = 'Student History',
}: Props) => {
  const [showCheckins, setShowCheckins] = useState(true);
  const [showScreeners, setShowScreeners] = useState(true);

  const renderIconType = (history: History) => {
    switch (history.type) {
      case HistoryType.info:
        return '';
        break;
      case HistoryType.warn:
        return <WarningTwoTone twoToneColor={constants.QUARANTINE_COLOR} />;
        break;
      case HistoryType.success:
        return <SmileTwoTone twoToneColor={constants.HEALTHY_GREEN_COLOR} />;
        break;
      case HistoryType.isolation:
        return <FrownTwoTone twoToneColor={constants.ISOLATION_COLOR} />;
        break;
      case HistoryType.quarantine:
        return <FrownTwoTone twoToneColor={constants.QUARANTINE_COLOR} />;
        break;
      case HistoryType.pending:
        return '';
        break;
      case HistoryType.note:
        return '';
        break;

      default:
        break;
    }
  };

  const renderAnswersToResponse = (response: Response) => {
    const answers = response.questions.map((q: any, i: number) => {
      if (q.answered) {
        const a = q.answers.find((a: Answer) => a.selected);
        let strongClass = '';
        if (a && (a.warn || a.quarantine)) {
          strongClass = 'red';
        }
        let answer = [
          <div key={`question-${i}`}>
            <ul>
              {a && (
                <li
                  className={strongClass}
                  dangerouslySetInnerHTML={{
                    __html: a?.responseText,
                  }}
                />
              )}
            </ul>
          </div>,
        ];

        let selections: Array<any> = [];
        if (q.type === 'MultipleChoice') {
          selections = q.answers.map((a: Answer) => {
            if (a.selected) {
              return (
                <div className="userprofile__response-selections">
                  - {a.text}
                </div>
              );
            }
          });
        }

        return [...answer, ...selections];
      }
    });

    return answers;
  };

  // if (user && user.history && user.history.length) {
  let columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: History) => {
        const date = format(new Date(record.createdAt), 'LLL do h:mm a');

        return [<b>{date}</b>];
      },
      width: 190,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 20,
      render: (text: string, record: History) => {
        const icon = renderIconType(record);
        return icon;
      },
    },
  ] as any;

  if (showUserName) {
    columns = [
      ...columns,
      {
        title: 'Who',
        dataIndex: 'user.displayName',
        key: 'user.displayName',
        // render: (text: string, record: History) => {
        //   return record.user.displayName;
        // },
        render: (text: string, record: History) => {
          const { user } = record;

          const link = `/dashboard/user/${user?._id}`;
          if (showNames) {
            return (
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                }}
                to={link}
              >
                {user?.displayName}
              </Link>
            );
          }

          return (
            <Link
              onClick={(e) => {
                e.stopPropagation();
              }}
              to={link}
            >
              {user?.hiddenName}
            </Link>
          );
        },
      } as any,
    ];
  }

  columns = [
    ...columns,
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      render: (text: string, record: History, dataIndex: number) => {
        if (record.response) {
          return (
            <Collapse className="userprofile__timeline-collapse" ghost>
              <Panel
                header={text}
                key={dataIndex}
                className="userprofile__history-panel"
              >
                <div className="userprofile__answer">
                  {renderAnswersToResponse(record.response)}
                </div>
              </Panel>
            </Collapse>
          );
        }
        if (
          (record?.note?.type === NoteType.note ||
            record?.note?.type === NoteType.test) &&
          record?.addedByUser?._id === loggedInUser?._id
        ) {
          return (
            <span className="userprofile__history-text userprofile__history-text-editable">
              {record.note.text || record.text}{' '}
              <EditTwoTone
                className="userprofile__history-text-editicon"
                twoToneColor={constants.PRIMARY_COLOR}
              />
            </span>
          );
        }

        let _text = text;
        if (record?.startAt) {
          _text = `${_text} from ${format(
            new Date(record?.startAt),
            'LLL do'
          )}`;
        }
        if (record?.endAt) {
          _text = `${_text} to ${format(new Date(record?.endAt), 'LLL do')}`;
        }

        return <span className="userprofile__history-text">{_text}</span>;
      },
    },
    {
      title: 'Added By',
      dataIndex: 'addedByUser',
      key: 'text',
      render: (text: string, record: History) => {
        if (record.addedByUser && record.addedByUser._id !== userId) {
          return (
            <Link to={`/dashboard/user/${record.addedByUser._id}`}>
              <small>{`${record.addedByUser.firstName.slice(
                0,
                1
              )}${record.addedByUser.lastName.slice(0, 1)}`}</small>
            </Link>
          );
        }

        return text;
      },
    },
  ];

  const excludedHistoryTypes: string[] = [];

  if (!showCheckins) {
    excludedHistoryTypes.push(HistoryType.checkin);
  }

  if (!showScreeners) {
    excludedHistoryTypes.push(HistoryType.screener);
  }

  const historyData = userHistory?.filter(
    (h: History) => !excludedHistoryTypes.includes(h.type)
  );

  return (
    <div className="userprofile__history">
      <div className="userprofile__history-header">
        <Space>
          <h3>{title}</h3>
          <Checkbox
            checked={showCheckins}
            onChange={(e) => setShowCheckins(e.target.checked)}
          >
            <small>Show Checkins</small>
          </Checkbox>
          <Checkbox
            checked={showScreeners}
            onChange={(e) => setShowScreeners(e.target.checked)}
          >
            <small>Show Screeners</small>
          </Checkbox>
        </Space>
        <Button
          onClick={() => {
            if (onAddNote) {
              onAddNote();
            }
          }}
        >
          <PlusOutlined />
        </Button>
      </div>
      <Table
        size="small"
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
          showSizeChanger: true,
        }}
        showHeader={false}
        dataSource={historyData}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (onAddNote && record.note) {
                onAddNote(record.note);
              }
            },
          };
        }}
      />
    </div>
  );
  // }

  return null;
};

export default UserHistory;
