import './styles.css';

import { Answer, Question } from 'screener19-core';
import {
  EditOutlined,
  MenuOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';

import React from 'react';

interface Props {
  question: Question;
  onPressEdit?: (question: Question) => void;
  onPressDelete?: (question: Question) => void;
}

const ScreenerQuestionListItem = ({
  question,
  onPressEdit,
  onPressDelete,
}: Props) => {
  return (
    <div className="district__screener-question">
      <MenuOutlined className="district__screener-question-icon" />
      <div className="flex-fill">
        <h3>{question.text}</h3>
        {question.answers.map((a: Answer) => {
          return (
            <React.Fragment>
              <div className="district__screener-answer">- {a.text}</div>
            </React.Fragment>
          );
        })}
      </div>
      <EditOutlined
        onClick={() => (onPressEdit ? onPressEdit(question) : null)}
      />
      <MinusCircleOutlined
        className="district__screener-delete-button"
        onClick={() => {
          const _confirm = window.confirm(
            'Are you sure you want to delete this question?'
          );
          if (onPressDelete && _confirm) {
            onPressDelete(question);
          }
        }}
      />
    </div>
  );
};

export default ScreenerQuestionListItem;
