import './styles.css';

import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { fetchQuestions, setUser } from 'screener19-core/dist/redux';

import Header from 'components/Header';
import { User } from 'screener19-core';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

interface Props {
  user: User;
  visible?: boolean;
  onLogout: () => void;
  onNotify: (email: string) => void;
}

const AccountNotFound = ({ user, visible, onLogout, onNotify }: Props) => {
  const [loading, setLoading] = useState(false);

  let { email } = useParams<any>();

  useEffect(() => {
    if (visible) {
      setLoading(false);
    }
  }, [visible]);

  const onPressNotify = async () => {
    setLoading(true);
    await onNotify(email);
    setLoading(false);
  };

  return (
    <>
      <div className="checkin__header">
        <Header user={user} showLogo={false} onLogout={onLogout} />
      </div>
      <div className="accountnotfound__container">
        <div className="checkin__logo">
          <a href="/">
            <img
              src={`${process.env.REACT_APP_S3_URL}/assets/images/screener19-logo-v2.png`}
            />
          </a>
        </div>
        <Spin spinning={loading}>
          <Row className="accountnotfound__row">
            <Col>
              <h2>Uh oh. We couldn't find an account for {email}.</h2>
              <h2>
                <a href="https://accounts.google.com/login" target="_blank">
                  Click here to go to Google
                </a>{' '}
                and choose to sign in with your student Google account.
              </h2>
              <h3 className="accountnotfound_mistake">
                If you think this is a mistake, please{' '}
                <a onClick={onPressNotify}>click here</a> to notify Screener19
                or contact your school office.
              </h3>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = { fetchQuestions, setUser };

export default connect(mapStateToProps, mapDispatchToProps)(AccountNotFound);
