import { Button, Checkbox, Collapse, Divider, Form, Input } from 'antd';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Answer } from 'screener19-core';
import React from 'react';

const { Panel } = Collapse;
const { TextArea } = Input;

interface Props {
  answers?: Answer[];
  onReorderAnswers: (answers: Answer[]) => void;
}

const ScreenerQuestionFormAnswers = ({ answers, onReorderAnswers }: Props) => {
  const reorder = (
    list: Answer[],
    startIndex: number,
    endIndex: number
  ): Answer[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (!answers) return [];

    const items = reorder(
      answers,
      result.source.index,
      result.destination.index
    );

    onReorderAnswers(items);
  };

  return (
    <React.Fragment>
      <div className="screenerquestionsform__modal-section-header">
        <Divider>Answers</Divider>
        <small>Add all of the possible answers and set their options</small>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'draggable-answers'}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <Form.List
                name="answers"
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(
                          new Error('At least one answer is required')
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => {
                  return (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <Draggable
                            key={index}
                            draggableId={`id-${index}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Form.Item required={false} key={index}>
                                  <span className="screeneranswers__number">
                                    {index + 1}.
                                  </span>
                                  <Form.Item
                                    name={[index, 'text']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    noStyle
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: 'Text is required',
                                      },
                                    ]}
                                    label={
                                      <div className="screeneranswer__label">
                                        <div>
                                          <div>Answer</div>
                                        </div>
                                      </div>
                                    }
                                    tooltip={
                                      'The answer text that will be visible during the screener.'
                                    }
                                  >
                                    <Input
                                      className="screenerquestionsform__modal-answerinput"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => remove(index)}
                                  />
                                  <Collapse
                                    className="screeneranswers__options"
                                    ghost
                                  >
                                    <Panel
                                      header="Options"
                                      key={`panel-${index}`}
                                    >
                                      <Form.Item
                                        name={[index, 'responseText']}
                                        label={
                                          <div>
                                            <div>Summary text</div>
                                          </div>
                                        }
                                        tooltip="Shown to summarize the answer on the confirmation page"
                                      >
                                        <Input placeholder="" />
                                      </Form.Item>
                                      <Form.Item
                                        name={[index, 'historyText']}
                                        label={
                                          <div>
                                            <div>History text</div>
                                          </div>
                                        }
                                        tooltip="Shown in the user's history in the dashboard"
                                      >
                                        <Input placeholder="" />
                                      </Form.Item>
                                      <Form.Item
                                        name={[index, 'pendingText']}
                                        label={
                                          <div>
                                            <div>Pending text</div>
                                          </div>
                                        }
                                        tooltip="Shown in the dashboard pending user's table"
                                      >
                                        <Input placeholder="" />
                                      </Form.Item>
                                      <Form.Item
                                        name={[index, 'warn']}
                                        fieldKey={[index, 'warn']}
                                        valuePropName="checked"
                                        initialValue={false}
                                        noStyle
                                        tooltip="Shown in the user's history in the dashboard"
                                      >
                                        <Checkbox>
                                          Answer triggers "pending" status
                                        </Checkbox>
                                      </Form.Item>
                                      <Form.Item>
                                        <small>
                                          If this answer is selected the user
                                          will be automatically put in the
                                          "pending" bucket
                                        </small>
                                      </Form.Item>
                                      <Form.Item
                                        name={[index, 'disabledIfUnhealthy']}
                                        fieldKey={[
                                          index,
                                          'disabledIfUnhealthy',
                                        ]}
                                        valuePropName="checked"
                                        initialValue={false}
                                        noStyle
                                        tooltip="Shown in the user's history in the dashboard"
                                      >
                                        <Checkbox>
                                          Disable this answer if user is not
                                          "healthy"
                                        </Checkbox>
                                      </Form.Item>
                                      <Form.Item>
                                        <small>
                                          This answer will be disabled if the
                                          user is pending, quarantined, or
                                          isolated. This can prevent unhealthy
                                          users from selecting certain options
                                          altogether{' '}
                                          <i>(i.e. Coming to campus)</i>
                                        </small>
                                      </Form.Item>
                                      <Form.Item
                                        name={[index, 'finished']}
                                        fieldKey={[index, 'finished']}
                                        valuePropName="checked"
                                        initialValue={false}
                                        noStyle
                                        tooltip="Finished indicates this is the final set of answers"
                                      >
                                        <Checkbox>Finished</Checkbox>
                                      </Form.Item>
                                      <Form.Item>
                                        <small>
                                          Selecting "Finished" indicates this
                                          answer will trigger the end of the
                                          screener
                                        </small>
                                      </Form.Item>
                                    </Panel>
                                  </Collapse>
                                  <Divider></Divider>
                                </Form.Item>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Answer
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </React.Fragment>
  );
};

export default ScreenerQuestionFormAnswers;
