import './styles.css';

import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Space,
  message,
} from 'antd';
import React, { useState } from 'react';
import { School } from 'screener19-core';
import { useLocation, useParams } from 'react-router-dom';
import { ImportOutlined } from '@ant-design/icons';
import SchoolsNavMenu from 'components/SchoolsNavMenu';
import UsersList from 'components/UsersList';
import useAsyncEffect from 'use-async-effect';

const { Meta } = Card;

interface Props {
  school?: School;
  loading: boolean;
  loadData: (id: string) => void;
  onCreateUser: (school: School, data: any) => void;
  onCreateResponses: (
    id: string,
    date?: Date,
    maxNumResponses?: number
  ) => void;
  onImportStudents: (school: School) => void;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AdminSchoolProfile = ({
  school,
  loading,
  loadData,
  onCreateResponses,
  onCreateUser,
  onImportStudents,
}: Props) => {
  let { id } = useParams<any>();
  const [form] = Form.useForm();
  let location = useLocation();

  const [showForm, setShowForm] = useState(false);
  const [showCreateResponsesForm, setShowCreateResponsesForm] = useState(false);

  useAsyncEffect(async () => {
    if (id) {
      loadData(id);
    }
  }, []);

  const importStudents = () => {
    if (onImportStudents && school) {
      try {
        onImportStudents(school);
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const onFinishUser = (values: any) => {
    if (school && onCreateUser) {
      onCreateUser(school, values);
    }
  };

  let actions = [
    <>
      <ImportOutlined key="meh" onClick={importStudents} />
      <small>
        <a onClick={importStudents}>Import Students</a>
      </small>
    </>,
    <>
      <ImportOutlined
        key="smile"
        twoToneColor="#52c41a"
        onClick={() => setShowCreateResponsesForm(true)}
      />
      <small>
        <a onClick={() => setShowCreateResponsesForm(true)}>Create Responses</a>
      </small>
    </>,
  ];

  return (
    <div className="userprofile__container">
      <SchoolsNavMenu id={school?._id} selectedKeys={[location.pathname]} />
      <h2>{school?.name}</h2>
      <Card className="userprofile__card" actions={actions}></Card>
      <div className="admindistrict_table">
        <Button type="primary" onClick={() => setShowForm(true)}>
          Add User
        </Button>
        <UsersList users={school && school.users} loading={loading} />
      </div>
      <Modal
        title={'Create Responses'}
        footer={null}
        visible={showCreateResponsesForm}
        destroyOnClose={true}
        onCancel={() => setShowCreateResponsesForm(false)}
      >
        <Form
          {...layout}
          preserve={false}
          form={form}
          name="control-hooks"
          onFinish={(values: any) => {
            const date = values.date ? values.date.toDate() : new Date();
            onCreateResponses(id, date, values.maxNum);
          }}
        >
          <Form.Item name="date" label={'Date'}>
            <DatePicker />
          </Form.Item>
          <Form.Item name="maxNum" label="Max Number">
            <Input type="number" />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit">
                Go
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={'Add User'}
        onCancel={() => setShowForm(false)}
        footer={null}
        visible={showForm}
        destroyOnClose={true}
      >
        <Form
          {...layout}
          preserve={false}
          form={form}
          name="control-hooks"
          onFinish={onFinishUser}
        >
          <Form.Item
            name="firstName"
            label={'First Name'}
            rules={[{ required: true }]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={'Last Name'}
            rules={[{ required: true }]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item name="email" label={'Email'} rules={[{ required: true }]}>
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Add User
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminSchoolProfile;
