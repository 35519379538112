import './styles.css';
import QRCode from 'react-qr-code';
import React from 'react';
import { Spin } from 'antd';
import { User } from 'screener19-core';

interface Props {
  loading: boolean;
  user?: User;
}

const UserQRCode = ({ loading, user }: Props) => {
  const renderQR = () => {
    if (!!user) {
      const url = `${process.env.REACT_APP_BASE_URL}/checkin/${user?._id}`;
      return (
        <>
          <a href={url} target="_blank">
            <QRCode value={url} size={280} />
          </a>
          <h3>
            Check in with <b>{user.displayName}</b> by scanning the QR code with
            your phone's camera app
          </h3>
        </>
      );
    }
  };
  return (
    <div className="checkin__container">
      <div className="checkin__logo">
        <img
          src={`${process.env.REACT_APP_S3_URL}/assets/images/screener19-logo-v2.png`}
        />
      </div>
      <div className="checkin__results checkin__self">
        <Spin spinning={loading}>
          <div className="qrcode__container">{renderQR()}</div>
        </Spin>
      </div>
    </div>
  );
};

export default UserQRCode;
