import './styles.css';

import {
  Button,
  Col,
  Form,
  Input,
  Result,
  Row,
  Space,
  message,
} from 'antd';
import { QuestionCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';

import CheckInApi from 'screener19-core/dist/api/checkin';
import Header from 'components/Header';
import { User } from 'screener19-core';
import axiosInstance from 'util/api-util';
import { connect } from 'react-redux';
import { constants } from 'conf/constants';
import { fetchQuestions } from 'screener19-core/dist/redux';
import { format } from 'date-fns';
import { setUser } from 'screener19-core/dist/redux';

interface CheckInProps {
  user: User;
  onLogout: () => void;
  visible?: boolean;
}

const CheckIn = ({ user, visible, onLogout }: CheckInProps) => {
  let studentIdInput = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [student, setStudent] = useState<User | undefined>(undefined);

  const api = new CheckInApi(axiosInstance);

  const todaysDay = format(new Date(), 'EEEE');
  const cohorts = user?.district?.cohorts;

  const expectedCohorts = cohorts
    ? cohorts?.find((c) => c.day === todaysDay)?.cohorts
    : [];

  useEffect(() => {
    if (visible) {
      setLoading(false);
    }
    window.localStorage.removeItem(constants.LOGIN_REDIRECT_STORAGE_KEY);
  }, [visible]);

  const validateMessages = {
    required: '${label} is required!',
  };

  const fetchCheckIn = async (id: string) => {
    setLoading(true);
    try {
      const _user = await api.checkStudent(id);
      setError('');
      setStudent(_user);
    } catch (error) {
      console.log(error);

      const errorMsg =
        error.response && error.response.data && error.response.data.data
          ? error.response.data.data
          : error.message;

      message.error(errorMsg);
      setError(errorMsg);
    } finally {
      setLoading(false);
      form.setFieldsValue({ studentId: '' });
      const inputElement = (studentIdInput.current as unknown) as HTMLInputElement;

      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  const onFinishForm = async (values: any) => {
    setLoading(true);
    await fetchCheckIn(values.studentId);
  };

  const resultInfo = () => {
    return (
      <>
        <div className="results__info">
          <h4 className="results__studentId">{student?.displayName}</h4>
          <h5>{student?.studentId}</h5>
          {renderCohort()}
        </div>
      </>
    );
  };

  const renderCohort = () => {
    let correctCohort = false;
    if (student?.cohort) {
      const studentInTodaysCohort = expectedCohorts?.find(
        (cohort: string) => cohort === student.cohort
      );
      if (studentInTodaysCohort) {
        correctCohort = true;
      }
    }

    if (student?.cohort) {
      if (correctCohort) {
        return <h5>Cohort {student?.cohort}</h5>;
      } else {
        return (
          <div className="checkin__cohort-warning">
            <WarningTwoTone twoToneColor={constants.QUARANTINE_COLOR} />
            <h5>{`Cohort ${student.cohort}`}</h5>
          </div>
        );
      }
    }
  };

  const renderResults = () => {
    const user = student;
    if (user) {
      if (user.quarantined || user.monitored || user.needsReview) {
        // In quarantine or isolation
        return (
          <div className="checkin__results">
            <Row className="checkin__row">
              <Col
                xl={4}
                lg={8}
                md={16}
                sm={20}
                xs={20}
                className="checkin__result-col"
              >
                <Result
                  status="error"
                  title={resultInfo()}
                  subTitle=""
                ></Result>
              </Col>
            </Row>
          </div>
        );
      } else if (user.respondedToday) {
        // "healthy"
        return (
          <div className="checkin__results">
            <Row className="checkin__row">
              <Col
                xl={4}
                lg={8}
                md={16}
                sm={20}
                xs={20}
                className="checkin__result-col"
              >
                <Result
                  status="success"
                  title={resultInfo()}
                  subTitle=""
                ></Result>
              </Col>
            </Row>
          </div>
        );
      } else {
        // "unknown" - no screener from today
        return (
          <div className="checkin__results">
            <Row className="checkin__row">
              <Col
                xl={4}
                lg={8}
                md={16}
                sm={20}
                xs={20}
                className="checkin__result-col"
              >
                <div className="ant-result">
                  <div className="ant-result-icon">
                    <QuestionCircleTwoTone className="checkin__result-icon" />
                  </div>
                  {resultInfo()}
                </div>
              </Col>
            </Row>
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className="checkin__header">
        <Header user={user} showLogo={false} onLogout={onLogout} />
      </div>
      <div className="checkin__container">
        <div className="checkin__logo">
          <img
            src={`${process.env.REACT_APP_S3_URL}/assets/images/screener19-logo-v2.png`}
          />
        </div>
        <Row className="checkin__row">
          <Col xl={4} lg={8} md={16} sm={20} xs={20}>
            <Form
              className="checkin__form"
              layout="vertical"
              preserve={false}
              form={form}
              name="control-hooks"
              onFinish={onFinishForm}
              validateMessages={validateMessages}
            >
              <h2>Enter Student ID</h2>
              <Form.Item name="studentId">
                <Input
                  autoFocus
                  size="large"
                  ref={studentIdInput}
                  type="number"
                />
              </Form.Item>
              <p className="checkin__error">{error}</p>
              <Form.Item>
                <Space>
                  <Button loading={loading} type="primary" htmlType="submit">
                    Lookup Student ID
                  </Button>
                </Space>
              </Form.Item>
            </Form>
            <h3>{`Todays Cohorts: ${expectedCohorts?.join(', ') || 'N/A'}`}</h3>
          </Col>
        </Row>
      </div>
      {renderResults()}
    </>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = { fetchQuestions, setUser };

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
