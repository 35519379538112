import './styles.css';

import * as Sentry from '@sentry/react';

import { Checkbox, Layout, Modal, Select, Space, message } from 'antd';
import { Class, History, School, User, UserRole } from 'screener19-core';
import React, { useEffect, useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import {
  clearTodaysResponse,
  reset,
  resetRosters,
  setCurrentSchool,
  setDashboardIsolationEndingSoon,
  setDashboardIsolationEndingSoonStaff,
  setDashboardPending,
  setDashboardPendingStaff,
  setDashboardQuarantineEndingSoon,
  setDashboardQuarantineEndingSoonStaff,
  setDashboardStaffStats,
  setDashboardStats,
  setDistricts,
  setShowNames,
} from 'screener19-core/dist/redux';
import { connect, useDispatch } from 'react-redux';

import AccountHistory from 'components/AccountHistory';
import AuthRoute from 'components/AuthRoute';
import ClassDetails from 'components/ClassDetails';
import ClassesApi from 'screener19-core/dist/api/class';
import DashboardHome from 'components/DashboardHome';
import DashboardMenu from 'components/DashboardMenu';
import DashboardUserAccountLinks from 'components/DashboardUserAccountLinks';
import DistrictsApi from 'screener19-core/dist/api/districts';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RefreshingIndicator from 'components/RefreshingIndicator';
import SchoolsDropDownSelect from 'components/SchoolsDropDownSelect';
import StatsApi from 'screener19-core/dist/api/stats';
import UserProfile from 'components/UserProfile';
import UsersApi from 'screener19-core/dist/api/users';
import axiosInstance from 'util/api-util';
import compareDesc from 'date-fns/compareDesc';
import isPast from 'date-fns/isPast';
import useAsyncEffect from 'use-async-effect';

const { Header, Content, Sider } = Layout;
const { Option } = Select;

const DashBoard = (props: any) => {
  const [collapsed, setCollapsed] = useState(false);
  const [currentMenuKey, setCurrentMenuKey] = useState('');
  const [siderBroken, setSiderBroken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [cls, setClass] = useState<Class>();
  const [classes, setClasses] = useState<Class[]>();
  const [userProfile, setUserProfile] = useState<User>();
  const [showSchoolDropdown, setShowSchoolDropdown] = useState(true);
  const [accountHistory, setAccountHistory] = useState<History[]>([]);

  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();

  message.config({
    duration: 2,
    maxCount: 1,
  });

  useAsyncEffect(async () => {
    if (props.user.permissions && props.user.permissions.canViewAdmin) {
      try {
        const api = new DistrictsApi(axiosInstance);
        const districts = await api.getAll();

        if (districts) {
          props.setDistricts(districts);
        }
      } catch (error) {
        Sentry.captureException(error);
        message.error(error.message);
      }
    }

    if (props.user?.email) {
      Sentry.setUser({ email: props.user?.email });

      Sentry.setContext('user', {
        name: props.user?.displaName,
        email: props.user?.email,
        permissions: props.user?.permissions,
      });
    }
  }, []);

  useAsyncEffect(async () => {
    const isStaffPath = location.pathname.match(/^\/dashboard\/staff(\/?)/g);
    const loading =
      (isStaffPath && !props.dashboard.staffStats) ||
      (!isStaffPath && !props.dashboard.stats);
    loading && setLoading(true);
    await fetchStats(!!isStaffPath);
    setLoading(false);
  }, [props.dashboard.currentSchool]);

  useEffect(() => {
    const isUserPath = location.pathname.match(
      /^\/dashboard\/(user|students)(\/?)/g
    );
    const isStaffPath = location.pathname.match(/^\/dashboard\/staff(\/?)/g);

    // setCurrentMenuKey(location.pathname);
    // return;

    if (isUserPath || isStaffPath) {
      if (isUserPath) {
        setCurrentMenuKey('/dashboard/students');
      }
      if (isStaffPath) {
        setCurrentMenuKey('/dashboard/staff');
      }

      const isProfile = location.pathname.match(
        /^\/dashboard\/(students|staff)(\/?)$/g
      );
      setShowSchoolDropdown(isProfile !== null);
      return;
    }

    setShowSchoolDropdown(true);

    setCurrentMenuKey(location.pathname);
  }, [location]);

  const fetchAccountHistory = async (id: string) => {
    const userApi = new UsersApi(axiosInstance);

    const { getAccountHistory } = userApi;

    setLoadingHistory(true);
    try {
      const history = await getAccountHistory(id);
      setAccountHistory(history);
    } catch (error) {
      message.error(error.message);
      Sentry.captureException(error);
    } finally {
      setLoadingHistory(false);
    }
  };

  const fetchUserById = async (id: string) => {
    const userApi = new UsersApi(axiosInstance);

    const { getUser } = userApi;

    setLoading(true);
    try {
      const _user = await getUser(id);
      setUserProfile(_user);

      // get classes for staff member
      if (_user.role !== UserRole.USER) {
        const classes = await userApi.getUserClasses(id);
        setClasses(classes);
      }
    } catch (error) {
      message.error(error.message);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchClass = async (classId: string) => {
    setLoading(true);
    try {
      const classesApi = new ClassesApi(axiosInstance);
      const _class = await classesApi.getClass(classId);
      setClass(_class);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchStats = async (staff = false) => {
    const api = new StatsApi(axiosInstance);
    try {
      setLoadingStats(true);

      let school =
        props.dashboard?.currentSchool ||
        props.user?.homeSchool?._id ||
        (props.user.schools.length &&
        props.user.schools[0] &&
        props.user.schools[0]._id
          ? props.user.schools[0]._id
          : '') ||
        '';

      if (
        !school &&
        props.user.schools &&
        props.user.schools[0] &&
        props.user.schools[0]._id
      ) {
        school = props.user.schools[0]._id;
        await onChangeSchool(school);
      }

      if (school) {
        let _stats;
        if (!staff) {
          _stats = await api.getStats(school);

          _stats = {
            ..._stats,
            pendingUsers: _stats.pendingUsers.sort((a: any, b: any) =>
              compareDesc(new Date(a.needsReviewAt), new Date(b.needsReviewAt))
            ),
            isolationEndingSoon: _stats.isolationEndingSoon.sort(
              (a: any, b: any) =>
                compareDesc(
                  new Date(b.monitoredEndAt),
                  new Date(a.monitoredEndAt)
                )
            ),
            quarantineEndingSoon: _stats.quarantineEndingSoon.sort(
              (a: any, b: any) =>
                compareDesc(
                  new Date(b.quarantineEndAt),
                  new Date(a.quarantineEndAt)
                )
            ),
          };

          let numQuarantinedPastDate = 0;
          if (_stats.quarantineEndingSoon?.length) {
            numQuarantinedPastDate = _stats.quarantineEndingSoon.reduce(
              (numPast: number, user: any) => {
                if (isPast(new Date(user.quarantineEndAt))) {
                  return numPast + 1;
                }
                return numPast;
              },
              0
            );
          }

          let numIsolatedPastDate = 0;
          if (_stats.isolationEndingSoon?.length) {
            numIsolatedPastDate = _stats.isolationEndingSoon.reduce(
              (numPast: number, user: any) => {
                if (isPast(new Date(user.monitoredEndAt))) {
                  return numPast + 1;
                }
                return numPast;
              },
              0
            );
          }

          _stats.numberOfUsersOverdue =
            numQuarantinedPastDate + numIsolatedPastDate;

          _stats && props.setDashboardStats(_stats);
        } else {
          _stats = await api.getStaffStats(school);

          _stats = {
            ..._stats,
            pendingUsers: _stats.pendingUsers.sort((a: any, b: any) =>
              compareDesc(new Date(a.needsReviewAt), new Date(b.needsReviewAt))
            ),
            isolationEndingSoon: _stats.isolationEndingSoon.sort(
              (a: any, b: any) =>
                compareDesc(
                  new Date(b.monitoredEndAt),
                  new Date(a.monitoredEndAt)
                )
            ),
            quarantineEndingSoon: _stats.quarantineEndingSoon.sort(
              (a: any, b: any) =>
                compareDesc(
                  new Date(b.quarantineEndAt),
                  new Date(a.quarantineEndAt)
                )
            ),
          };

          _stats && props.setDashboardStaffStats(_stats);
        }
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
      Sentry.captureException(error);
    } finally {
      setLoadingStats(false);
    }
  };

  const clearUser = async (
    id: string,
    monitored: Boolean,
    quarantined: Boolean
  ) => {
    const userApi = new UsersApi(axiosInstance);
    setLoading(true);
    try {
      const resp = await userApi.clearUser(id, monitored, quarantined);
      await fetchUserById(id);
      message.success('User status has been updated');
    } catch (error) {
      message.error(error.message);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (id: string) => {
    Modal.confirm({
      title: 'Delete this user?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          This will permanently delete this user and all of their associated
          screener data, history, notes, test results, etc.
          <br />
          <br />
          <b>This is permanent and can not be reverted.</b>
        </div>
      ),
      okText: 'Yes, Delete this user',
      cancelText: 'Cancel',
      onOk: async () => {
        const userApi = new UsersApi(axiosInstance);
        setLoading(true);
        try {
          const resp = await userApi.deleteUser(id);
          message.success('User has been deleted');
        } catch (error) {
          message.error(error.message);
          Sentry.captureException(error);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const monitorUser = async (id: string, start: Date, end: Date) => {
    const userApi = new UsersApi(axiosInstance);
    setLoading(true);
    try {
      const resp = await userApi.monitorUser(id, start, end);
      await fetchUserById(id);
      message.success('User is now isolated');
    } catch (error) {
      message.error(error.message);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const quarantineUser = async (id: string, startDate: Date, endDate: Date) => {
    const userApi = new UsersApi(axiosInstance);
    setLoading(true);
    try {
      const resp = await userApi.quarantineUser(id, startDate, endDate);
      await fetchUserById(id);
      message.success('Quarantine started');
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (
    id: string,
    district: string,
    role: string,
    schools: Array<string>,
    isTechAdmin: boolean
  ) => {
    const userApi = new UsersApi(axiosInstance);
    setLoading(true);
    try {
      const resp = await userApi.updateUser(
        id,
        district,
        role,
        schools,
        isTechAdmin
      );
      console.log(resp);
      await fetchUserById(id);
      message.success('User updated');
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const canViewDashboard = () => {
    return (
      !!props.user &&
      !!props.user.permissions &&
      props.user.permissions.canViewDashboard
    );
  };

  const onChangeSchool = async (id: string) => {
    await dispatch(setCurrentSchool(id));
    await props.resetRosters();
  };

  const onChangeHomeSchool = async (school: string) => {
    try {
      const userApi = new UsersApi(axiosInstance);
      userProfile && (await userApi.updateHomeSchool(userProfile?._id, school));
      userProfile && (await fetchUserById(userProfile?._id));
      message.success('Home school updated');
    } catch (error) {
      message.error('There was a problem updating the home school');
    }
  };

  const defaultSchool =
    props.dashboard?.currentSchool ||
    props.user?.homeSchool?._id ||
    (props.user.schools.length &&
    props.user.schools[0] &&
    props.user.schools[0]._id
      ? props.user.schools[0]._id
      : '') ||
    '';

  return (
    <Layout
      style={{ minHeight: '100vh' }}
      className={!siderBroken ? 'dashboard__collapsed' : ''}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(c) => setCollapsed(c)}
        onBreakpoint={(b) => setSiderBroken(b)}
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div className="logo" />
        <DashboardMenu
          canViewAdmin={props.user?.permissions?.canViewAdmin}
          canViewSettings={props.user?.isTechAdmin}
          currentMenuKey={currentMenuKey}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="dashboard__header">
          <div>
            <Space>
              <SchoolsDropDownSelect
                defaultSchool={defaultSchool}
                onChange={onChangeSchool}
                schools={props.user.schools}
                showSchoolDropdown={showSchoolDropdown}
              />
              <Checkbox
                checked={props.dashboard.showNames}
                onChange={(e) => props.setShowNames(e.target.checked)}
              >
                <small>Show Personal Information</small>
              </Checkbox>
            </Space>
          </div>
          <DashboardUserAccountLinks
            user={props.user}
            onLogout={props.onLogout}
          />
        </Header>
        <Content className="content__container">
          <RefreshingIndicator loading={loadingStats} />
          <AuthRoute exact auth={canViewDashboard()} path="/dashboard">
            <div className="dashboard__list">
              <DashboardHome
                currentSchool={props.dashboard.currentSchool}
                loading={loading}
                loggedInUser={props.user}
                onChangePending={(users) => {
                  users && props.setDashboardPending(users);
                }}
                onChangeQuarantineEndingSoon={(users) => {
                  users && props.setDashboardQuarantineEndingSoon(users);
                }}
                onChangeIsolationEndingSoon={(users) => {
                  users && props.setDashboardIsolationEndingSoon(users);
                }}
                onClickTests={(showCurrentSchool) => {
                  if (showCurrentSchool) {
                    history.push(
                      `/dashboard/reports/covid-tests/${props.dashboard.currentSchool}`
                    );
                  } else {
                    history.push('/dashboard/reports/covid-tests');
                  }
                }}
                fetchStats={() => fetchStats()}
                questions={props.questions}
                stats={props.dashboard.stats}
                showNames={props.dashboard.showNames}
              />
            </div>
          </AuthRoute>
          <AuthRoute exact auth={canViewDashboard()} path="/dashboard/staff">
            <div className="dashboard__list">
              <DashboardHome
                currentSchool={props.dashboard.currentSchool}
                loading={loading}
                loggedInUser={props.user}
                onChangePending={(users) => {
                  users && props.setDashboardPendingStaff(users);
                }}
                onChangeQuarantineEndingSoon={(users) => {
                  users && props.setDashboardQuarantineEndingSoonStaff(users);
                }}
                onChangeIsolationEndingSoon={(users) => {
                  users && props.setDashboardIsolationEndingSoonStaff(users);
                }}
                onClickTests={() =>
                  history.push('/dashboard/reports/covid-tests')
                }
                fetchStats={() => fetchStats(true)}
                questions={props.questions}
                stats={props.dashboard.staffStats}
                showNames={props.dashboard.showNames}
                urlKey={'staff'}
              />
            </div>
          </AuthRoute>
          <Switch>
            <AuthRoute
              auth={canViewDashboard()}
              path={['/dashboard/user/:id/history']}
            >
              <AccountHistory
                history={accountHistory}
                loadData={async (id) => {
                  id && (await fetchUserById(id));
                  id && fetchAccountHistory(id);
                }}
                loading={loadingHistory}
                loggedInUser={props.user}
                showNames={props.dashboard.showNames}
                user={userProfile}
              />
            </AuthRoute>
            <AuthRoute
              auth={canViewDashboard()}
              path={[
                '/dashboard/staff/:id',
                '/dashboard/user/:id/:bucket',
                '/dashboard/user/:id',
              ]}
            >
              <UserProfile
                changeHomeSchool={onChangeHomeSchool}
                classes={classes}
                clearUser={clearUser}
                deleteUser={deleteUser}
                districts={props.districts}
                fetchUserById={fetchUserById}
                loggedInUser={props.user}
                monitorUser={monitorUser}
                quarantineUser={quarantineUser}
                rosters={props.rosters}
                stats={props.dashboard.stats}
                staffStats={props.dashboard.staffStats}
                updateUser={updateUser}
                showNames={props.dashboard.showNames}
                user={userProfile}
              />
            </AuthRoute>
          </Switch>
          <AuthRoute auth={canViewDashboard()} path={['/dashboard/class/:id']}>
            <ClassDetails
              cls={cls}
              fetchClass={fetchClass}
              loggedInUser={props.user}
              showNames={props.dashboard.showNames}
            />
          </AuthRoute>
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    dashboard: state.dashboard,
    districts: state.districts,
    questions: state.questions,
    rosters: state.rosters,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearTodaysResponse,
  reset,
  resetRosters,
  setDashboardStats,
  setDashboardStaffStats,
  setDashboardPending,
  setDashboardQuarantineEndingSoon,
  setDashboardIsolationEndingSoon,
  setDashboardPendingStaff,
  setDashboardIsolationEndingSoonStaff,
  setDashboardQuarantineEndingSoonStaff,
  setDistricts,
  setShowNames,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
