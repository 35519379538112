import './styles.less';

import { Answer, District, Question } from 'screener19-core';
import { Button, Space } from 'antd';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import ScreenerQuestionFormModal from 'components/ScreenerQuestionFormModal';
import ScreenerQuestionListItem from 'components/ScreenerQuestionListItem';

interface Props {
  baseQuestions?: Question[];
  district?: District;
  onResetQuestions: () => Promise<Question[]>;
  onUpdateQuestions: (questions: Question[]) => void;
}

const DistrictSettingsQuestions = ({
  district,
  baseQuestions,
  onResetQuestions,
  onUpdateQuestions,
}: Props) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [questionToEdit, setQuestionToEdit] = useState<Question | undefined>();
  const [showForm, setShowForm] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (baseQuestions && !questions?.length) {
      setQuestions(baseQuestions);
    }
  }, [baseQuestions]);

  // a little function to help us with reordering the result
  const reorder = (
    list: Question[],
    startIndex: number,
    endIndex: number
  ): Question[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      questions,
      result.source.index,
      result.destination.index
    );

    setQuestions(
      items.map((item: Question, index: number) => {
        return { ...item, order: index };
      })
    );
  };

  const onPressResetQuestions = () => {
    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content:
        'This will reset the screener questions to the Screener19 defaults',
      okText: 'Reset to Default',
      cancelText: 'Cancel',
      onOk: async () => {
        const _questions = await onResetQuestions();
        setQuestions(_questions);
      },
    });
  };

  return (
    <div className="districtsettings__container">
      <div className="userslist__header">
        <div>
          <h1 className="userslist__headerlabel">Screener Questions</h1>
          <small>
            Screener questions and answers can be customized and re-ordered at
            any time
          </small>
        </div>
        <div className="userslist__header-col">
          <div className="userslist__header-button">
            <Space>
              <Button type="primary" onClick={() => setShowForm(true)}>
                Add Question
              </Button>
              <Button type="default" onClick={onPressResetQuestions}>
                Resets Question to Default
              </Button>
            </Space>
          </div>
        </div>
      </div>
      <div className="admindistrict_table">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {questions.map((item: Question, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`districtsettings__screener-question 
                          ${
                            snapshot.isDragging
                              ? 'districtsettings__screener-question-drag'
                              : ''
                          }`}
                      >
                        <ScreenerQuestionListItem
                          question={item}
                          onPressEdit={(q: Question) => {
                            const question = {
                              ...q,
                              answers: q.answers.map((a: Answer) => {
                                return {
                                  ...a,
                                  warn: a.warn || a.quarantine,
                                };
                              }),
                            };

                            setQuestionToEdit(question);
                            setShowForm(true);
                          }}
                          onPressDelete={(q: Question) => {
                            const _questions = questions.filter(
                              (question) => q._id !== question._id
                            );
                            setQuestions(_questions);
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <ScreenerQuestionFormModal
        loading={false}
        visible={showForm}
        onCancel={() => setShowForm(false)}
        onFinish={(values: any) => {
          let _questions;
          if (questionToEdit) {
            _questions = questions.map((q: Question, i) => {
              if (q._id === questionToEdit?._id) {
                return {
                  ...q,
                  ...values,
                };
              }

              return q;
            });
          } else {
            _questions = [...questions, { ...values, order: questions.length }];
          }

          setQuestions(_questions);
          setQuestionToEdit(undefined);
          setShowForm(false);
        }}
        question={questionToEdit}
      />
      <Button
        type="primary"
        loading={saving}
        onClick={async () => {
          setSaving(true);
          await onUpdateQuestions(
            questions.map((q: Question, i: number) => {
              return { ...q, order: i };
            })
          );
          setSaving(false);
        }}
      >
        Save Questions
      </Button>
    </div>
  );
};

export default DistrictSettingsQuestions;
