import { Button, Modal, Space } from 'antd';

interface Props {
  onCancel: () => void;
  onPressAddIncident: () => void;
  onPressAddTestResult: () => void;
  visible: boolean;
}

const UserChooseIncidentFormModal = ({
  onCancel,
  onPressAddIncident,
  onPressAddTestResult,
  visible,
}: Props) => {
  return (
    <Modal title="" visible={visible} footer={null} onCancel={onCancel}>
      <Space direction="vertical">
        <div>
          <h3>COVID-19 Test</h3>
          <p>
            Add new COVID-19 test information. This helps track positive case
            numbers across the district.
          </p>
          <Button type="primary" onClick={onPressAddTestResult}>
            Add Test
          </Button>
        </div>
        <div className="userprofile__form-modal-choices">
          <h3>Incident Form</h3>
          <p>
            Add a new incident form to track exposure, contact or to help chart
            any COVID related actions that a nurse has taken
          </p>
          <Button type="primary" onClick={onPressAddIncident}>
            Add Incident Form
          </Button>
        </div>
      </Space>
    </Modal>
  );
};

export default UserChooseIncidentFormModal;
