import React from 'react';
import { Result } from 'antd';

const Page403 = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to view this page."
    />
  );
};

export default Page403;
