import './styles.css';

import { School } from 'screener19-core';
import { Select } from 'antd';

const Option = Select.Option;

interface Props {
  defaultSchool: string;
  onChange: (id: string) => void;
  showSchoolDropdown: boolean;
  schools?: School[];
}

const SchoolsDropDownSelect = ({
  defaultSchool,
  onChange,
  showSchoolDropdown,
  schools = [],
}: Props) => {
  if (showSchoolDropdown && schools && schools?.length > 1) {
    return (
      <Select
        className="dashboard__header-select"
        size="large"
        defaultValue={defaultSchool}
        onChange={onChange}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={(input, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {schools?.map((school: School) => {
          return <Option value={school._id}>{school.name}</Option>;
        })}
      </Select>
    );
  } else {
    const school = schools?.find((s: any) => s && s._id === defaultSchool);
    return <h3 className="dashboard__header-schoolname">{school?.name}</h3>;
  }
};

export default SchoolsDropDownSelect;
