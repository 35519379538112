import './styles.css';

import { Typography } from 'antd';
import { Answer, Question, User } from 'screener19-core';

import Button from 'components/Button';
import React from 'react';
import { Redirect } from 'react-router-dom';

const { Paragraph, Text } = Typography;

interface Props {
  onPressSubmit?: () => void;
  onPressRestart?: () => void;
  questions: Array<Question>;
  user: User;
}

const ScreenerConfirmation = ({
  onPressSubmit,
  onPressRestart,
  questions,
  user,
}: Props) => {
  if (!questions) {
    return <Redirect to="/screener/" />;
  }

  const answeredQs = questions.find((q) => q.answered);

  if (!answeredQs) {
    return <Redirect to="/" />;
  }

  const renderAnswer = (a: Answer, i: number) => {
    return (
      <span
        key={`question-${i}`}
        dangerouslySetInnerHTML={{
          __html: a?.responseText,
        }}
      />
    );
  };

  const renderResponses = () => {
    return (
      <>
        <div>
          <h1 className="header-label">
            Please confirm your responses for today before submitting them.
          </h1>
          <div className="results__responses-list">
            {questions.map((q: Question, i: number) => {
              if (q.answered) {
                const answers = q.answers.filter((a: Answer) => a.selected);
                if (answers?.length) {
                  // render all the answers
                  if (q.showAnswersOnExit) {
                    return (
                      <div key={i} className="results__responses-listitem">
                        <span>{q.exitAnswerText}</span>
                        {answers
                          .map<React.ReactNode>((a) => {
                            return renderAnswer(a, i);
                          })
                          .reduce((prev, curr) => [prev, ',\u00A0', curr])}
                      </div>
                    );
                  }
                  // just render first answer
                  return (
                    <div key={i} className="results__responses-listitem">
                      {renderAnswer(answers[0], i)}
                    </div>
                  );
                }
              }
            })}
          </div>
        </div>
      </>
    );
  };

  const maintainDistanceText = () => {
    // Albany USD
    if (user?.district?._id?.toString() === '6010329248f6b90dcbd9f47c') {
      return 'Maintain a minimum of four (4) foot distance from others';
    }

    return 'Maintain a minimum of six (6) foot distance from others';
  };

  const tellMyTeacherText = () => {
    // Corcoran USD
    if (user?.district?._id?.toString() === '6037bc5be99ab400073edc16') {
      return '';
    }

    return (
      <li>Tell my teacher and go see the school nurse if I do not feel well</li>
    );
  };

  const renderReminders = () => {
    return (
      <div className="desc">
        <Paragraph>
          <Text
            style={{
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            By submitting my responses, I certify that all information is true
            and correct to the best of my knowledge.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text
            style={{
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            I acknowledge that it is my responsibility to:
          </Text>
        </Paragraph>
        <Paragraph>
          <ul>
            {tellMyTeacherText()}
            <li>
              Wash my hands with soap and water or use hand sanitizer each time
              I enter or leave my individual work station
            </li>
            <li>{maintainDistanceText()}</li>
            <li>
              {' '}
              Sneeze or cough into a cloth or a tissue, or if not available, my
              elbow
            </li>
            <li>
              Wear a face-covering prior to entering the school and keep the
              face-covering on while on campus
            </li>
            <li>
              Not shake hands, hug or engage in unnecessary physical contact
            </li>
          </ul>
        </Paragraph>
      </div>
    );
  };

  return (
    <div className="screener__confirmation">
      <div className="screener__confirm-body">
        <div className="max-size-container">
          <div className="results__responses">{renderResponses()}</div>
          {renderReminders()}
          <Button
            label="Submit"
            onPress={() => (onPressSubmit ? onPressSubmit() : null)}
          />

          <div className="screener__start-over">
            <a onClick={onPressRestart}>Did you make a mistake? Start over.</a>
          </div>
        </div>
      </div>
      <div className="results__body"></div>
    </div>
  );
};

export default ScreenerConfirmation;
