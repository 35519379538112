import { Button, Form, Input, Modal } from 'antd';

import React from 'react';
import RolesDropdownSelect from 'components/RolesDropdownSelect';

interface Props {
  loading: boolean;
  onCancel: () => void;
  onFinish: (values: any) => void;
  title?: string;
  visible: boolean;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AddUserModal = ({
  loading,
  onCancel,
  onFinish,
  title = 'Add User',
  visible,
}: Props) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title={title}
      onCancel={onCancel}
      footer={null}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        {...layout}
        preserve={false}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Form.Item
          name="firstName"
          label={'First Name'}
          rules={[{ required: true }]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={'Last Name'}
          rules={[{ required: true }]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item name="email" label={'Email'} rules={[{ required: true }]}>
          <Input placeholder="Email" />
        </Form.Item>
        <RolesDropdownSelect />
        <Form.Item {...tailLayout}>
          <Button loading={loading} type="primary" htmlType="submit">
            {!loading ? title : 'Creating...'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
