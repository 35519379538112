import './styles.css';

import { Layout, Space } from 'antd';
import React, { useState } from 'react';
import DashboardMenu from 'components/DashboardMenu';
import { User } from 'screener19-core';

const { Header, Content, Sider } = Layout;

interface Props {
  children: any;
  currentMenuKey: string;
  onLogout: () => void;
  pageTitle?: string;
  user: User;
}

const DashboardContainer = ({
  children,
  currentMenuKey,
  pageTitle,
  user,
  onLogout,
  ...props
}: Props) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(c) => setCollapsed(c)}
        theme="light"
      >
        <div className="logo" />
        <DashboardMenu
          canViewAdmin={user.permissions?.canViewAdmin}
          canViewSettings={user.isTechAdmin}
          currentMenuKey={currentMenuKey}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="dashboard__header">
          <div>
            <Space>
              <h3 className="dashboard__header-title">{pageTitle}</h3>
            </Space>
          </div>
          <div>
            {user.displayName} | <a onClick={onLogout}>Logout</a>
          </div>
        </Header>
        <Content className="content__container">{children}</Content>
      </Layout>
    </Layout>
  );
};

export default DashboardContainer;
