import './styles.css';

import * as Sentry from '@sentry/react';

import { CheckCircleOutlined, CheckSquareFilled } from '@ant-design/icons';
import { Checkbox, Layout, Menu, Select, Space, message } from 'antd';
import { Class, History, School, User, UserRole } from 'screener19-core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import {
  clearTodaysResponse,
  reset,
  resetRosters,
  setCurrentSchool,
  setDistricts,
  setShowNames,
  setUser,
} from 'screener19-core/dist/redux';
import { connect, useDispatch } from 'react-redux';

import AccountHistory from 'components/AccountHistory';
import AuthRoute from 'components/AuthRoute';
import ClassDetails from 'components/ClassDetails';
import ClassesApi from 'screener19-core/dist/api/class';
import DashboardMenu from 'components/DashboardMenu';
import DashboardUserAccountLinks from 'components/DashboardUserAccountLinks';
import SchoolsDropDownSelect from 'components/SchoolsDropDownSelect';
import UserProfile from 'components/UserProfile';
import UsersApi from 'screener19-core/dist/api/users';
import axiosInstance from 'util/api-util';
import useAsyncEffect from 'use-async-effect';

const { Header, Content, Sider } = Layout;
const { Option } = Select;

const Account = (props: any) => {
  const [collapsed, setCollapsed] = useState(false);
  const [currentMenuKey, setCurrentMenuKey] = useState('');
  const [siderBroken, setSiderBroken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cls, setClass] = useState<Class>();
  const [classes, setClasses] = useState<Class[]>();
  const [userProfile, setUserProfile] = useState<User>();
  const [showSchoolDropdown, setShowSchoolDropdown] = useState(true);
  const [accountHistory, setAccountHistory] = useState<History[]>([]);

  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();

  message.config({
    duration: 2,
    maxCount: 1,
  });

  useAsyncEffect(async () => {
    if (props.user?.email) {
      Sentry.setUser({ email: props.user?.email });

      Sentry.setContext('user', {
        name: props.user?.displaName,
        email: props.user?.email,
        permissions: props.user?.permissions,
      });
    }
  }, []);

  useAsyncEffect(async () => {
    setLoading(true);
    await fetchUserProfile(props.user._id);
    setLoading(false);
  }, [props.dashboard.currentSchool]);

  const fetchClass = async (classId: string) => {
    setLoading(true);
    try {
      const classesApi = new ClassesApi(axiosInstance);
      const _class = await classesApi.getClass(classId);
      setClass(_class);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserProfile = async (id: string) => {
    const userApi = new UsersApi(axiosInstance);

    const { getMe } = userApi;

    setLoading(true);
    try {
      const _user = await getMe();

      // get classes for staff member
      if (_user.role !== UserRole.USER) {
        const classes = await userApi.getUserClasses(id);
        setClasses(classes);
      }

      setUserProfile(_user);
      props.setUser(_user);
    } catch (error) {
      message.error(error.message);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAccountHistory = async () => {
    const userApi = new UsersApi(axiosInstance);

    const { getMyAccountHistory } = userApi;

    setLoading(true);
    try {
      const history = await getMyAccountHistory();
      setAccountHistory(history);
    } catch (error) {
      message.error(error.message);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeSchool = async (id: string) => {
    dispatch(setCurrentSchool(id));
    props.resetRosters();
  };

  const onChangeHomeSchool = async (school: string) => {
    try {
      const userApi = new UsersApi(axiosInstance);
      await userApi.updateHomeSchool(props.user._id, school);
      await fetchUserProfile(props.user._id);

      message.success('Home school updated');
    } catch (error) {
      message.error('There was a problem updating the home school');
    }
  };

  const defaultSchool =
    props.dashboard && props.dashboard.currentSchool
      ? props.dashboard.currentSchool
      : props.user.schools && props.user.schools.length
      ? props.user.schools[0] && props.user.schools[0]._id
        ? props.user.schools[0]._id
        : ''
      : '';

  return (
    <Layout
      style={{ minHeight: '100vh' }}
      className={!siderBroken ? 'dashboard__collapsed' : ''}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(c) => setCollapsed(c)}
        onBreakpoint={(b) => setSiderBroken(b)}
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div className="logo" />
        {props.user?.permissions?.canViewDashboard && (
          <DashboardMenu
            canViewAdmin={props.user?.permissions?.canViewAdmin}
            canViewSettings={props.user?.isTechAdmin}
            currentMenuKey={currentMenuKey}
          />
        )}
        {!props.user?.permissions?.canViewDashboard && (
          <Menu
            theme="light"
            selectedKeys={[currentMenuKey]}
            defaultOpenKeys={['dash', 'users']}
            mode="inline"
          >
            <Menu.Item key="/" icon={<CheckCircleOutlined />}>
              <Link to="/">Screener</Link>
            </Menu.Item>
            <Menu.Item key="/checkin" icon={<CheckSquareFilled />}>
              <Link to="/checkin">Check-In</Link>
            </Menu.Item>
            <Menu.Divider key="divider-0" />
          </Menu>
        )}
      </Sider>
      <Layout className="site-layout">
        <Header className="dashboard__header">
          <div>
            <Space>
              <SchoolsDropDownSelect
                defaultSchool={defaultSchool}
                onChange={onChangeSchool}
                schools={props.user.schools}
                showSchoolDropdown={showSchoolDropdown}
              />
              <Checkbox
                checked={props.dashboard.showNames}
                onChange={(e) => props.setShowNames(e.target.checked)}
              >
                <small>Show Personal Information</small>
              </Checkbox>
            </Space>
          </div>
          <DashboardUserAccountLinks
            user={props.user}
            onLogout={props.onLogout}
          />
        </Header>
        <Content className="content__container">
          <AuthRoute
            exact
            auth={props.user?.permissions?.canViewCheckin}
            path={['/account']}
          >
            <UserProfile
              changeHomeSchool={onChangeHomeSchool}
              classes={classes}
              districts={props.districts}
              loggedInUser={props.user}
              showNames={props.dashboard.showNames}
              showQrCode={false}
              user={props.user}
            />
          </AuthRoute>
          <AuthRoute
            exact
            auth={props.user?.permissions?.canViewCheckin}
            path={['/account/history']}
          >
            <AccountHistory
              history={accountHistory}
              loading={loading}
              loggedInUser={props.user}
              loadData={fetchAccountHistory}
              showNames={props.dashboard.showNames}
              user={props.user}
            />
          </AuthRoute>
          <AuthRoute
            auth={props.user?.permissions?.canViewCheckin}
            path={['/account/class/:id']}
          >
            <ClassDetails
              cls={cls}
              fetchClass={fetchClass}
              loggedInUser={props.user}
              showNames={props.dashboard.showNames}
            />
          </AuthRoute>
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    dashboard: state.dashboard,
    districts: state.districts,
    questions: state.questions,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearTodaysResponse,
  reset,
  resetRosters,
  setDistricts,
  setShowNames,
  setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
