import './styles.css';

import { District, User, UserRole } from 'screener19-core';

import React from 'react';

const CustomScreenerHeader = ({
  user,
  district,
}: {
  user: User;
  district: District;
}) => {
  // Acton Agua Dulce
  if (district?._id?.toString() !== '6010329248f6b90dcbd9f47c') return <div />;

  if (user.role === UserRole.USER) {
    return (
      <div className="screener__customheader">
        If your student has tested positive for COVID-19 in the past 10 days,
        please contact your school site immediately with the date of your
        positive test date and date your student’s symptoms began. If your
        student lives with another AUSD employee or student, they will also need
        to stay home.
      </div>
    );
  } else {
    return (
      <div className="screener__customheader">
        If you have tested positive for COVID-19 in the past 10 days, please
        contact your school site immediately with the date of your positive test
        date and date your symptoms began. If you live with another AUSD
        employee or a student, they will also need to stay home.
      </div>
    );
  }
};

export default CustomScreenerHeader;
