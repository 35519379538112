import { ColumnProps } from 'antd/lib/table';

// This function can be used with antd's Table component and will perform the
// necessary sorting and filtering logic on the records, given the value of columns.
// It is useful when you want to create a CSV with data that mirrors data in an antd
// table
export function filterAndSortTableData<R>(
  records: R[],
  columns: ColumnProps<R>[]
) {
  return records
    .filter((record) => {
      for (let col of columns) {
        const { filteredValue, onFilter } = col;
        if (onFilter && filteredValue && filteredValue.length > 0) {
          if (filteredValue.filter((val) => onFilter(val, record)).length === 0)
            return false;
        }
      }
      return true;
    })
    .sort((a, b) => {
      const sort = (col: ColumnProps<R>) => {
        const { sorter, sortOrder } = col;
        if (sorter && sorter instanceof Function && !isBool(sortOrder)) {
          return sorter(a, b, sortOrder);
        }
        return 0;
      };

      const sortColumn = columns.find((c) => !!c.sortOrder);
      if (sortColumn) return sort(sortColumn);

      const defaultSortColumn = columns.find((c) => !!c.defaultSortOrder);
      if (defaultSortColumn) return sort(defaultSortColumn);

      return 0;
    })
    .map((record, idx) => {
      const finalRecord: any = {};

      for (const col of columns) {
        const propName = (col.dataIndex as any) || (col.key as any);
        if (propName) {
          const text = (record as any)[propName];
          if (col.key && col.key === 'addedBy' && col.render) {
            finalRecord['addedBy'] = col.render(text, record, idx);
          } else if (propName == 'quarantined') {
            finalRecord['status'] = `"${status(record)}"`;
          } else if (col.render) {
            const renderedCol: any = col.render(text, record, idx);
            const type =
              renderedCol.type?.displayName ||
              renderedCol.type?.render?.displayName;

            if (type === 'CloseOutlined') {
              finalRecord[propName] = 'X';
            } else if (renderedCol?.props?.children) {
              finalRecord[propName] = `"${renderedCol?.props?.children}"`;
            } else {
              finalRecord[propName] = `"${renderedCol}"`;
            }
          } else {
            finalRecord[propName] = `"${text}"`;
          }
        }
      }

      return finalRecord;
    });
}

export const generateCSV = (items: any[]) => {
  if (!items?.length) return;

  const colPrettyNames = {
    addedBy: 'Added By',
    addedByUser: 'Checked-in With',
    covidTestDate: 'Test Date',
    covidTestResultDate: 'Result Date',
    createdAt: 'Time',
    displayName: 'Name',
    monitoredRemaining: 'Days Remaining',
    monitoredEndAt: 'End of Isolation',
    quarantineRemaining: 'Days Remaining',
    quarantineEndAt: 'End of Quarantine',
    studentId: 'ID',
    user: 'ID',
  } as any;

  const columnNames = Object.keys(items[0]).map((col: string) => {
    if (colPrettyNames[col]) {
      return colPrettyNames[col];
    }

    return col.substr(0, 1).toUpperCase() + col.substr(1);
  });

  const csvString = [columnNames, ...items.map((item) => Object.values(item))]
    .map((e) => e.join(','))
    .join('\n');

  const encodedUri =
    'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvString);
  let link = document.createElement('a');
  link.href = encodedUri;
  link.download = 'Screener19-export.csv';
  link.click();

  //   window.open(encodedUri);
  return encodedUri;
};

function isBool(x: any): x is boolean {
  return typeof x === 'boolean';
}

const status = (record: any): string => {
  if (
    !record?.quarantined &&
    !record?.monitored &&
    !record?.needsReview &&
    !record.respondedToday
  ) {
    return 'Unknown';
  }

  if (
    !record?.quarantined &&
    !record?.monitored &&
    !record?.needsReview &&
    record.respondedToday
  ) {
    return 'Healthy';
  }
  if (record?.quarantined) {
    return 'Quarantined';
  }
  if (record?.monitored) {
    return 'Isolated';
  }
  if (record?.needsReview) {
    return 'Pending';
  }

  return 'Unknown';
};
