import './styles.css';

import {
  AreaChartOutlined,
  CheckCircleOutlined,
  CheckSquareFilled,
  GoldOutlined,
  HomeOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import React from 'react';

interface Props {
  canViewAdmin?: boolean;
  canViewSettings?: boolean;
  currentMenuKey?: string;
}

const DashboardMenu = ({
  canViewAdmin,
  canViewSettings,
  currentMenuKey = '',
}: Props) => {
  const renderAdminMenuItem = () => {
    if (canViewAdmin) {
      let items = [
        <Menu.Divider key="divider-1" />,
        <Menu.Item key="/admin/districts" icon={<GoldOutlined />}>
          <Link to="/admin/districts">Districts</Link>
        </Menu.Item>,
        <Menu.Item key="/admin/schools" icon={<HomeOutlined />}>
          <Link to="/admin/schools">Schools</Link>
        </Menu.Item>,
        <Menu.Item key="/admin/users" icon={<TeamOutlined />}>
          <Link to="/admin/users">All Users</Link>
        </Menu.Item>,
      ];
      return items;
    }
  };

  const renderSettingsMenuItem = () => {
    if (canViewAdmin || canViewSettings) {
      let items = [
        <Menu.Divider key="divider-settings" />,
        <Menu.Item key="/district/settings" icon={<SettingOutlined />}>
          <Link to="/district/settings">District Settings</Link>
        </Menu.Item>,
      ];
      return items;
    }
  };

  const menu = (
    <Menu
      theme="light"
      selectedKeys={[currentMenuKey]}
      defaultOpenKeys={['dash', 'users']}
      mode="inline"
    >
      <Menu.Item key="/" icon={<CheckCircleOutlined />}>
        <Link to="/">Screener</Link>
      </Menu.Item>
      <Menu.Item key="/checkin" icon={<CheckSquareFilled />}>
        <Link to="/checkin">Check-In</Link>
      </Menu.Item>
      <Menu.Divider key="divider-0" />
      <Menu.SubMenu key="dash" icon={<AreaChartOutlined />} title="Dashboard">
        <Menu.Item key="/dashboard">
          <Link to="/dashboard">Students</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/staff">
          <Link to="/dashboard/staff">Staff</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key="users" icon={<UserOutlined />} title="Rosters">
        <Menu.Item key="/dashboard/users/students">
          <Link to="/dashboard/users/students">Students</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/users/staff">
          <Link to="/dashboard/users/staff">Staff</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="/dashboard/reports" icon={<UnorderedListOutlined />}>
        <Link to="/dashboard/reports/checked-in">Reports</Link>
      </Menu.Item>
      {renderSettingsMenuItem()}
      {renderAdminMenuItem()}
    </Menu>
  );

  return menu;
};

export default DashboardMenu;
