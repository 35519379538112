import './styles.less';

import { Note, NoteType, User } from 'screener19-core';
import {
  Button,
  Card,
  Collapse,
  DatePicker,
  Select,
  Skeleton,
  Table,
} from 'antd';
import {
  FileAddOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

const { Meta } = Card;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props {
  loading: boolean;
  onAddNote: () => void;
  onClickNote: (note: Note) => void;
  user?: User;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const UserNotes = ({ loading, onAddNote, onClickNote, user }: Props) => {
  let { id } = useParams<any>();

  const notes =
    user && !!user.notes?.length
      ? user.notes?.filter(
          (note: Note) =>
            note.type === NoteType.incident || note.type === NoteType.test
        )
      : [];

  const addNote = () => {
    if (onAddNote) {
      onAddNote();
    }
  };

  let actions = [
    <>
      <FileAddOutlined key="plus" twoToneColor="#52c41a" onClick={addNote} />
      <small>
        <a onClick={addNote}>Add Incident Form</a>
      </small>
    </>,
  ];

  const columns = [
    {
      title: 'Form ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (text: string, record: Note) => {
        if (record.type === NoteType.incident) {
          return <strong>Form #{record.id}</strong>;
        }

        return <strong>Test Result #{record.id}</strong>;
      },
    },
    {
      title: '',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: Note) => {
        // const icon = renderIconType(record);
        const date = format(new Date(record.createdAt), 'LLL do h:mm a');

        return <strong>{date}</strong>;
      },
      width: 190,
    },
    // {
    //   title: 'Added By',
    //   dataIndex: 'addedByUser',
    //   key: 'text',
    //   render: (text: string, record: Note) => {
    //     if (record.addedByUser && record.addedByUser._id !== record.user._id) {
    //       return [<small>{record.addedByUser.displayName}</small>];
    //     }

    //     return text;
    //   },
    // },
  ];

  return (
    <Card className="userprofile__card">
      <Skeleton loading={loading} paragraph={{ rows: 1 }}>
        <div className="userprofile__history-header">
          <Meta title={<p>Incident Forms & Test Results</p>} description={''} />
          <Button
            onClick={() => {
              if (onAddNote) {
                onAddNote();
              }
            }}
          >
            <PlusOutlined />
          </Button>
        </div>
        {notes && (
          <Table
            size="small"
            loading={loading}
            pagination={{ pageSize: 5, hideOnSinglePage: true }}
            showHeader={false}
            dataSource={notes}
            columns={columns}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (onClickNote) {
                    onClickNote(record);
                  }
                },
              };
            }}
          />
        )}
      </Skeleton>
    </Card>
  );
};

export default UserNotes;
