import React from 'react';
import './styles.css';
import HeaderText from 'components/HeaderText';

interface StepHeaderProps {
  description?: string;
  label?: string;
}

const StepHeader = ({ description, label }: StepHeaderProps) => {
  return (
    <div className="step-header">
      <HeaderText>{label}</HeaderText>
      <div className="step-description">{description}</div>
    </div>
  );
};

export default StepHeader;
