import React, { useEffect } from 'react';

import { Redirect } from 'react-router-dom';
import { User } from 'screener19-core';

interface Props {
  logout: () => void;
  user: User;
}

const LoggedOut = ({ logout, user }: Props) => {
  useEffect(() => {
    if (logout) {
      logout();
    }
  }, [user]);

  if (!user) {
    return <Redirect to="/" push={true} />;
  }

  return <div />;
};

export default LoggedOut;
