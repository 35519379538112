import { History, User } from 'screener19-core';

import React from 'react';
import UserHistory from 'components/UserProfile/UserHistory';
import useAsyncEffect from 'use-async-effect';
import { useParams } from 'react-router-dom';

interface Props {
  history?: History[];
  loading?: boolean;
  loggedInUser: User;
  loadData: (id?: string) => void;
  showNames: boolean;
  user?: User;
}

const AccountHistory = ({
  history,
  loading = false,
  loadData,
  loggedInUser,
  showNames,
  user,
}: Props) => {
  let { id } = useParams<any>();

  useAsyncEffect(async () => {
    await loadData(id);
  }, []);

  return (
    <UserHistory
      userHistory={history}
      userId={user?._id}
      loading={loading}
      loggedInUser={loggedInUser}
      onAddNote={() => {
        console.log('add note');
      }}
      showNames={showNames}
      showUserName={true}
      title={`${user?.displayName} - History`}
    />
  );
};

export default AccountHistory;
