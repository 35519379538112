import './styles.css';

import { School } from 'screener19-core';
import { Popconfirm, Table } from 'antd';
import React, { useEffect } from 'react';

interface Props {
  data: Array<School>;
  onClickEdit?: (school: School) => void;
  onClickDelete?: (id: string) => void;
  onClickRow?: (id: string) => void;
}

const SchoolsTable = ({
  data,
  onClickEdit,
  onClickDelete,
  onClickRow,
}: Props) => {
  useEffect(() => {}, []);

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a: any, b: any) => parseInt(a.code) - parseInt(b.code),
    },
    {
      title: 'Number of Students',
      dataIndex: 'numberOfUsers',
      key: 'numberOfUsers',
      sorter: (a: any, b: any) =>
        parseInt(a.numberOfUsers) - parseInt(b.numberOfUsers),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: (a: any, b: any) => a.address.localeCompare(b.address),
    },
    {
      title: 'City',
      dataIndex: 'addressCity',
      key: 'addressCity',
      sorter: (a: any, b: any) => a.addressCity.localeCompare(b.addressCity),
    },
    {
      title: 'State',
      dataIndex: 'addressState',
      key: 'addressState',
      sorter: (a: any, b: any) => a.addressState.localeCompare(b.addressState),
    },
    {
      title: 'Zip',
      dataIndex: 'addressZip',
      key: 'addressZip',
      sorter: (a: any, b: any) => a.addressZip.localeCompare(b.addressZip),
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      render: (text: any, rec: School) =>
        onClickEdit && (
          <a onClick={() => onClickEdit && onClickEdit(rec)}>Edit</a>
        ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'delete',
      render: (text: any, rec: School) =>
        onClickDelete && (
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onClickDelete && onClickDelete(rec._id)}
          >
            <a>Delete</a>
          </Popconfirm>
        ),
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowClassName="userslist_row"
      pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            console.log(record._id);
            if (onClickRow) {
              onClickRow(record._id);
            }
          },
        };
      }}
    />
  );
};

export default SchoolsTable;
