import './styles.css';

import { Answer } from 'screener19-core';
import Button from 'components/Button';
import List from 'components/List';
import React from 'react';

interface StepTrueFalseProps {
  items: Answer[];
  multiselect?: boolean;
  onPressNext?: (index: number, item: Answer) => void;
  onChange?: (items: Answer[]) => void;
  stepIndex: number;
  noneIndex?: number;
  userIsQuarantinedOrIsolated?: boolean;
}

const StepTrueFalse = ({
  multiselect,
  noneIndex,
  items,
  onPressNext,
  onChange,
  stepIndex,
  userIsQuarantinedOrIsolated,
}: StepTrueFalseProps) => {
  const onPress = (index: number) => {
    const _items = items.map((item: any, i: number) => {
      if (multiselect) {
        if (index === noneIndex) {
          if (i === index) {
            return { ...item, selected: !item.selected };
          }
          // unset all others
          return { ...item, selected: false };
        } else {
          if (i === index) {
            return { ...item, selected: !item.selected };
          }
          // unset "none"
          if (i === noneIndex) {
            return { ...item, selected: false };
          }
          return { ...item };
        }
      } else {
        if (i === index) {
          return { ...item, selected: !item.selected };
        }
        return { ...item, selected: false };
      }
    });

    if (onChange) {
      onChange(_items);
    }
  };

  const nextButton = () => {
    let choice: any;
    for (let _choice of items) {
      if (_choice.selected) {
        choice = _choice;
        break;
      }
    }

    return (
      <div>
        <Button
          disabled={!choice}
          label="Next"
          onPress={() => (onPressNext ? onPressNext(stepIndex, choice) : null)}
        />
      </div>
    );
  };

  return (
    <div>
      <List
        items={items}
        multiselect={multiselect}
        onPressItem={onPress}
        userIsQuarantinedOrIsolated={userIsQuarantinedOrIsolated}
      />
      {nextButton()}
    </div>
  );
};

export default StepTrueFalse;
