import './styles.css';

import { User, UsersRostersProps } from 'screener19-core';

import React from 'react';
import UsersWithNavMenu from 'components/UsersWithNavMenu';
import compareDesc from 'date-fns/compareDesc';
import format from 'date-fns/format';

const UsersIsolated = (props: UsersRostersProps) => {
  return (
    <UsersWithNavMenu
      {...props}
      extraColumns={[
        {
          title: 'Days Remaining',
          dataIndex: 'monitoredRemaining',
          key: 'monitoredRemaining',
          defaultSortOrder: 'descend',
          sorter: (a: any, b: any) =>
            compareDesc(new Date(a.monitoredEndAt), new Date(b.monitoredEndAt)),
        },
        {
          title: 'End of Isolation',
          dataIndex: 'monitoredEndAt',
          key: 'monitoredEndAt',
          render: (text: string, rec: any) => {
            if (!text) return '';
            return format(new Date(text), 'LLL do');
          },
          sorter: (a: User, b: User) =>
            compareDesc(new Date(a.monitoredEndAt), new Date(b.monitoredEndAt)),
        },
      ]}
    />
  );
};

export default UsersIsolated;
