import './styles.css';

import { Result, Typography } from 'antd';
import { Answer, Question, Response, User } from 'screener19-core';

import Barcode from 'react-barcode';
import CustomResults from 'components/CustomResults';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { format } from 'date-fns';

const { Paragraph, Text } = Typography;

interface ResultsProps {
  onPressDone?: () => void;
  response: Response;
  user: User;
}

const Results = ({ onPressDone, response, user }: ResultsProps) => {
  if (!response) {
    return <Redirect to="/screener/" />;
  }

  const todaysDay = format(new Date(), 'EEEE');
  const cohorts = user?.district?.cohorts;
  const expectedCohorts = cohorts
    ? cohorts?.find((c) => c.day === todaysDay)?.cohorts
    : [];

  const renderCohort = () => {
    let correctCohort = false;
    if (user?.cohort) {
      const studentInTodaysCohort = expectedCohorts?.find(
        (cohort: string) => cohort === user.cohort
      );
      if (studentInTodaysCohort) {
        correctCohort = true;
      }
    }

    if (user?.cohort && !correctCohort) {
      return (
        <div className="results__cohort-warning">
          <h2>{`Cohort ${user.cohort}`}</h2>
        </div>
      );
    }

    if (user?.cohort) {
      return <h2>Cohort {user?.cohort}</h2>;
    }
  };

  const renderResult = () => {
    if (user && (user.quarantined || user.monitored || user.needsReview)) {
      // In quarantine or isolation
      const end =
        user.quarantined && user.quarantineEndAt
          ? format(new Date(user.quarantineEndAt), 'LLL do')
          : user.monitored && user.monitoredEndAt
          ? format(new Date(user.monitoredEndAt), 'LLL do')
          : '';
      let msg = user.quarantined
        ? `Quarantine through ${end}.`
        : `Isolation through ${end}.`;
      if (user.needsReview) {
        msg = '';
      }
      return (
        <div>
          <Result
            status="error"
            title={
              <div>
                <h2 className="results__name">{user?.displayName}</h2>
                {renderCohort()}
                <h2 className="results__date">
                  {format(new Date(response.date), 'LLL do, yyyy')}
                </h2>
                {msg} {msg && <br />}{' '}
                <span>
                  Please do not come to campus until you have been cleared by a
                  school nurse.
                </span>
                <div>
                  <small>
                    Reminder: Please complete your screener every day even if
                    you are not planning to come to school
                    <br />
                    If you think you should be cleared to come to school then
                    please contact your school office.
                  </small>
                </div>
              </div>
            }
            subTitle=""
          ></Result>
          <CustomResults district={user?.district} />
        </div>
      );
    }

    const renderAnswer = (a: Answer, i: number) => {
      return (
        <span
          key={`question-${i}`}
          dangerouslySetInnerHTML={{
            __html: a?.responseText,
          }}
        />
      );
    };

    // "healthy"
    return (
      <Result
        status="success"
        title={
          <div>
            <h2 className="results__name">{user?.displayName}</h2>
            {user?.studentId && <Barcode height={30} value={user?.studentId} />}
            {renderCohort()}
            {response.questions?.map((q: Question, i: number) => {
              if (q.answered) {
                const answers = q.answers.filter((a: Answer) => a.selected);
                if (answers?.length) {
                  // render all the answers
                  if (q.showAnswersOnExit) {
                    return (
                      <h2 className="results__name">
                        {answers
                          .map<React.ReactNode>((a) => {
                            return renderAnswer(a, i);
                          })
                          .reduce((prev, curr) => [prev, ',\u00A0', curr])}
                      </h2>
                    );
                  }
                }
              }
            })}
            <h2 className="results__date">
              {format(new Date(response.date), 'LLL do, yyyy')}
            </h2>
            <div></div>
            You are safe to go to school. <br />
            You may proceed to campus if you are planning to.
          </div>
        }
        subTitle=""
      ></Result>
    );
  };

  return (
    <div className="results">
      <div className="results__header">
        <div className="max-size-container">
          {renderResult()}
          <div
            className="results__start-over"
            onClick={() => {
              if (!!process.env.REACT_APP_DEMO_SITE && onPressDone) {
                onPressDone();
              }
            }}
          >
            Do you have questions? Contact your school office for any follow up.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
