import './styles.css';

import { Answer, Question, User, UsersRostersProps } from 'screener19-core';

import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import UsersWithNavMenu from 'components/UsersWithNavMenu';
import compareDesc from 'date-fns/compareDesc';
import format from 'date-fns/format';

const UsersPending = (props: UsersRostersProps) => {
  const { questions, canViewResponses } = props;
  const getAnswerFromQuestionsForKey = (
    questions: Array<Question>,
    key: string
  ) => {
    const question = questions.find(
      (ques: Question) => ques.key.toString() === key
    );

    if (question) {
      const answers = question.answers.filter((a: Answer) => a.selected);
      if (answers) {
        const positiveAnswer = answers.find((a: any) => !!a.pendingText);
        return positiveAnswer;
      }
    }
  };

  const extraPendingColumns =
    !canViewResponses || !questions
      ? []
      : questions.map((q: Question, index: number) => {
          return {
            title: q.shortDescription,
            dataIndex: q.key,
            key: q.key,
            width: 50,
            defaultSortOrder: 'ascend',
            className: 'userlist__col-center',
            sorter: {
              compare: (userA: User, userB: User) => {
                if (
                  !userA.movedToPendingResponse ||
                  !userB.movedToPendingResponse
                ) {
                  return 0;
                }

                const answerA = getAnswerFromQuestionsForKey(
                  userA.movedToPendingResponse.questions,
                  q.key
                );
                const answerB = getAnswerFromQuestionsForKey(
                  userB.movedToPendingResponse.questions,
                  q.key
                );

                let answerApositive = !!answerA?.pendingText;
                let answerBpositive = !!answerB?.pendingText;

                return answerApositive === answerBpositive
                  ? 0
                  : answerApositive
                  ? -1
                  : 1;
              },
              multiple: index,
            },
            render: (text: string, user: User, index: any) => {
              if (user.movedToPendingResponse) {
                const a = getAnswerFromQuestionsForKey(
                  user.movedToPendingResponse.questions,
                  q.key
                );
                if (a && (a.warn || a.quarantine)) {
                  return <CloseOutlined twoToneColor="red" />;
                }
                if (a && !!a.pendingText) {
                  return <CloseOutlined twoToneColor="red" />;
                }
                if (a && !a.pendingText) {
                  return '';
                }
              }

              return '';
            },
          };
        });

  return (
    <UsersWithNavMenu
      {...props}
      extraColumns={[
        {
          title: 'Pending Date',
          dataIndex: 'needsReviewAt',
          key: 'needsReviewAt',
          width: 150,
          render: (text: string, rec: any) => {
            if (!text) return '';
            return format(new Date(text), 'LLL do');
          },
          sorter: (a: any, b: any) =>
            compareDesc(new Date(a.needsReviewAt), new Date(b.needsReviewAt)),
        },
        ...extraPendingColumns,
      ]}
    />
  );
};

export default UsersPending;
