import './styles.css';

import { Class } from 'screener19-core';
import { Link } from 'react-router-dom';
import React from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';

interface Props {
  loading: boolean;
  classes?: Class[];
}

const UserClassList = ({ loading, classes }: Props) => {
  const history = useHistory();
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text: string, record: Class, dataIndex: number) => {
        return (
          <>
            <Link to={`/account/class/${record._id}`}>
              {record?.course?.title}
            </Link>
            {record?.course?.description && (
              <div>{record?.course?.description}</div>
            )}
          </>
        );
      },
      // defaultSortOrder: 'ascend',
      sorter: (a: Class, b: Class) =>
        a.course.title.localeCompare(b.course.title),
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      render: (text: string, record: Class) => {
        return text;
      },
      defaultSortOrder: 'ascend',
      sorter: (a: Class, b: Class) =>
        parseInt(a.period || '0') - parseInt(b.period || '0'),
    },
    {
      title: 'Section',
      dataIndex: 'sectionNumber',
      key: 'sectionNumber',
      render: (text: string, record: Class) => {
        return text;
      },
      sorter: (a: Class, b: Class) =>
        parseInt(a.sectionNumber) - parseInt(b.sectionNumber),
    },
  ] as any;

  return (
    <div className="userprofile__history">
      <div className="userprofile__history-header">
        <h3>Classes</h3>
      </div>
      <Table
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
          showSizeChanger: true,
        }}
        dataSource={classes}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              history.push(`/account/class/${record._id}`);
            },
          };
        }}
      />
    </div>
  );
};

export default UserClassList;
