import './styles.css';

import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import React from 'react';

interface Props {
  id?: string;
  selectedKeys: string[];
}

const DistrictsNavMenu = ({ id, selectedKeys }: Props) => {
  return (
    <Menu
      theme="light"
      selectedKeys={selectedKeys}
      mode="horizontal"
      className="userlist__menu"
    >
      <Menu.Item key={`/admin/districts/${id}`}>
        <Link to={`/admin/districts/${id}`}>Overview</Link>
      </Menu.Item>
      <Menu.Item key={`/admin/districts/${id}/logs`}>
        <Link to={`/admin/districts/${id}/logs`}>Logs</Link>
      </Menu.Item>
      <Menu.Item key={`/admin/districts/${id}/settings`}>
        <Link to={`/admin/districts/${id}/settings`}>Settings</Link>
      </Menu.Item>
      <Menu.Item key={`/admin/districts/${id}/cohorts`}>
        <Link to={`/admin/districts/${id}/cohorts`}>Cohorts</Link>
      </Menu.Item>
      <Menu.Item key={`/admin/districts/${id}/screener`}>
        <Link to={`/admin/districts/${id}/screener`}>Screener Questions</Link>
      </Menu.Item>
    </Menu>
  );
};

export default DistrictsNavMenu;
