import './styles.css';

import { UsersRostersProps } from 'screener19-core';

import React from 'react';
import UsersWithNavMenu from 'components/UsersWithNavMenu';
import compareDesc from 'date-fns/compareDesc';
import format from 'date-fns/format';

const UsersQuarantined = (props: UsersRostersProps) => {
  return (
    <UsersWithNavMenu
      {...props}
      extraColumns={[
        {
          title: 'Days Remaining',
          dataIndex: 'quarantineRemaining',
          defaultSortOrder: 'descend',
          key: 'quarantineRemaining',
          sorter: (a: any, b: any) =>
            compareDesc(
              new Date(a.quarantineEndAt),
              new Date(b.quarantineEndAt)
            ),
        },
        {
          title: 'End of Quarantine',
          dataIndex: 'quarantineEndAt',
          key: 'quarantineEndAt',
          render: (text: string, rec: any) => {
            if (!text) return '';
            return format(new Date(text), 'LLL do');
          },
          sorter: (a: any, b: any) =>
            compareDesc(
              new Date(a.quarantineEndAt),
              new Date(b.quarantineEndAt)
            ),
        },
      ]}
    />
  );
};

export default UsersQuarantined;
