import './styles.css';

import { Question } from 'screener19-core';
import { Steps } from 'antd';

const { Step } = Steps;

interface Props {
  currentStep: number;
  questions: Array<Question>;
  onChangeStep?: (step: number) => void;
}

const ScreenerSteps = ({ currentStep, onChangeStep, questions }: Props) => {
  return (
    <Steps
      direction="horizontal"
      labelPlacement="vertical"
      size="small"
      className="screener__steps"
      current={currentStep}
      onChange={onChangeStep}
    >
      {questions.map((q: Question, index: number) => {
        return <Step disabled={onChangeStep && index > currentStep} />;
      })}
    </Steps>
  );
};

export default ScreenerSteps;
