import './styles.less';

import React from 'react';

interface ButtonProps {
  disabled?: boolean;
  onPress?: () => void;
  label?: string;
}

const Button = ({ disabled = false, label, onPress }: ButtonProps) => {
  const cls = !disabled ? 'button' : 'button button-disabled';
  return (
    <div
      className={cls}
      onClick={() => (onPress && !disabled ? onPress() : null)}
    >
      {label}
    </div>
  );
};

export default Button;
