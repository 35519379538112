import './styles.css';

import { District, School, User } from 'screener19-core';
import { Layout, message } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import AdminApi from 'screener19-core/dist/api/admin';
import AdminClass from 'components/AdminClass';
import AdminDistricts from 'components/AdminDistricts';
import AdminSchoolClasses from 'components/AdminSchoolClasses';
import AdminSchoolProfile from 'components/AdminSchoolProfile';
import AdminSchools from 'components/AdminSchools';
import AdminUsers from 'components/AdminUsers';
import AuthRoute from 'components/AuthRoute';
import { Class } from 'screener19-core/dist/interfaces';
import ClassesApi from 'screener19-core/dist/api/class';
import DashboardMenu from 'components/DashboardMenu';
import DistrictsApi from 'screener19-core/dist/api/districts';
import Fuse from 'fuse.js';
import SchoolsApi from 'screener19-core/dist/api/schools';
import UsersApi from 'screener19-core/dist/api/users';
import axiosInstance from 'util/api-util';
import { connect } from 'react-redux';

const { Header, Content, Footer, Sider } = Layout;

const Admin = (props: any) => {
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cls, setClass] = useState<Class | undefined>();
  const [districts, setDistricts] = useState<District[]>([]);
  const [classes, setClasses] = useState<Class[]>();
  const [school, setSchool] = useState<School>();
  const [schools, setSchools] = useState<School[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [students, setStudents] = useState<User[]>([]);
  const [searchResults, setSearchResults] = useState<Array<any> | undefined>();

  let location = useLocation();
  const classesApi = new ClassesApi(axiosInstance);
  const usersApi = new UsersApi(axiosInstance);
  const districtApi = new DistrictsApi(axiosInstance);
  const schoolsApi = new SchoolsApi(axiosInstance);
  const adminApi = new AdminApi(axiosInstance);

  message.config({
    duration: 2,
    maxCount: 1,
  });

  const options = {
    includeScore: true,
    // Search in `author` and in `tags` array
    keys: ['displayName', '_id', 'email', 'schoolNames'],
    threshold: 0.25,
  };

  const fuse = new Fuse(users, options);

  const canViewAdmin = () => {
    return (
      !!props.user &&
      !!props.user.permissions &&
      !!props.user.permissions.canViewAdmin
    );
  };

  const createResponsesForSchool = async (
    schoolId: string,
    date?: Date,
    maxNumberOfResponses?: number
  ) => {
    await adminApi.createRandomResponsesForSchool(
      schoolId,
      date,
      maxNumberOfResponses
    );
  };

  const fetchDistricts = async () => {
    setLoading(true);
    try {
      const _districts = await districtApi.getAll();
      setDistricts(_districts);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const _users = await usersApi.getAllUsers();
      setUsers(_users);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchClass = async (classId: string) => {
    setLoading(true);
    try {
      const _class = await classesApi.getClass(classId);
      setClass(_class);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchClassesForSchool = async (id: string) => {
    setLoading(true);
    try {
      await fetchSchool(id);
      const _classes = await schoolsApi.getClasses(id);
      setClasses(_classes);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchSchools = async () => {
    setLoading(true);
    try {
      const _schools = await schoolsApi.getAll();
      setSchools(_schools);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchSchool = async (id: string) => {
    setLoading(true);
    setSchool(undefined);
    try {
      const _school = await schoolsApi.getOne(id);
      const _users = await schoolsApi.getUsers(id);
      _school.users = _users;
      setSchool(_school);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCreateDistrict = async (values: any) => {
    const district = await districtApi.add(values);
    setDistricts([district]);
  };

  const onCreateSchool = async (values: any) => {
    const school = await schoolsApi.add(values);
    fetchSchools();
  };

  const onDeleteSchool = async (id: string) => {
    const school = await schoolsApi.destroy(id);
    fetchSchools();
  };

  const onImportStudents = async (school: School) => {
    const schools = await districtApi.importStudents(
      school.district,
      school.code
    );
    fetchSchool(school._id);
  };

  const onUpdateSchool = async (id: string, values: any) => {
    const school = await schoolsApi.update(id, values);
    fetchSchools();
  };

  const onSearchUsers = async (query: string) => {
    if (query === '') {
      setSearchResults(undefined);
      return;
    }

    const result = fuse.search(query);
    setSearchResults(result.map((i: any) => i.item));
  };

  const onCreateUser = async (school: School, data: any) => {
    console.log('school', school);
    console.log('data', data);

    const userData = {
      ...data,
      displayName: `${data.firstName} ${data.lastName}`,
      district: school.district,
      school: school._id,
      role: 'user',
    };

    await usersApi.createUser(userData);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(c) => setCollapsed(c)}
        theme="light"
      >
        <div className="logo" />
        <DashboardMenu
          canViewAdmin={canViewAdmin()}
          canViewSettings={props.user?.isTechAdmin}
          currentMenuKey={location.pathname}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="dashboard__header dashboard__header-admin">
          <div>
            {props.user.displayName} | <a onClick={props.onLogout}>Logout</a>
          </div>
        </Header>
        <Content className="content__container">
          <AuthRoute exact auth={canViewAdmin()} path="/admin/districts">
            <AdminDistricts
              loading={loading}
              loadData={fetchDistricts}
              districts={districts}
              onFinish={onCreateDistrict}
            />
          </AuthRoute>
          <AuthRoute exact auth={canViewAdmin()} path="/admin/users">
            <AdminUsers
              loading={loading}
              loadData={fetchUsers}
              users={searchResults || users}
              onSearch={onSearchUsers}
            />
          </AuthRoute>
          <AuthRoute exact auth={canViewAdmin()} path="/admin/schools">
            <AdminSchools
              districts={districts}
              loading={loading}
              loadData={fetchSchools}
              loadDistricts={fetchDistricts}
              data={schools}
              onDelete={onDeleteSchool}
              onFinish={onCreateSchool}
              onUpdate={onUpdateSchool}
            />
          </AuthRoute>
          <AuthRoute exact auth={canViewAdmin()} path="/admin/schools/:id">
            <AdminSchoolProfile
              school={school}
              loading={loading}
              loadData={fetchSchool}
              onCreateUser={onCreateUser}
              onCreateResponses={createResponsesForSchool}
              onImportStudents={onImportStudents}
            />
          </AuthRoute>
          <AuthRoute
            exact
            auth={canViewAdmin()}
            path="/admin/schools/:id/classes"
          >
            <AdminSchoolClasses
              classes={classes}
              school={school}
              loading={loading}
              loadClasses={fetchClassesForSchool}
            />
          </AuthRoute>
          <AuthRoute
            exact
            auth={canViewAdmin()}
            path="/admin/schools/:id/classes/:classId"
          >
            <AdminClass cls={cls} loading={loading} loadData={fetchClass} />
          </AuthRoute>
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
