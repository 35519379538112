import './styles.less';

import {
  Class,
  District,
  Note,
  NoteType,
  User,
  UserRole,
} from 'screener19-core';
import { Form, Modal, message } from 'antd';
import React, { useState } from 'react';
import {
  showNoteSuccessMessage,
  showNoteUpdatedMessage,
} from 'util/user-profile-util';
import { useLocation, useParams } from 'react-router-dom';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import UserAddIncidentFormModal from './UserAddIncidentFormModal';
import UserAddNoteModal from './UserAddNoteModal';
import UserAddTestFormModal from './UserAddTestFormModal';
import UserCard from './UserCard';
import UserChooseIncidentFormTypeModal from './UserChooseIncidentFormTypeModal';
import UserClassList from 'components/UserClassList';
import UserEditModal from './UserEditModal';
import UserFormModal from './UserFormModal';
import UserHistory from './UserHistory';
import UserNotes from './UserNotes';
import UsersApi from 'screener19-core/dist/api/users';
import axiosInstance from 'util/api-util';
import useAsyncEffect from 'use-async-effect';

interface Props {
  classes?: Class[];
  clearUser?: (id: string, monitored: Boolean, quarantined: Boolean) => void;
  changeHomeSchool: (schoolId: string) => void;
  districts?: Array<District>;
  fetchUserById?: (id: string) => void;
  loggedInUser: User;
  deleteUser?: (id: string) => void;
  monitorUser?: (id: string, start: Date, end: Date) => void;
  quarantineUser?: (id: string, start: Date, end: Date) => void;
  rosters?: any;
  stats?: any;
  staffStats?: any;
  updateUser?: (
    id: string,
    district: string,
    role: string,
    schools: Array<string>,
    isTechAdmin: boolean
  ) => void;
  showNames?: boolean;
  showQrCode?: boolean;
  user?: User;
}

const UserProfile = ({
  changeHomeSchool,
  classes,
  deleteUser,
  loggedInUser,
  user,
  clearUser,
  districts,
  fetchUserById,
  monitorUser,
  quarantineUser,
  rosters,
  stats,
  staffStats,
  updateUser,
  showNames = false,
  showQrCode = true,
}: Props) => {
  let { id, bucket } = useParams<any>();
  let location = useLocation();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState<Note | undefined>();
  const [showAddNoteForm, setShowAddNoteForm] = useState(false);
  const [showAddIncidentForm, setShowAddIncidentForm] = useState(false);
  const [showAddTestForm, setShowAddTestForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showHealthyForm, setShowHealthyForm] = useState(false);
  const [showMonitorForm, setShowMonitorForm] = useState(false);
  const [
    showNewIncidentOrTestResultForm,
    setShowNewIncidentOrTestResultForm,
  ] = useState(false);
  const [showQuarantineForm, setShowQuarantineForm] = useState(false);

  const clearForms = () => {
    setShowAddIncidentForm(false);
    setShowAddNoteForm(false);
    setShowAddTestForm(false);
    setNoteToEdit(undefined);
  };

  const onClearUser = async (
    id: string,
    monitored: Boolean,
    quarantined: Boolean
  ) => {
    if (clearUser && !!id) {
      setShowMonitorForm(false);
      setShowQuarantineForm(false);
      await clearUser(id, monitored, quarantined);
    }
  };

  const onAddNote = async (values: any) => {
    try {
      const api = new UsersApi(axiosInstance);
      await api.addNote(id, values);
      if (fetchUserById) {
        await fetchUserById(id);
      }
      showNoteSuccessMessage(values.type);
    } catch (error) {
      message.error(error.response?.data?.data || error.message, 5);
    } finally {
      clearForms();
    }
  };

  const onUpdateNote = async (noteId: string, values: any) => {
    try {
      const api = new UsersApi(axiosInstance);
      await api.updateNote(id, noteId, values);
      if (fetchUserById) {
        await fetchUserById(id);
      }
      showNoteUpdatedMessage(values.type);
    } catch (error) {
      message.error(error.response?.data?.data || error.message, 5);
    } finally {
      clearForms();
    }
  };

  const onDeleteNote = async (noteId: string) => {
    try {
      const api = new UsersApi(axiosInstance);
      await api.deleteNote(id, noteId);
      if (fetchUserById) {
        await fetchUserById(id);
      }

      message.success('Incident form deleted');
    } catch (error) {
      message.error(error.message);
    } finally {
      clearForms();
    }
  };

  const onEditUser = async (values: any) => {
    console.log('onEditUser', values);

    setShowEditForm(false);
    if (updateUser) {
      await updateUser(
        id,
        values.district,
        values.role,
        values.schools,
        values.isTechAdmin
      );
    }
  };

  const onPressHealthy = async (show: boolean) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to mark this user as healthy?',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: async () => await onClearUser(id, false, false),
    });
  };

  const onStartQuarantine = async (values: any) => {
    if (quarantineUser && !!id) {
      try {
        await quarantineUser(id, values.range[0], values.range[1]);
      } catch (error) {
        message.error(error.message);
      } finally {
        setShowQuarantineForm(false);
      }
    }
  };

  const onStartMonitor = async (values: any) => {
    if (monitorUser && !!id) {
      setShowMonitorForm(false);
      try {
        await monitorUser(id, values.range[0], values.range[1]);
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  useAsyncEffect(async () => {
    if (id) {
      setLoading(true);
      if (fetchUserById) {
        await fetchUserById(id);
      }
      setLoading(false);
    }
  }, [id]);

  // get rosters for next/previous buttons
  const isUserPath = location.pathname.match(
    /^\/dashboard\/(user|students)(\/?)/g
  );
  const isStudent = user?.role === UserRole.USER;
  const studentOrStaff = isStudent ? 'students' : 'staff';
  let rosterList =
    user &&
    rosters &&
    rosters[studentOrStaff] &&
    rosters[studentOrStaff][bucket]
      ? rosters[studentOrStaff][bucket]
      : [];

  if (bucket === 'quarantine-ending') {
    if (isStudent) {
      rosterList =
        user && stats?.quarantineEndingSoon ? stats.quarantineEndingSoon : [];
    } else {
      rosterList =
        user && staffStats?.quarantineEndingSoon
          ? staffStats.quarantineEndingSoon
          : [];
    }
  }

  if (bucket === 'isolation-ending') {
    if (isStudent) {
      rosterList =
        user && stats?.isolationEndingSoon ? stats.isolationEndingSoon : [];
    } else {
      rosterList =
        user && staffStats?.isolationEndingSoon
          ? staffStats.isolationEndingSoon
          : [];
    }
  }

  if (bucket === 'pending-dashboard' && user) {
    if (isStudent) {
      rosterList = user && stats?.pendingUsers ? stats.pendingUsers : [];
    } else {
      rosterList =
        user && staffStats?.pendingUsers ? staffStats.pendingUsers : [];
    }
  }

  return (
    <div className="userprofile__container">
      <div className="userprofile__cards">
        <UserCard
          userListBucket={bucket}
          userList={rosterList}
          canDeleteUsers={loggedInUser.permissions?.canDeleteUsers}
          canUpdateRoles={loggedInUser.permissions?.canUpdateRoles}
          canUpdateStatuses={loggedInUser?.permissions?.canUpdateStatuses}
          isTechAdmin={loggedInUser.isTechAdmin}
          loading={loading}
          showHealthyForm={onPressHealthy}
          showEditForm={setShowEditForm}
          canChangeHomeSchool={
            loggedInUser._id === user?._id ||
            loggedInUser.permissions.canUpdateRoles
          }
          onChangeHomeSchool={changeHomeSchool}
          onPressDelete={deleteUser}
          showMonitorForm={setShowMonitorForm}
          showQuarantineForm={setShowQuarantineForm}
          showNames={showNames}
          showQrCode={showQrCode}
          user={user}
        />
        {loggedInUser?.permissions?.canViewResponses && (
          <UserNotes
            loading={loading}
            onAddNote={() => setShowNewIncidentOrTestResultForm(true)}
            onClickNote={(note: Note) => {
              setNoteToEdit(note);
              if (note.type === NoteType.incident) {
                setShowAddIncidentForm(true);
              }
              if (note.type === NoteType.test) {
                setShowAddTestForm(true);
              }
            }}
            user={user}
          />
        )}
      </div>
      {loggedInUser?.permissions?.canViewResponses && (
        <UserHistory
          loggedInUser={loggedInUser}
          loading={loading}
          userId={user?._id}
          userHistory={user?.history}
          onAddNote={(note) => {
            if (note) {
              setNoteToEdit(note);
              if (note.type === NoteType.note) {
                setShowAddNoteForm(true);
              }
              if (note.type === NoteType.test) {
                setShowAddTestForm(true);
              }
            } else {
              setShowAddNoteForm(true);
            }
          }}
        />
      )}
      {user?.role !== UserRole.USER && !!classes?.length && (
        <UserClassList loading={loading} classes={classes} />
      )}
      <UserFormModal
        clearButtonTitle={'End Quarantine'}
        start={user?.quarantineStartAt}
        end={user?.quarantineEndAt}
        onCancel={() => setShowQuarantineForm(false)}
        onFinish={onStartQuarantine}
        onPressClear={() => onClearUser(id, !!user && user.monitored, false)}
        label={'Quarantine Period'}
        title={'Quarantine'}
        visible={showQuarantineForm}
      />
      <UserFormModal
        clearButtonTitle={'End Isolation'}
        start={user?.monitoredStartAt}
        end={user?.monitoredEndAt}
        onCancel={() => setShowMonitorForm(false)}
        onFinish={onStartMonitor}
        onPressClear={() => onClearUser(id, false, !!user && user.quarantined)}
        label={'Isolation Period'}
        title={'Isolation'}
        visible={showMonitorForm}
      />
      <UserEditModal
        districts={districts}
        onCancel={() => setShowEditForm(false)}
        onFinish={onEditUser}
        visible={showEditForm}
        loggedInUser={loggedInUser}
        user={user}
      />
      <UserChooseIncidentFormTypeModal
        onCancel={() => setShowNewIncidentOrTestResultForm(false)}
        visible={showNewIncidentOrTestResultForm}
        onPressAddIncident={() => {
          setShowNewIncidentOrTestResultForm(false);
          setShowAddIncidentForm(true);
        }}
        onPressAddTestResult={() => {
          setShowNewIncidentOrTestResultForm(false);
          setShowAddTestForm(true);
        }}
      />
      <UserAddNoteModal
        onCancel={() => {
          setShowAddNoteForm(false);
          setNoteToEdit(undefined);
        }}
        onFinish={onAddNote}
        onUpdate={onUpdateNote}
        visible={showAddNoteForm}
        note={noteToEdit}
        user={user}
      />
      <UserAddIncidentFormModal
        onCancel={() => {
          setShowAddIncidentForm(false);
          setNoteToEdit(undefined);
        }}
        onDelete={onDeleteNote}
        note={noteToEdit}
        onFinish={onAddNote}
        onUpdate={onUpdateNote}
        visible={showAddIncidentForm}
        user={user}
      />
      <UserAddTestFormModal
        onCancel={() => {
          setShowAddTestForm(false);
          setNoteToEdit(undefined);
        }}
        onDelete={onDeleteNote}
        note={noteToEdit}
        onFinish={onAddNote}
        onUpdate={onUpdateNote}
        visible={showAddTestForm}
        user={user}
      />
    </div>
  );
};

export default UserProfile;
