import './styles.css';

import { DatePicker, Select, Space } from 'antd';
import { Form, Spin } from 'antd';
import moment, { Moment } from 'moment';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import { User } from 'screener19-core';
import UsersList from 'components/UsersList';

const { Option } = Select;

interface Props {
  currentDate?: Moment;
  loading: boolean;
  includeStudents: boolean;
  includeStaff: boolean;
  onChangeDate: (date: Moment) => void;
  onChangeStaffFilter: (value: boolean) => void;
  onChangeStudentsFilter: (value: boolean) => void;
  onFetchUsers: () => void;
  onSearchUsers: (query: string) => Promise<void>;
  users: User[];
  searchResults?: User[];
  showNames: boolean;
}

const CheckedInReport = ({
  currentDate,
  loading,
  includeStaff,
  includeStudents,
  onChangeDate,
  onChangeStaffFilter,
  onChangeStudentsFilter,
  onFetchUsers,
  onSearchUsers,
  users,
  searchResults,
  showNames,
}: Props) => {
  return (
    <div className="dashboard__list">
      <Spin spinning={loading}>
        <div>
          <div className="checkinreport__header">
            <h3>Checked-in Report</h3>
            <small>
              Check-in report can be filtered by date and by students or staff
            </small>
          </div>
          <div>
            <Space>
              <Form.Item name="checkinDate">
                Date:
                <DatePicker
                  defaultValue={currentDate ? currentDate : moment()}
                  onChange={(date) => {
                    if (date) {
                      onChangeDate(date);
                    }
                  }}
                />
              </Form.Item>
              {false && (
                <Form.Item name="staffMember">
                  <Select
                    showSearch
                    placeholder="Select staff member"
                    onChange={(user: string) => {
                      console.log(user);
                    }}
                  >
                    {[].map((item: any, index: number) => {
                      return <Option value={item._id}>{item.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
              )}
              <Space>
                <Form.Item name="userFilters">
                  <Checkbox
                    checked={includeStudents}
                    value="students"
                    onChange={(e) => {
                      onChangeStudentsFilter(e.target.checked);
                    }}
                  >
                    Students
                  </Checkbox>
                  <Checkbox
                    checked={includeStaff}
                    value="staff"
                    onChange={(e) => {
                      onChangeStaffFilter(e.target.checked);
                    }}
                  >
                    Staff
                  </Checkbox>
                </Form.Item>
              </Space>
            </Space>
          </div>
        </div>

        <UsersList
          fetchUsers={onFetchUsers}
          onSearchUsers={onSearchUsers}
          users={searchResults || users}
          showNames={showNames}
          includeId={true}
        />
      </Spin>
    </div>
  );
};

export default CheckedInReport;
