import { UsersRostersProps } from 'screener19-core';

import AuthRoute from 'components/AuthRoute';
import { Button } from 'antd';
import React from 'react';
import StaffRoster from 'components/StaffRoster';
import UsersIsolated from 'components/UsersIsolated';
import UsersPending from 'components/UsersPending';
import UsersQuarantined from 'components/UsersQuarantined';
import UsersWithNavMenu from 'components/UsersWithNavMenu';

const StaffRosters = ({
  allUsers,
  healthyUsers,
  pendingUsers,
  quarantinedUsers,
  isolatedUsers,
  unknownUsers,
  inactiveUsers,
  canUpdateRoles,
  canViewDashboard,
  canViewResponses,
  isTechAdmin,
  loading,
  location,
  onHeaderButtonClick,
  onSearchUsers,
  questions,
  setCurrentUserFilter,
  users,
  showNames,
}: UsersRostersProps) => {
  return (
    <React.Fragment>
      <AuthRoute exact auth={canViewDashboard} path="/dashboard/users/staff">
        <StaffRoster
          appendToUserLink={'all'}
          loading={loading}
          headerButton={
            (canUpdateRoles || isTechAdmin) && (
              <Button type="primary" onClick={onHeaderButtonClick}>
                Add Staff Member
              </Button>
            )
          }
          location={location}
          onSearchUsers={onSearchUsers}
          setCurrentUserFilter={setCurrentUserFilter}
          users={allUsers}
          showNames={showNames}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/staff/inactive"
      >
        <StaffRoster
          appendToUserLink={'inactive'}
          description={'Staff who are inactive in the SIS'}
          filter={'staff-inactive'}
          loading={loading}
          location={location}
          onSearchUsers={onSearchUsers}
          setCurrentUserFilter={setCurrentUserFilter}
          users={inactiveUsers}
          showNames={showNames}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/staff/healthy"
      >
        <StaffRoster
          appendToUserLink={'healthy'}
          description={'Staff who have responded today and are healthy'}
          filter={'staff-healthy'}
          loading={loading}
          location={location}
          onSearchUsers={onSearchUsers}
          setCurrentUserFilter={setCurrentUserFilter}
          users={healthyUsers}
          showNames={showNames}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/staff/review"
      >
        <UsersPending
          appendToUserLink={'pending'}
          canViewResponses={canViewResponses}
          description="Staff whose responses have triggered a nursing review"
          filter={'staff-pending'}
          loading={loading}
          location={location}
          questions={questions}
          setCurrentUserFilter={setCurrentUserFilter}
          onSearchUsers={onSearchUsers}
          users={pendingUsers}
          showNames={showNames}
          title={'Staff'}
          urlKey={'staff'}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/staff/quarantined"
      >
        <UsersQuarantined
          appendToUserLink={'quarantined'}
          description="Staff who are currently in quarantine"
          filter={'staff-quarantined'}
          loading={loading}
          location={location}
          setCurrentUserFilter={setCurrentUserFilter}
          onSearchUsers={onSearchUsers}
          users={quarantinedUsers}
          showNames={showNames}
          title={'Staff'}
          urlKey={'staff'}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/staff/isolated"
      >
        <UsersIsolated
          description="Staff who are currently in isolation"
          filter={'staff-isolated'}
          loading={loading}
          location={location}
          setCurrentUserFilter={setCurrentUserFilter}
          onSearchUsers={onSearchUsers}
          users={isolatedUsers}
          showNames={showNames}
          title={'Staff'}
          urlKey={'staff'}
        />
      </AuthRoute>
      <AuthRoute
        exact
        auth={canViewDashboard}
        path="/dashboard/users/staff/unknown"
      >
        <UsersWithNavMenu
          appendToUserLink={'unknown'}
          description="Staff who have not responded to the screener today are unknown"
          filter={'staff-unknown'}
          loading={loading}
          location={location}
          onSearchUsers={onSearchUsers}
          setCurrentUserFilter={setCurrentUserFilter}
          users={unknownUsers}
          showNames={showNames}
          title={'Staff'}
          urlKey={'staff'}
        />
      </AuthRoute>
    </React.Fragment>
  );
};

export default StaffRosters;
