import './styles.css';

import React from 'react';

const DemoLoginLinks = () => {
  return (
    <div className="home__demo-links">
      <h5>
        Interested in trying out the product? Select a Nurse or Student login
        below
      </h5>
      <a
        className="home__button home__demo-links-button"
        href="/auth/demo/nurse"
      >
        Sign in as a Nurse
      </a>
      <a
        className="home__button home__demo-links-button"
        href="/auth/demo/student"
      >
        Sign in as a Student
      </a>
      <a
        className="home__button home__demo-links-button"
        href="/auth/demo/teacher"
      >
        Sign in as a Teacher
      </a>
    </div>
  );
};

export default DemoLoginLinks;
