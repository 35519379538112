import './styles.css';

import { Card, Skeleton } from 'antd';
import { Class, User } from 'screener19-core';
import React, { useState } from 'react';

import UsersList from 'components/UsersList';
import useAsyncEffect from 'use-async-effect';
import { useParams } from 'react-router-dom';

interface Props {
  cls?: Class;
  fetchClass: (id: string) => void;
  loggedInUser: User;
  showNames?: boolean;
}

const ClassDetails = ({
  cls,
  loggedInUser,
  fetchClass,
  showNames = false,
}: Props) => {
  let { id } = useParams<any>();

  const [loading, setLoading] = useState(false);

  useAsyncEffect(async () => {
    if (id) {
      setLoading(true);
      await fetchClass(id);
      setLoading(false);
    }
  }, [id]);

  return (
    <div className="userprofile__container">
      <h1 className="classdetails_header">Class Information</h1>
      <div className="classdetails__cards">
        <Card className="userprofile__card">
          <Skeleton loading={loading} paragraph={{ rows: 1 }}>
            <div>
              <h1>{cls?.course?.title}</h1>
              <h2>{cls?.course?.description}</h2>
              {cls?.sectionNumber && <div>Section: {cls?.sectionNumber}</div>}
              {cls?.period && <div>Period: {cls?.period}</div>}
              {cls?.room && <div>Room: {cls?.room}</div>}
            </div>
          </Skeleton>
        </Card>
        <Card className="userprofile__card">
          <Skeleton loading={loading} paragraph={{ rows: 1 }}>
            <div>
              <div className="userprofile__card-name">
                {cls?.staff?.map((staff: User) => {
                  return (
                    <div className="classdetails_staff">
                      <div className="userprofile__identifier">
                        {staff.displayName}
                      </div>
                      <span className="userprofile__email">
                        {showNames ? staff?.studentId : '[hidden]'}
                      </span>
                      <br />
                      <span className="userprofile__email">
                        {showNames ? staff?.email : '[hidden]'}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </Skeleton>
        </Card>
      </div>
      <div className="classdetails__students">
        <UsersList
          users={cls?.students}
          loading={loading}
          linkToStudent={loggedInUser?.permissions?.canViewDashboard}
          showNames={showNames}
        />
      </div>
    </div>
  );
};

export default ClassDetails;
