const constants = {
  HEALTHY_COLOR: 'green',
  HEALTHY_GREEN_COLOR: '#52c41a',
  ISOLATION_COLOR: 'red',
  LOGIN_REDIRECT_STORAGE_KEY: 'loginRedirect',
  PENDING_COLOR: 'blue',
  PENDING_BLUE_COLOR: '#1890ff',
  PRIMARY_COLOR: '#0060b5',
  QUARANTINE_COLOR: 'orange',
};

export { constants };
