import './styles.less';

import DemoLoginLinks from 'components/DemoLoginLinks';
import Header from 'components/Header';

interface Props {
  alert?: any;
  onLogout?: () => void;
}

const Home = ({ alert, onLogout }: Props) => {
  return (
    <>
      {alert}
      <div className="home__header">
        <Header
          showLogo={true}
          logoHeight={50}
          showLogin={false}
          onLogout={onLogout}
        />
      </div>
      <div className="home">
        <div className="home__inner">
          <h1>
            COVID-19
            <br />
            Daily Student Health Screener
          </h1>
          <div className="home__description">
            <p>
              This form is vital to ensure the health and well being of each
              student and our school district. While on campus you also must
              follow the COVID-19 Safety Plan. Use your district email address
              to sign in.
            </p>
          </div>
          <div className="home__button-container">
            <div className="home__button-col">
              <a className="home__button" href="/auth/google">
                Sign in with Google
              </a>
              <small>
                Use your school district provided Google email address to sign
                in
              </small>
              {!!process.env.REACT_APP_DEMO_SITE && <DemoLoginLinks />}
            </div>
          </div>
          <div className="home__graphic">
            <img
              className="home__graphic-img"
              src={`${process.env.REACT_APP_S3_URL}/assets/images/undraw-doctor-blue-v2.png`}
            />
          </div>
        </div>
      </div>
      <div className="screener__footer">
        <a href="/privacy.html">Privacy Policy</a> |{' '}
        <a href="/terms.html">Terms of Use</a>
      </div>
    </>
  );
};

export default Home;
