import './styles.css';

import { Answer } from 'screener19-core';
import ListItem from 'components/ListItem';
import React from 'react';

interface ListProps {
  items: Answer[];
  multiselect?: boolean;
  onPressItem?: (index: number) => void;
  userIsQuarantinedOrIsolated?: boolean;
}

const List = ({
  items,
  onPressItem,
  multiselect,
  userIsQuarantinedOrIsolated,
}: ListProps) => {
  const onPress = (index: number) => {
    if (onPressItem) {
      onPressItem(index);
    }
  };

  return (
    <div>
      {items.map((item, i) => {
        const itemDisabled =
          item.disabledIfUnhealthy && userIsQuarantinedOrIsolated;

        const isLast = i === items.length - 1;

        const listItem = (
          <ListItem
            key={i}
            selected={item.selected}
            onPress={() => onPress(i)}
            disabled={itemDisabled}
            disabledMessage={item.disabledIfUnhealthyMessage}
          >
            {item.text}
          </ListItem>
        );

        if (itemDisabled) {
          return listItem;
        }

        return listItem;
      })}
    </div>
  );
};

export default List;
