import * as Sentry from '@sentry/react';

import React, { useEffect, useState } from 'react';

import { User } from 'screener19-core';
import UserQRCode from 'components/UserQRCode';
import UsersApi from 'screener19-core/dist/api/users';
import axiosInstance from 'util/api-util';
import { message } from 'antd';
import { useParams } from 'react-router-dom';

interface Params {
  id: string;
}

const DashBoardQRCode = (props: any) => {
  const { id } = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>();

  const fetchUserById = async (id: string) => {
    const userApi = new UsersApi(axiosInstance);

    const { getUser } = userApi;

    setLoading(true);
    try {
      const _user = await getUser(id);

      setUser(_user);
    } catch (error) {
      message.error(error.message);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchUserById(id);
    }
  }, []);

  return <UserQRCode loading={loading} user={user} />;
};

export default DashBoardQRCode;
