import './styles.css';

import { Class, School, User } from 'screener19-core';
import { useLocation, useParams } from 'react-router-dom';

import React from 'react';
import SchoolsNavMenu from 'components/SchoolsNavMenu';
import UsersList from 'components/UsersList';
import useAsyncEffect from 'use-async-effect';

interface Props {
  cls?: Class;
  loading: boolean;
  loadData: (classId: string) => void;
}

const AdminClass = ({ cls, loading, loadData }: Props) => {
  const location: any = useLocation();
  let { classId } = useParams<any>();

  useAsyncEffect(async () => {
    loadData(classId);
  }, []);

  const school = (cls?.school as unknown) as School;

  return (
    <div className="userprofile__container">
      <SchoolsNavMenu id={school?._id} selectedKeys={[location.pathname]} />
      <h2>
        {!!school?.name ? `${school?.name}:` : ''} {cls?.course?.title} -{' '}
        {cls?.sectionNumber}
      </h2>

      <div className="admindistrict_table">
        <UsersList
          users={(cls?.students as unknown) as User[]}
          loading={loading}
          showNames={true}
        />
      </div>
    </div>
  );
};

export default AdminClass;
