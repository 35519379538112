import './styles.css';

import { Alert, Col, Row, Spin } from 'antd';
import { Answer, Question, User } from 'screener19-core';
import React, { useEffect } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import DashboardStats from 'components/DashboardStats';
import UsersList from 'components/UsersList';
import compareDesc from 'date-fns/compareDesc';
import format from 'date-fns/format';

interface Props {
  currentSchool?: string;
  fetchStats: () => void;
  loading: boolean;
  loggedInUser: User;
  numberOfUsersOverdue?: number;
  onClickTests: (showCurrentSchool: boolean) => void;
  onChangePending: (users: User[]) => void;
  onChangeIsolationEndingSoon: (users: User[]) => void;
  onChangeQuarantineEndingSoon: (users: User[]) => void;
  questions: Array<Question>;
  stats: any;
  showNames?: boolean;
  urlKey?: string;
}

const DashboardHome = ({
  currentSchool,
  fetchStats,
  loading,
  loggedInUser,
  numberOfUsersOverdue,
  onClickTests,
  onChangePending,
  onChangeIsolationEndingSoon,
  onChangeQuarantineEndingSoon,
  questions,
  stats,
  showNames = false,
  urlKey = 'students',
}: Props) => {
  useEffect(() => {
    fetchStats();
  }, []);

  const getAnswerFromQuestionsForKey = (
    questions: Array<Question>,
    key: string
  ) => {
    const question = questions.find(
      (ques: Question) => ques.key.toString() === key
    );

    if (question) {
      const answers = question.answers.filter((a: Answer) => a.selected);
      if (answers) {
        const positiveAnswer = answers.find((a: any) => !!a.pendingText);
        return positiveAnswer;
      }
    }
  };

  const extraPendingColumns = !loggedInUser.permissions?.canViewResponses
    ? []
    : questions.map((q: Question, index: number) => {
        return {
          title: q.shortDescription,
          dataIndex: q.key,
          key: q.key,
          width: 50,
          // defaultSortOrder: 'ascend',
          className: 'userlist__col-center',
          sorter: {
            compare: (userA: User, userB: User) => {
              if (
                !userA.movedToPendingResponse ||
                !userB.movedToPendingResponse
              ) {
                return 0;
              }

              const answerA = getAnswerFromQuestionsForKey(
                userA.movedToPendingResponse.questions,
                q.key
              );
              const answerB = getAnswerFromQuestionsForKey(
                userB.movedToPendingResponse.questions,
                q.key
              );

              let answerApositive = !!answerA?.pendingText;
              let answerBpositive = !!answerB?.pendingText;

              return answerApositive === answerBpositive
                ? 0
                : answerApositive
                ? -1
                : 1;
            },
            multiple: index,
          },
          render: (text: string, user: User, index: any) => {
            if (user.movedToPendingResponse) {
              const a = getAnswerFromQuestionsForKey(
                user.movedToPendingResponse.questions,
                q.key
              );
              if (a && (a.warn || a.quarantine)) {
                return <CloseOutlined twoToneColor="red" />;
              }
              if (a && !!a.pendingText) {
                return <CloseOutlined twoToneColor="red" />;
              }
              if (a && !a.pendingText) {
                return '';
              }
            }

            return '';
          },
        };
      });

  return (
    <>
      {!loading && !stats && !currentSchool && (
        <Alert
          type="warning"
          message="You have dashboard access but are not assigned to any schools."
          style={{ marginBottom: 15 }}
        />
      )}
      {stats?.numberOfUsersOverdue > 0 && (
        <Alert
          message="Warning"
          description={`There are ${stats?.numberOfUsersOverdue} ${urlKey} that have passed their quarantine or isolation date.`}
          type="warning"
          showIcon
          closable
          style={{ marginBottom: 15 }}
        />
      )}

      <Spin spinning={loading}>
        <div className="dashboardhome__content">
          <DashboardStats
            stats={stats}
            loading={loading}
            onClickDistrictTests={() => onClickTests(false)}
            onClickSchoolTests={() => onClickTests(true)}
            canViewTestResults={loggedInUser.permissions?.canViewResponses}
            urlKey={urlKey}
          />
          {stats && stats.pendingUsers && (
            <Row>
              <Col span="24">
                <div className="dashboardhome__endingsoon">
                  <UsersList
                    appendToUserLink={'pending-dashboard'}
                    description="Students whose responses have triggered a nursing review"
                    includeSchools={false}
                    onChange={onChangePending}
                    title="Pending"
                    users={stats.pendingUsers}
                    showNames={showNames}
                    extraColumns={[
                      {
                        title: 'Pending Date',
                        dataIndex: 'needsReviewAt',
                        key: 'needsReviewAt',
                        width: 150,
                        defaultSortOrder: 'ascend',
                        render: (text: string, rec: any) => {
                          return format(new Date(text), 'LLL do');
                        },
                        sorter: (a: any, b: any) =>
                          compareDesc(
                            new Date(a.needsReviewAt),
                            new Date(b.needsReviewAt)
                          ),
                      },
                      ...extraPendingColumns,
                    ]}
                  />
                </div>
              </Col>
            </Row>
          )}
          {stats && stats.quarantineEndingSoon && (
            <Row>
              <Col span="24">
                <div className="dashboardhome__endingsoon">
                  <UsersList
                    appendToUserLink={'quarantine-ending'}
                    includeSchools={false}
                    title="Quarantine Ending Soon"
                    description="Students whose quarantine period ends within the next three (3) days"
                    users={stats.quarantineEndingSoon}
                    onChange={onChangeQuarantineEndingSoon}
                    showNames={showNames}
                    extraColumns={[
                      {
                        title: 'Days Remaining',
                        dataIndex: 'quarantineRemaining',
                        key: 'quarantineRemaining',
                        defaultSortOrder: 'descend',
                        sorter: (a: any, b: any) =>
                          compareDesc(
                            new Date(a.quarantineEndAt),
                            new Date(b.quarantineEndAt)
                          ),
                      },
                      {
                        title: 'End of Quarantine',
                        dataIndex: 'quarantineEndAt',
                        key: 'quarantineEndAt',
                        render: (text: string, rec: any) => {
                          if (text) {
                            return format(new Date(text), 'LLL do');
                          }

                          return '';
                        },
                        sorter: (a: any, b: any) =>
                          compareDesc(
                            new Date(a.quarantineEndAt),
                            new Date(b.quarantineEndAt)
                          ),
                      },
                    ]}
                  />
                </div>
              </Col>
            </Row>
          )}
          {stats && stats.isolationEndingSoon && (
            <Row>
              <Col span="24">
                <div className="dashboardhome__endingsoon">
                  <UsersList
                    appendToUserLink={'isolation-ending'}
                    description="Students whose isolation period ends within the next three (3) days"
                    includeSchools={false}
                    title="Isolation Ending Soon"
                    users={stats.isolationEndingSoon}
                    onChange={onChangeIsolationEndingSoon}
                    showNames={showNames}
                    extraColumns={[
                      {
                        title: 'Days Remaining',
                        dataIndex: 'monitoredRemaining',
                        key: 'monitoredRemaining',
                        defaultSortOrder: 'descend',
                        sorter: (a: any, b: any) =>
                          compareDesc(
                            new Date(a.monitoredEndAt),
                            new Date(b.monitoredEndAt)
                          ),
                      },
                      {
                        title: 'End of Isolation',
                        dataIndex: 'monitoredEndAt',
                        key: 'monitoredEndAt',
                        render: (text: string, rec: any) => {
                          return format(new Date(text), 'LLL do');
                        },
                        sorter: (a: any, b: any) =>
                          compareDesc(
                            new Date(a.monitoredEndAt),
                            new Date(b.monitoredEndAt)
                          ),
                      },
                    ]}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Spin>
    </>
  );
};

export default DashboardHome;
