import './styles.css';

import { Button, Card, Skeleton } from 'antd';
import { DeleteOutlined, ImportOutlined } from '@ant-design/icons';
import { useLocation, useParams } from 'react-router-dom';

import AdminSchools from 'components/AdminSchools';
import { District } from 'screener19-core';
import DistrictsNavMenu from 'components/DistrictsNavMenu';
import React from 'react';
import useAsyncEffect from 'use-async-effect';

const { Meta } = Card;

interface Props {
  district?: District;
  districts: Array<District>;
  loading: boolean;
  loadData: (id: string) => void;
  loadDistricts: () => void;
  onCreateSchool: (values: any) => void;
  onDeleteSchool: (id: string) => void;
  onDeleteCourses: (districtId: string) => void;
  onImportCourses: (districtId: string) => void;
  onImportSchools: (districtId: string) => void;
  onImportStaff: (districtId: string) => void;
  onUpdateSchool: (id: string, values: any) => void;
}

const AdminDistrictProfile = ({
  district,
  districts,
  loading,
  loadData,
  loadDistricts,
  onCreateSchool,
  onDeleteSchool,
  onDeleteCourses,
  onImportCourses,
  onImportSchools,
  onImportStaff,
  onUpdateSchool,
}: Props) => {
  let { id } = useParams<any>();
  let location = useLocation();

  useAsyncEffect(async () => {
    if (id) {
      loadData(id);
    }
    loadDistricts();
  }, []);

  const onCreate = async (values: any) => {
    if (onCreateSchool) {
      await onCreateSchool(values);
    }
    loadData(id);
  };

  const onDelete = async (schoolId: string) => {
    if (onDeleteSchool) {
      await onDeleteSchool(schoolId);
    }
    loadData(id);
  };

  const onUpdate = async (id: string, values: any) => {
    if (onUpdateSchool) {
      await onUpdateSchool(id, values);
    }
    loadData(id);
  };

  const deleteCourses = () => {
    const _confirm = window.confirm(
      'Are you sure you want to delete all of the courses and classes for this district?'
    );

    if (_confirm && onDeleteCourses) {
      onDeleteCourses(id);
    }
  };

  const importCourses = () => {
    if (onImportCourses) {
      onImportCourses(id);
    }
  };

  const importSchools = () => {
    if (onImportSchools) {
      onImportSchools(id);
    }
  };

  const importStaff = () => {
    if (onImportStaff) {
      onImportStaff(id);
    }
  };

  return (
    <React.Fragment>
      <DistrictsNavMenu id={id} selectedKeys={[location.pathname]} />
      <div className="userprofile__container">
        <Card className="userprofile__card" title={district?.name}>
          Cohorts:{' '}
          {district?.cohorts?.map((cohort: any, i) => {
            return (
              <div key={`cohort-${i}`}>
                <strong>{cohort.day}:</strong>{' '}
                <span>{cohort.cohorts.join(', ')}</span>
              </div>
            );
          })}
        </Card>
        <Card
          className="userprofile__card"
          actions={[
            <>
              <ImportOutlined key="meh" onClick={importSchools} />
              <small>
                <Button type="text" onClick={importSchools}>
                  Import Schools
                </Button>
              </small>
            </>,
            <>
              <ImportOutlined key="import-staff" onClick={importStaff} />
              <small>
                <Button type="text" onClick={importStaff}>
                  Import Staff
                </Button>
              </small>
            </>,
            <>
              <ImportOutlined key="import-courses" onClick={importCourses} />
              <small>
                <Button type="text" onClick={importCourses}>
                  Import Courses
                </Button>
              </small>
            </>,
            <>
              <DeleteOutlined key="delete-courses" onClick={deleteCourses} />
              <small>
                <Button type="text" onClick={deleteCourses}>
                  Delete All Courses
                </Button>
              </small>
            </>,
          ]}
        >
          <Skeleton loading={loading} paragraph={{ rows: 1 }}>
            <Meta title={district?.name} />
          </Skeleton>
        </Card>
        <div className="admindistrict_table">
          <AdminSchools
            districts={districts}
            loading={loading}
            data={district?.schools || []}
            onDelete={onDelete}
            onFinish={onCreate}
            onUpdate={onUpdate}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminDistrictProfile;
