import './styles.css';

import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import React from 'react';

interface Props {
  id?: string;
  selectedKeys: string[];
}

const DistrictsSettingsNavMenu = ({ id, selectedKeys }: Props) => {
  return (
    <Menu
      theme="light"
      selectedKeys={selectedKeys}
      mode="horizontal"
      className="userlist__menu"
    >
      <Menu.Item key={`/district/settings`}>
        <Link to={`/district/settings`}>Screener Questions</Link>
      </Menu.Item>
    </Menu>
  );
};

export default DistrictsSettingsNavMenu;
