import './styles.css';

import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import React from 'react';

interface Props {
  id?: string;
  selectedKeys: string[];
}

const SchoolsNavMenu = ({ id, selectedKeys }: Props) => {
  return (
    <Menu
      theme="light"
      selectedKeys={selectedKeys}
      mode="horizontal"
      className="userlist__menu"
    >
      <Menu.Item key={`/admin/schools/${id}`}>
        <Link to={`/admin/schools/${id}`}>Overview</Link>
      </Menu.Item>
      <Menu.Item key={`/admin/schools/${id}/classes`}>
        <Link to={`/admin/schools/${id}/classes`}>Classes</Link>
      </Menu.Item>
    </Menu>
  );
};

export default SchoolsNavMenu;
