import './App.less';

import React, { useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
  logoutAction,
  setJwt,
  setQuestions,
  setRefreshToken,
  setUser,
} from 'screener19-core/dist/redux';

import Account from 'containers/Account';
import AccountNotFound from 'containers/AccountNotFound';
import Admin from 'containers/Admin';
import AdminDistrict from 'containers/AdminDistrict';
import AuthRoute from 'components/AuthRoute';
import CheckIn from 'containers/CheckIn';
import CheckInSelf from 'containers/CheckInSelf';
import DashBoard from 'containers/DashBoard';
import DashBoardQRCode from 'containers/DashBoardQRCode';
import DashBoardReports from 'containers/DashBoardReports';
import DashBoardRosters from 'containers/DashBoardRosters';
import DistrictSettings from 'containers/DistrictSettings';
import LoggedInRoute from 'components/LoggedInRoute';
import LoggedOut from 'components/LoggedOut';
import NotifyApi from 'screener19-core/dist/api/notify';
import Page403 from 'components/Page403';
import Page404 from 'components/Page404';
import QuestionsApi from 'screener19-core/dist/api/questions';
import Screener from 'containers/Screener';
import UsersApi from 'screener19-core/dist/api/users';
import axiosInstance from 'util/api-util';
import { clearTokensFromLocalStorage } from 'util/logout-util';
import { message } from 'antd';
import { useAsyncEffect } from 'use-async-effect';
import { useTracking } from 'util/hooks/useTracking';

const App = (props: any) => {
  useTracking();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useAsyncEffect(async () => {
    const { JWT, refreshToken } = window.localStorage;
    if (JWT) {
      await props.setJwt(JWT);
    }
    if (refreshToken) {
      await props.setRefreshToken(refreshToken);
    }

    const jwtToken = JWT || props.jwt;

    if (jwtToken) {
      try {
        const questionApi = new QuestionsApi(axiosInstance);
        const questions = await questionApi.get();
        props.setQuestions(questions);

        const api = new UsersApi(axiosInstance);
        const response = await api.getMe();

        if (response && response._id) {
          return await props.setUser(response);
        }
      } catch (error) {
        console.log('error', error);

        message.warning('Please login again.');
        logout();
      }
    } else if (location?.pathname === '/') {
      logout();
    }
  }, []);

  const { user } = props;

  useEffect(() => {
    if (user) {
      gtag('set', 'user_properties', {
        district: user.district.name,
      });
    }
  }, [user]);

  const logout = async () => {
    dispatch(logoutAction());
    clearTokensFromLocalStorage();
    history.push('/');
  };

  const renderDemoBanner = () => {
    const showDemoBanner = false;

    if (showDemoBanner) {
      return (
        <div className="demo__banner">THIS SITE IS FOR DEMO PURPOSES ONLY.</div>
      );
    }
  };

  const onNotifyMissingAccount = async (email: string) => {
    return new Promise<void>(async (resolve, reject) => {
      const api = new NotifyApi(axiosInstance);
      try {
        await api.missingAccount(email);
        message.success(
          'Screener19 has been notified of your request.  We will contact you shortly.',
          0
        );
        resolve();
      } catch (error) {
        console.log(error);
        message.error(error.message);
        reject();
      }
    });
  };

  return (
    <div>
      {renderDemoBanner()}
      <Switch>
        <Route exact path={['/', '/index.html', '/screener/:key']}>
          <Screener onLogoutSuccess={logout} user={props.user} />
        </Route>
        <LoggedInRoute auth={!!user} exact path="/checkin">
          <CheckIn onLogout={logout} />
        </LoggedInRoute>
        <LoggedInRoute auth={!!user} exact path="/checkin/:id">
          <CheckInSelf onLogout={logout} />
        </LoggedInRoute>
        <AuthRoute
          exact
          auth={user?.permissions?.canViewDashboard}
          path="/dashboard/user/:id/qrcode"
        >
          <DashBoardQRCode />
        </AuthRoute>
        <AuthRoute
          exact
          auth={props.user?.permissions?.canViewDashboard}
          path={['/dashboard', '/dashboard/staff', '/dashboard/user/*']}
        >
          <DashBoard onLogout={logout} />
        </AuthRoute>
        <AuthRoute
          exact
          auth={props.user?.permissions?.canViewCheckin}
          path={['/account', '/account/history']}
        >
          <Account onLogout={logout} />
        </AuthRoute>
        <AuthRoute
          exact
          auth={props.user?.permissions?.canViewCheckin}
          path={['/account/class/*']}
        >
          <Account onLogout={logout} />
        </AuthRoute>
        <AuthRoute
          exact
          auth={
            !!user &&
            !!props.user.permissions &&
            props.user.permissions.canViewDashboard
          }
          path={[
            '/dashboard/users/students',
            '/dashboard/users/students/healthy',
            '/dashboard/users/students/isolated',
            '/dashboard/users/students/review',
            '/dashboard/users/students/quarantined',
            '/dashboard/users/students/unknown',
            '/dashboard/users/students/inactive',
            '/dashboard/users/staff',
            '/dashboard/users/staff/*',
          ]}
        >
          <DashBoardRosters onLogout={logout} />
        </AuthRoute>
        <AuthRoute
          auth={
            !!user &&
            !!props.user.permissions &&
            props.user.permissions.canViewDashboard
          }
          path={['/dashboard/reports']}
        >
          <DashBoardReports onLogout={logout} />
        </AuthRoute>
        <AuthRoute
          exact
          auth={user?.permissions?.canViewAdmin || user?.isTechAdmin}
          path="/district/settings"
        >
          <DistrictSettings onLogout={logout} />
        </AuthRoute>
        <AuthRoute
          auth={!!user && !!user.permissions && !!user.permissions.canViewAdmin}
          path="/admin/districts/:id"
        >
          <AdminDistrict onLogout={logout} />
        </AuthRoute>
        <AuthRoute
          auth={!!user && !!user.permissions && !!user.permissions.canViewAdmin}
          path={['/admin']}
        >
          <Admin onLogout={logout} />
        </AuthRoute>
        <Route path="/logged-out">
          <LoggedOut
            logout={() => {
              dispatch({
                type: 'USER_LOGOUT',
              });
            }}
            user={props.user}
          />
        </Route>
        <Route path="/account-not-found/:email">
          <AccountNotFound
            onNotify={onNotifyMissingAccount}
            onLogout={logout}
          />
        </Route>
        <Route path="/unauthorized">
          <Page403 />
        </Route>
        <Route>
          <Page404 />
        </Route>
      </Switch>
    </div>
  );
};

const AppRouter = (props: any) => {
  return (
    <Router>
      <App {...props} />
    </Router>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    questions: state.questions,
    auth: state.auth,
    user: state.user,
    jwt: state.auth?.jwt || window.localStorage.JWT || '',
  };
};

const mapDispatchToProps = {
  setJwt,
  setRefreshToken,
  setUser,
  setQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
