import './styles.css';

import { Button, Table } from 'antd';
import { Note, School } from 'screener19-core';
import { filterAndSortTableData, generateCSV } from 'util/export-util';

import { ExportOutlined } from '@ant-design/icons';
import React from 'react';
import { format } from 'date-fns';
import useAsyncEffect from 'use-async-effect';
import { useParams } from 'react-router-dom';

interface Props {
  chartData?: Map<string, number>;
  fetchTests: () => void;
  loading: boolean;
  onClickTestResult: (studentId: string) => void;
  tests: Note[];
  showNames: boolean;
}

const TestResultsReport = ({
  chartData,
  fetchTests,
  loading,
  onClickTestResult,
  tests,
  showNames,
}: Props) => {
  useAsyncEffect(async () => {
    await fetchTests();
  }, []);

  const { schoolId } = useParams<any>();

  const schoolFilters = new Map();

  for (const test of tests) {
    const school = (test?.school as unknown) as any;
    schoolFilters.set(school.id, {
      text: school?.name,
      value: school?.id,
    });
  }

  let defaultSchoolFilters: string[] = [];
  if (schoolId) {
    defaultSchoolFilters = [schoolId];
  }

  const exportCSV = () => {
    const exportData = filterAndSortTableData(tests as any, columns);
    generateCSV(exportData);
  };

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: 100,
    //   sorter: (a: any, b: any) => {
    //     return a.id < b.id;
    //   },
    //   render: (text: string, record: Note) => {
    //     return <>{record.id}</>;
    //   },
    // },
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
      sorter: (a: any, b: any) => {
        const aSchool = ((a.school as unknown) as School)?.name || '';
        const bSchool = ((b.school as unknown) as School)?.name || '';

        return aSchool.localeCompare(bSchool);
      },
      render: (text: string, record: Note) => {
        if (record.school) {
          const school = (record.school as unknown) as School;
          return school.name;
        }

        return '';
      },
      defaultFilteredValue: defaultSchoolFilters,
      fitered: true,
      filters: Array.from(schoolFilters, ([name, value]) => value).sort(
        (a: any, b: any) => {
          return a.text?.localeCompare(b.text);
        }
      ),
      onFilter: (value: string, record: any) => record.school?.id == value,
    },
    {
      title: 'Test Date',
      dataIndex: 'covidTestDate',
      key: 'covidTestDate',
      sorter: (a: any, b: any) =>
        a.covidTestDate.localeCompare(b.covidTestDate),
      render: (text: string, record: Note) => {
        if (record.covidTestDate) {
          const date = format(new Date(record.covidTestDate), 'LLLL do');
          return date;
        }

        return '';
      },
    },
    {
      title: 'Result Date',
      dataIndex: 'covidTestResultDate',
      key: 'covidTestResultDate',
      sorter: (a: any, b: any) =>
        a.covidTestResultDate.localeCompare(b.covidTestResultDate),
      render: (text: string, record: Note) => {
        if (record.covidTestResultDate) {
          const date = format(new Date(record.covidTestResultDate), 'LLLL do');
          return date;
        }

        return '';
      },
    },
    {
      title: 'Student',
      dataIndex: 'user',
      key: 'user',
      sorter: (a: Note, b: Note) =>
        a.user?.studentId?.localeCompare(b.user?.studentId),
      render: (text: string, record: Note) => {
        return record.user?.studentId;
      },
    },
    {
      title: 'Added By',
      dataIndex: 'addedByUser',
      key: 'addedBy',
      sorter: (a: Note, b: Note) =>
        a.addedByUser?.firstName?.localeCompare(b.addedByUser?.firstName),
      render: (text: string, record: Note) => {
        if (record.addedByUser?._id !== record.user?._id) {
          {
            return `${record.addedByUser?.firstName?.slice(
              0,
              1
            )}${record.addedByUser?.lastName?.slice(0, 1)}`;
          }
        }

        return text;
      },
    },
  ] as any;

  return (
    <div className="dashboard__list">
      <div className="userslist__header">
        <div>
          <h1 className="userslist__headerlabel">
            COVID-19 Positive Test Results
          </h1>
          <small>Tracking positive tests across the district</small>
        </div>

        <div className="userslist__header-col">
          <div className="userslist__header-button">
            <Button
              type="primary"
              onClick={exportCSV}
              icon={<ExportOutlined />}
            >
              Export to CSV
            </Button>
          </div>
        </div>
      </div>

      <Table
        size="small"
        loading={loading}
        showHeader={true}
        dataSource={tests}
        columns={columns}
        rowClassName="dashboardreports__testrow"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (onClickTestResult) {
                onClickTestResult(record.user._id);
              }
            },
          };
        }}
        pagination={{
          showSizeChanger: true,
          showTotal: (total: number, range: [number, number]) => {
            return `Showing ${range[0]}-${range[1]} of ${total}`;
          },
        }}
      />
    </div>
  );
};

export default TestResultsReport;
