import './index.css';
import './index.css';

import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';

import { persistor, store } from './redux/store';

import App from './containers/App';
import { Integrations } from '@sentry/tracing';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const { hostname } = window.location;

if (hostname !== 'localhost') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Extension context invalidated',
      'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
      'ceCurrentVideo.currentTime',
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.05,
  });
}

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./containers/App', render);
}
