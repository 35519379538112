import './styles.css';
import React from 'react';
import { User } from 'screener19-core';

interface Props {
  logoHeight?: number;
  showLogin?: boolean;
  showLogo?: boolean;
  user?: User;
  onLogout?: () => void;
}

const Header = ({
  logoHeight = 45,
  user,
  showLogin = true,
  showLogo = true,
  onLogout,
}: Props) => {
  const renderLinks = () => {
    if (user) {
      let elems = [
        <span key="divider-1"> | </span>,
        <a onClick={onLogout}>Logout</a>,
      ];

      if (user.permissions?.canViewDashboard) {
        elems = [
          <span key="divider-0"> | </span>,
          <a href="/dashboard" key="dashboard-link-1">
            Dashboard
          </a>,
          ...elems,
        ];
      }

      if (user.permissions?.canViewCheckin) {
        elems = [
          <span key="divider-2"> | </span>,
          <a href="/checkin" key="dashboard-link-2">
            Check-In
          </a>,
          <span key="divider-account"> | </span>,
          <a href="/account">Classes</a>,
          ...elems,
        ];
      }

      return [user?.displayName || user?.email, ...elems];
    }
  };

  if (user?._id) {
    return (
      <div className="nav">
        <div className="nav__logo">
          {showLogo && (
            <a href="/">
              <img
                height={logoHeight}
                src={`${process.env.REACT_APP_S3_URL}/assets/images/screener19-logo-v2.png`}
              />
            </a>
          )}
        </div>
        <h4 className="nav__links">{renderLinks()}</h4>
      </div>
    );
  }

  return (
    <div className="nav">
      <div className="nav__logo">
        {showLogo && (
          <a href="/">
            <img
              height={logoHeight}
              src={`${process.env.REACT_APP_S3_URL}/assets/images/screener19-logo-v2.png`}
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default Header;
