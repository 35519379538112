import './styles.css';

import { Button, Input, Space, Table, Tag } from 'antd';
import React, { useState } from 'react';
import { filterAndSortTableData, generateCSV } from 'util/export-util';

import { ExportOutlined } from '@ant-design/icons';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';
import { User } from 'screener19-core';
import { constants } from 'conf/constants';
import { debounce } from 'throttle-debounce';
import useAsyncEffect from 'use-async-effect';
import { useHistory } from 'react-router-dom';

const { Search } = Input;

interface Props {
  appendToUserLink?: string;
  description?: string;
  extraColumns?: any[];
  headerButton?: any;
  loading?: boolean;
  linkToStudent?: boolean;
  includeId?: boolean;
  includeSchools?: boolean;
  includeStatus?: boolean;
  fetchUsers?: () => void;
  onChange?: (users: User[]) => void;
  onSearchUsers?: (query: string) => void;
  paginationLabel?: string;
  paginationTotal?: number;
  showNames?: boolean;
  userTypeUrlKey?: string;
  users?: User[];
  title?: String;
}

const UsersList = ({
  appendToUserLink,
  description,
  extraColumns,
  headerButton,
  loading,
  linkToStudent = true,
  includeId = false,
  includeSchools = false,
  includeStatus = true,
  fetchUsers,
  onChange,
  onSearchUsers,
  paginationLabel = 'students',
  paginationTotal,
  showNames = false,
  users,
  userTypeUrlKey = 'user',
  title = 'Students',
}: Props) => {
  const history = useHistory();
  const [searchResulsts, setSearchResults] = useState<User[] | null>(null);

  const options = {
    includeScore: true,
    // Search in `author` and in `tags` array
    keys: ['displayName', '_id', 'email', 'schoolNames', 'studentId'],
    threshold: 0.25,
  };
  let fuse: any = new Fuse([], options);
  if (users) {
    fuse.setCollection(users);
  }

  const search = (query: string) => {
    if (query === '') {
      setSearchResults(null);
      return;
    }

    const result = fuse?.search(query);
    if (result) {
      setSearchResults(result.map((i: any) => i.item));
    }
  };

  const onSearch = debounce(100, search);

  useAsyncEffect(async () => {
    if (fetchUsers) {
      const users = await fetchUsers();
    }
  }, []);

  let columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      sorter: (a: any, b: any) => a.displayName.localeCompare(b.displayName),
      render: (text: string, user: User) => {
        if (!linkToStudent) {
          return showNames ? user.displayName : user.hiddenName;
        }

        const link = `/dashboard/${userTypeUrlKey}/${user._id}${
          !!appendToUserLink ? `/${appendToUserLink}` : ''
        }`;
        if (showNames) {
          return (
            <Link
              onClick={(e) => {
                e.stopPropagation();
              }}
              to={link}
            >
              {user.displayName}
            </Link>
          );
        }

        return (
          <Link
            onClick={(e) => {
              e.stopPropagation();
            }}
            to={link}
          >
            {user.hiddenName}
          </Link>
        );
      },
    } as any,
  ];

  if (includeStatus) {
    columns = [
      ...columns,
      {
        title: 'Status',
        dataIndex: 'quarantined',
        key: 'quarantined',
        render: (text: string, record: any) => {
          const status = () => {
            const clear = (
              <Tag
                className="userprofile__badge"
                color={constants.HEALTHY_COLOR}
              >
                healthy
              </Tag>
            );
            const unknown = (
              <Tag className="userprofile__badge" color={'default'}>
                unknown
              </Tag>
            );
            const quarantine = (
              <Tag
                className="userprofile__badge"
                color={constants.QUARANTINE_COLOR}
              >
                quarantined
              </Tag>
            );
            const monitored = (
              <div>
                <Tag
                  className="userprofile__badge"
                  color={constants.ISOLATION_COLOR}
                >
                  isolated
                </Tag>
              </div>
            );
            const needsReview = (
              <div>
                <Tag
                  className="userprofile__badge"
                  color={constants.PENDING_COLOR}
                >
                  pending
                </Tag>
              </div>
            );

            return (
              <Space direction="vertical">
                {!record?.quarantined &&
                  !record?.monitored &&
                  !record?.needsReview &&
                  !record.respondedToday &&
                  unknown}
                {!record?.quarantined &&
                  !record?.monitored &&
                  !record?.needsReview &&
                  record.respondedToday &&
                  clear}
                {record?.quarantined && quarantine}
                {record?.monitored && monitored}
                {record?.needsReview && needsReview}
              </Space>
            );
          };

          return status();
        },
        sorter: (a: User, b: User) => {
          const status = (record: User) => {
            if (
              !record?.quarantined &&
              !record?.monitored &&
              !record?.needsReview &&
              !record.respondedToday
            ) {
              return 'unknown';
            }

            if (
              !record?.quarantined &&
              !record?.monitored &&
              !record?.needsReview &&
              record.respondedToday
            ) {
              return 'healthy';
            }
            if (record?.quarantined) {
              return 'quarantined';
            }
            if (record?.monitored) {
              return 'monitored';
            }
            if (record?.needsReview) {
              return 'pending';
            }

            return '';
          };

          return status(a).localeCompare(status(b));
        },
      },
    ];
  }

  if (includeId) {
    columns = [
      ...columns,
      {
        title: 'ID',
        dataIndex: 'studentId',
        key: 'studentId',
        sorter: (a: any, b: any) => a.studentId.localeCompare(b.studentId),
      } as any,
    ];
  }

  const exportCSV = () => {
    const exportData = filterAndSortTableData(users as any, columns);
    generateCSV(exportData);
  };

  const cohortFilters = new Set();
  if (users) {
    for (const user of users) {
      if (user.cohort?.trim()) {
        cohortFilters.add(user.cohort);
      }
    }
  }

  if (includeStatus) {
    columns = [
      ...columns,
      {
        title: 'Cohort',
        dataIndex: 'cohort',
        key: 'cohort',
        fitered: true,
        filters: Array.from(cohortFilters)
          .sort()
          .map((c: any) => {
            return {
              text: `Cohort ${c}`,
              value: c,
            };
          }),
        onFilter: (value: string, record: any) => record.cohort == value,
        sorter: (a: any, b: any) => {
          if (a.cohort && b.cohort) {
            return a.cohort.localeCompare(b.cohort);
          }

          return 0;
        },
      },
    ];
  }

  if (includeSchools) {
    columns = [
      ...columns,
      {
        title: 'School',
        dataIndex: 'schoolNames',
        key: 'schoolNames',
        sorter: (a: any, b: any) => a.schoolNames.localeCompare(b.schoolNames),
        render: (text: string) => {
          return text;
        },
      },
    ];
  }

  if (extraColumns) {
    columns = [...columns, ...extraColumns];
  }

  return (
    <div>
      <div className="userslist__header">
        <div>
          <h1 className="userslist__headerlabel">{title}</h1>
          {description && <small>{description}</small>}
        </div>
        <div className="userslist__header-col">
          {headerButton && (
            <div className="userslist__header-button">{headerButton}</div>
          )}
          <div className="userslist__header-button">
            <Button
              type="primary"
              onClick={exportCSV}
              icon={<ExportOutlined />}
            >
              Export to CSV
            </Button>
          </div>
          {onSearchUsers && (
            <Search
              className="userslist__search"
              placeholder="Search"
              onSearch={onSearch}
              onChange={(e: any) => {
                if (onSearch) {
                  onSearch(e.target.value);
                }
              }}
              enterButton
            />
          )}
        </div>
      </div>
      <Table
        className="userslist__table"
        dataSource={searchResulsts || users}
        loading={loading && !users?.length}
        columns={columns}
        onChange={(pagination, filters, sorter, data) => {
          if (filters?.cohort) {
            return;
          }

          onChange && onChange(data?.currentDataSource);
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (linkToStudent) {
                const link = `/dashboard/${userTypeUrlKey}/${record._id}${
                  !!appendToUserLink ? `/${appendToUserLink}` : ''
                }`;
                history.push(link);
              }
            },
          };
        }}
        rowClassName="userslist_row"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          showTotal: (total: number, range: [number, number]) => {
            return `Showing ${range[0]}-${range[1]} of ${total} ${paginationLabel}`;
          },
        }}
      />
    </div>
  );
};

export default UsersList;
