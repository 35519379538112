import './styles.less';

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
} from 'antd';
import { Note, NoteType, User } from 'screener19-core';
import React, { useEffect, useState } from 'react';

import moment from 'moment';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface Props {
  onCancel: () => void;
  onDelete?: (id: string) => void;
  onFinish: (values: any) => void;
  onUpdate: (noteId: string, values: any) => void;
  visible: boolean;
  user?: User;
  note?: Note;
}

const UserAddIncidentFormModal = ({
  onFinish,
  onCancel,
  onDelete,
  onUpdate,
  user,
  visible,
  note,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [title, setTitle] = useState('Add Incident Form');
  const [quarantineOrIsolation, setQuarantineIsolation] = useState('no');
  const [hadCovidTest, setCovidTest] = useState<boolean | undefined>();

  useEffect(() => {
    if (visible) {
      setDeleting(false);
      setLoading(false);
      if (note) {
        setTitle('Update Incident Form');
        form.setFieldsValue({
          ...note,
          quarantineRange: [
            moment(note.quarantineOrIsolationStartAt),
            moment(note.quarantineOrIsolationEndAt),
          ],
          covidTestDate: note.covidTestDate
            ? moment(note.covidTestDate)
            : undefined,
        });
        const quarantineOrIsolation = note.quarantined
          ? 'quarantined'
          : note.isolated
          ? 'isolated'
          : 'no';
        setQuarantineIsolation(quarantineOrIsolation);
        setCovidTest(note.hadCovidTest);
      } else {
        setQuarantineIsolation('no');
        setCovidTest(false);
      }
    }
  }, [visible]);

  const validateMessages = {
    required: '${label} is required',
  };

  const onFinishForm = (values: any) => {
    setLoading(true);
    const data = {
      ...values,
      isolated: quarantineOrIsolation === 'isolated',
      quarantined: quarantineOrIsolation === 'quarantined',
      quarantineOrIsolationStartAt:
        values.quarantineRange && values.quarantineRange.length > 0
          ? values.quarantineRange[0]
          : '',
      quarantineOrIsolationEndAt:
        values.quarantineRange && values.quarantineRange.length > 0
          ? values.quarantineRange[1]
          : '',
      hadCovidTest: hadCovidTest,
      type: NoteType.incident,
    };

    if (note) {
      if (onUpdate) {
        onUpdate(note._id, data);
      }
      return;
    }

    if (onFinish) {
      onFinish(data);
    }
  };

  const renderIncidentForm = () => {
    return (
      <>
        <Form.Item
          name="recentContacts"
          label={
            <div>
              Whom has he/she/they been in close contact with (employees or
              students) in the 48 hour period prior to onset of symptoms?
              (within 6 feet for 15 minutes) <br />
              If yes, list names and dates:
            </div>
          }
        >
          <TextArea
            placeholder="Enter names and dates"
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item name="description" label={'Brief description of exposure:'}>
          <TextArea
            placeholder="Enter description"
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item
          name="symptoms"
          label={'What are the student’s current or past symptoms?'}
        >
          <TextArea
            placeholder="Enter symptoms"
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item
          name="lastDay"
          label={
            'Brief description of student’s last day on campus (with cohort, mixed with another cohort, contact with public, parents, students, etc.):'
          }
        >
          <TextArea
            placeholder="Enter description"
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item
          name="directionFromPhysician"
          label={
            'What direction has the student received from their private health care provider or local health department?'
          }
        >
          <TextArea
            placeholder="Enter direction"
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item
          name="quarantine"
          label={'Has the student been quarantined or isolated?'}
        >
          <Checkbox
            checked={quarantineOrIsolation === 'quarantined'}
            value="quarantined"
            onChange={(e) =>
              setQuarantineIsolation(e.target.checked ? e.target.value : '')
            }
          >
            Quarantined
          </Checkbox>
          <Checkbox
            defaultChecked={false}
            checked={quarantineOrIsolation === 'isolated'}
            value="isolated"
            onChange={(e) =>
              setQuarantineIsolation(e.target.checked ? e.target.value : '')
            }
          >
            Isolated
          </Checkbox>
        </Form.Item>
        <Row>
          <Col span={8}>
            {quarantineOrIsolation && quarantineOrIsolation === 'quarantined' && (
              <Form.Item
                name="quarantineRange"
                label={'Quarantine Dates'}
                rules={[{ required: true }]}
              >
                <RangePicker />
              </Form.Item>
            )}
            {quarantineOrIsolation && quarantineOrIsolation === 'isolated' && (
              <Form.Item
                name="quarantineRange"
                label={'Isolation Dates'}
                rules={[{ required: true }]}
              >
                <RangePicker />
              </Form.Item>
            )}
          </Col>
          {quarantineOrIsolation &&
            (quarantineOrIsolation === 'isolated' ||
              quarantineOrIsolation === 'quarantined') && (
              <>
                <Col span={1} />
                <Col span={8}>
                  <Form.Item
                    name="quarantineOrIsolationReason"
                    label="Reason for quarantine or isolation"
                  >
                    <Input placeholder="Enter reason" />
                  </Form.Item>
                </Col>
              </>
            )}
        </Row>
        {hadCovidTest && (
          <>
            <Form.Item
              name="hadCovidTest"
              label={'Has this student been tested for COVID-19?'}
            >
              <Checkbox
                checked={hadCovidTest}
                value="yes"
                onChange={(e) =>
                  setCovidTest(e.target.checked ? true : undefined)
                }
              >
                Yes
              </Checkbox>
              <Checkbox
                defaultChecked={false}
                checked={false}
                value="no"
                onChange={(e) =>
                  setCovidTest(e.target.checked ? false : undefined)
                }
              >
                No
              </Checkbox>
            </Form.Item>

            <Row>
              <Col span={5}>
                <Form.Item name="covidTestDate" label="COVID-19 Test Date">
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="covidTestResult"
                  label="COVID-19 Test Information"
                >
                  <Input name="covidTestResult" placeholder="Test result" />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={8}>
                <Form.Item
                  name="covidTestAdministeredBy"
                  label="Test Administered By"
                >
                  <Input
                    name="covidTestResult"
                    placeholder="Enter Test Administrator"
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Form.Item name="additionalInfo" label={'Additional Information'}>
          <TextArea
            placeholder="Enter additional information"
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
      </>
    );
  };

  return (
    <Modal
      title={title}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          Modal.confirm({
            title: (
              <div>
                <span>Are you sure you want to close the form?</span>
                <small className="incidentform__confirm-desc">
                  You will lose any unsaved changes.
                </small>
              </div>
            ),
            onOk: onCancel,
          });
        } else {
          onCancel();
        }
      }}
      footer={null}
      visible={visible}
      destroyOnClose={true}
      width={800}
      maskClosable={true}
    >
      <Form
        layout="vertical"
        preserve={false}
        form={form}
        name="control-hooks"
        onFinish={onFinishForm}
        validateMessages={validateMessages}
      >
        {renderIncidentForm()}
        <Form.Item hidden={true} name="type">
          <Input value="incident" />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button loading={loading} type="primary" htmlType="submit">
              {note ? 'Update Incident Report' : 'Save Incident Report'}
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this form?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                if (note && onDelete) {
                  setDeleting(true);
                  onDelete(note._id);
                }
              }}
            >
              {note && (
                <Button loading={deleting} type="default" htmlType="submit">
                  Delete
                </Button>
              )}
            </Popconfirm>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserAddIncidentFormModal;
