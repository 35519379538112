import './styles.css';

import * as Sentry from '@sentry/react';

import { Checkbox, Layout, Menu, Select, Space, Spin, message } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Note, School, User } from 'screener19-core';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  resetRosters,
  setCurrentSchool,
  setShowNames,
} from 'screener19-core/dist/redux';

import AuthRoute from 'components/AuthRoute';
import CheckedInLog from 'components/CheckedInLog';
import CheckedInReport from 'components/CheckedInReport';
import DashboardMenu from 'components/DashboardMenu';
import DashboardUserAccountLinks from 'components/DashboardUserAccountLinks';
import Fuse from 'fuse.js';
import NotesApi from 'screener19-core/dist/api/notes';
import SchoolsDropDownSelect from 'components/SchoolsDropDownSelect';
import TestResultsReports from 'components/TestResultsReport/TestResultsReport';
import UsersApi from 'screener19-core/dist/api/users';
import UsersList from 'components/UsersList';
import axiosInstance from 'util/api-util';
import format from 'date-fns/format';
import moment from 'moment';
import useAsyncEffect from 'use-async-effect';

const { Header, Content, Sider } = Layout;
const { Option } = Select;

const DashBoardReports = (props: any) => {
  const [collapsed, setCollapsed] = useState(false);
  const [currentUserFilter, setCurrentUserFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [positiveTests, setPositiveTests] = useState<Note[]>([]);
  const [testsChartData, setTestsChartData] = useState<Map<string, number>>();
  const [checkinStaff, setCheckinStaff] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [searchResults, setSearchResults] = useState<Array<any> | undefined>();
  const [showSchoolDropdown, setShowSchoolDropdown] = useState(true);
  const [showStaff, setShowStaff] = useState(false);
  const [showStudents, setShowStudents] = useState(true);
  const [showNamesCheckbox, setShowNamesCheckbox] = useState(true);
  const [currentDate, setCurrentDate] = useState(moment());

  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();
  let currentCheckinStaff: string[] = [];

  const options = {
    includeScore: true,
    keys: [
      'displayName',
      '_id',
      'email',
      'schoolNames',
      'studentId',
      'user.studentId',
      'user.displayName',
    ],
    threshold: 0.25,
  };

  const fuse = new Fuse(users, options);

  message.config({
    duration: 2,
    maxCount: 1,
  });

  useAsyncEffect(async () => {
    setLoading(true);
    await setCheckinStaff([]);
    currentCheckinStaff = [];
    const _users = await fetchUsers();

    if (_users) await updateCheckinStaff(_users);

    setLoading(false);
  }, [currentDate, showStaff, showStudents]);

  const updateCurrentCheckinStaff = async (staffIDs: string[]) => {
    currentCheckinStaff = staffIDs;

    setLoading(true);
    await fetchUsers();
    setLoading(false);
  };

  useAsyncEffect(async () => {
    setLoading(true);
    await fetchUsers();
    setLoading(false);
  }, [currentUserFilter, props.dashboard.currentSchool]);

  useAsyncEffect(async () => {
    await setCheckinStaff([]);
    currentCheckinStaff = [];
  }, [showStaff, showStudents]);

  const updateCheckinStaff = async (staff: any) => {
    let checkinAddedByUsers = new Map();

    for (const user of staff) {
      const history = user as any;
      if (history.addedByUser) {
        checkinAddedByUsers.set(history.addedByUser._id, history.addedByUser);
      }
    }
    setCheckinStaff(
      Array.from(checkinAddedByUsers.values()).sort((a: User, b: User) =>
        a.displayName.localeCompare(b.displayName)
      )
    );
  };

  const fetchUsers = async () => {
    return new Promise(async (resolve, reject) => {
      if (!props.dashboard.currentSchool && props.user.schools) {
        const school = props.user.schools[0]._id;
        await onChangeSchool(school);
      }

      const userApi = new UsersApi(axiosInstance);
      const {
        getCheckedIn,
        getCheckedInLog,
        getRespondedTodayUsers,
        getNotRespondedTodayUsers,
      } = userApi;

      const currentSchool = props.dashboard.currentSchool;

      setSearchResults(undefined);
      setUsers([]);

      try {
        let users;
        switch (currentUserFilter) {
          case 'checkedin':
            users = await getCheckedIn(
              currentSchool,
              new Date(currentDate.format()),
              showStudents,
              showStaff
            );
            break;
          case 'checkedin-log':
            users = await getCheckedInLog(
              currentSchool,
              new Date(currentDate.format()),
              currentCheckinStaff,
              showStudents,
              showStaff
            );
            break;
          case 'responded':
            users = await getRespondedTodayUsers(currentSchool);
            break;
          case 'not-responded':
            users = await getNotRespondedTodayUsers(currentSchool);
            break;

          default:
            break;
        }
        if (users) {
          setUsers(users);
        }

        // set checkin staff list
        if (users && checkinStaff.length === 0) {
          await updateCheckinStaff(users);
        }

        resolve(users);
      } catch (error) {
        message.error(error.message);
      }
    });
  };

  const fetchTests = async () => {
    fetchPositiveTests();
    fetchPositiveTestsByDay();
  };

  const fetchPositiveTests = async () => {
    try {
      const api = new NotesApi(axiosInstance);
      const tests = await api.getAllPositiveTestResultsInDistrict(
        props.user.district
      );

      setPositiveTests(tests);
    } catch (error) {
      Sentry.captureException(error);
      message.error(error.response.data.data || error.message);
    }
  };

  const fetchPositiveTestsByDay = async () => {
    try {
      const api = new NotesApi(axiosInstance);
      const tests = await api.getAllPositiveTestResultsByDayInDistrict(
        props.user.district
      );

      let chartData = new Map();
      for (const test of tests) {
        if (test.covidTestResultDate) {
          const date = format(new Date(test.covidTestResultDate), 'L-d');
          if (chartData.has(date)) {
            chartData.set(date, chartData.get(date) + 1);
          } else {
            chartData.set(date, 1);
          }
        }
      }

      setTestsChartData(chartData);
    } catch (error) {
      Sentry.captureException(error);
      message.error(error.response.data.data || error.message);
    }
  };

  const onSearchUsers = async (query: string) => {
    if (query === '') {
      setSearchResults(undefined);
      return;
    }

    const result = fuse.search(query);
    setSearchResults(result.map((i: any) => i.item));
  };

  const renderCheckedIn = () => {
    return (
      <CheckedInReport
        currentDate={currentDate}
        loading={loading}
        includeStaff={showStaff}
        includeStudents={showStudents}
        onChangeDate={async (date) => {
          setCurrentDate(date);
        }}
        onChangeStaffFilter={(show) => setShowStaff(show)}
        onChangeStudentsFilter={(show) => setShowStudents(show)}
        onFetchUsers={() => setCurrentUserFilter('checkedin')}
        onSearchUsers={onSearchUsers}
        users={searchResults || users}
        showNames={props.dashboard.showNames}
      />
    );
  };

  const renderCheckedInLog = () => {
    return (
      <CheckedInLog
        staff={checkinStaff}
        currentDate={currentDate}
        loading={loading}
        includeStaff={showStaff}
        includeStudents={showStudents}
        onChangeDate={async (date) => {
          setCurrentDate(date);
        }}
        onChangeStaff={(userIDs) => updateCurrentCheckinStaff(userIDs)}
        onChangeStaffFilter={(show) => setShowStaff(show)}
        onChangeStudentsFilter={(show) => setShowStudents(show)}
        onFetchUsers={() => setCurrentUserFilter('checkedin-log')}
        onSearchUsers={onSearchUsers}
        users={searchResults || users}
        showNames={props.dashboard.showNames}
      />
    );
  };

  const renderNotResponded = () => {
    return (
      <div className="dashboard__list">
        <Spin spinning={loading}>
          <UsersList
            fetchUsers={() => setCurrentUserFilter('not-responded')}
            onSearchUsers={onSearchUsers}
            users={searchResults || users}
            showNames={props.dashboard.showNames}
          />
        </Spin>
      </div>
    );
  };

  const renderRespondedToday = () => {
    return (
      <div className="dashboard__list">
        <Spin spinning={loading}>
          <UsersList
            fetchUsers={() => setCurrentUserFilter('responded')}
            onSearchUsers={onSearchUsers}
            users={searchResults || users}
            showNames={props.dashboard.showNames}
          />
        </Spin>
      </div>
    );
  };

  const navMenu = (
    <Menu
      theme="light"
      selectedKeys={[location.pathname]}
      mode="horizontal"
      className="userlist__menu"
    >
      <Menu.Item key="/dashboard/reports/checked-in">
        <Link to="/dashboard/reports/checked-in">Checked-In</Link>
      </Menu.Item>
      <Menu.Item key="/dashboard/reports/checked-in/log">
        <Link to="/dashboard/reports/checked-in/log">Check-In Log</Link>
      </Menu.Item>
      <Menu.Item key="/dashboard/reports/responded">
        <Link to="/dashboard/reports/responded">Responded Today</Link>
      </Menu.Item>
      <Menu.Item key="/dashboard/reports/not-responded">
        <Link to="/dashboard/reports/not-responded">Not Responded Today</Link>
      </Menu.Item>
      {props.user.permissions?.canViewResponses && (
        <Menu.Item key="/dashboard/reports/covid-tests">
          <Link to="/dashboard/reports/covid-tests">
            COVID-19 Positive Tests
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );

  const canViewDashboard = () => {
    return (
      !!props.user &&
      !!props.user.permissions &&
      props.user.permissions.canViewDashboard
    );
  };

  const onChangeSchool = async (id: string) => {
    await dispatch(setCurrentSchool(id));
    await props.resetRosters();
  };

  const defaultSchool =
    props.dashboard && props.dashboard.currentSchool
      ? props.dashboard.currentSchool
      : props.user.schools && props.user.schools.length
      ? props.user.schools[0] && props.user.schools[0]._id
        ? props.user.schools[0]._id
        : ''
      : '';

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(c) => setCollapsed(c)}
        theme="light"
      >
        <div className="logo" />
        <DashboardMenu
          canViewAdmin={props.user?.permissions?.canViewAdmin}
          canViewSettings={props.user?.isTechAdmin}
          currentMenuKey={'/dashboard/reports'}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="dashboard__header">
          <div>
            <Space>
              <SchoolsDropDownSelect
                defaultSchool={defaultSchool}
                onChange={onChangeSchool}
                schools={props.user.schools}
                showSchoolDropdown={showSchoolDropdown}
              />
              {showNamesCheckbox && (
                <Checkbox
                  checked={props.dashboard.showNames}
                  onChange={(e) => props.setShowNames(e.target.checked)}
                >
                  <small>Show Personal Information</small>
                </Checkbox>
              )}
            </Space>
          </div>
          <DashboardUserAccountLinks
            user={props.user}
            onLogout={props.onLogout}
          />
        </Header>
        <Content className="content__container">
          <AuthRoute
            exact
            auth={canViewDashboard()}
            path="/dashboard/reports/responded"
          >
            {navMenu}
            {renderRespondedToday()}
          </AuthRoute>
          <AuthRoute
            exact
            auth={canViewDashboard()}
            path="/dashboard/reports/not-responded"
          >
            {navMenu}
            {renderNotResponded()}
          </AuthRoute>
          <AuthRoute
            exact
            auth={canViewDashboard()}
            path="/dashboard/reports/checked-in"
          >
            {navMenu}
            {renderCheckedIn()}
          </AuthRoute>
          <AuthRoute
            exact
            auth={canViewDashboard()}
            path="/dashboard/reports/checked-in/log"
          >
            {navMenu}
            {renderCheckedInLog()}
          </AuthRoute>
          <AuthRoute
            exact
            auth={props.user.permissions.canViewResponses}
            path={[
              '/dashboard/reports/covid-tests',
              '/dashboard/reports/covid-tests/:schoolId',
            ]}
          >
            {navMenu}
            <TestResultsReports
              loading={loading}
              fetchTests={fetchTests}
              onClickTestResult={(id) => history.push(`/dashboard/user/${id}`)}
              tests={positiveTests}
              chartData={testsChartData}
              showNames={props.dashboard.showNames}
            />
          </AuthRoute>
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    auth: state.auth,
    dashboard: state.dashboard,
    districts: state.districts,
    user: state.user,
  };
};

const mapDispatchToProps = {
  resetRosters,
  setShowNames: setShowNames,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardReports);
