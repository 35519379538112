import './styles.css';

import { UsersRostersProps } from 'screener19-core';

import React from 'react';
import UsersWithNavMenu from 'components/UsersWithNavMenu';

const StaffRoster = (props: UsersRostersProps) => {
  return (
    <UsersWithNavMenu
      description={props.description || 'All staff'}
      title={props.title || 'Staff'}
      filter={props.filter || 'staff'}
      urlKey={'staff'}
      paginationLabel="staff members"
      {...props}
      extraColumns={[
        {
          title: 'Role',
          dataIndex: 'role',
          key: 'role',
          render: (text: string, rec: any) => {
            return text.slice(0, 1).toUpperCase() + text.slice(1);
          },
          sorter: (a: any, b: any) => a.role.localeCompare(b.role),
        },
      ]}
    />
  );
};

export default StaffRoster;
