import * as H from 'history';

import {
  Redirect,
  Route,
  RouteChildrenProps,
  RouteComponentProps,
} from 'react-router-dom';

import Page403 from 'components/Page403';
import React from 'react';

interface Props {
  auth?: boolean;
  location?: H.Location;
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  children?:
    | ((props: RouteChildrenProps<any>) => React.ReactNode)
    | React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

const AuthRoute = ({ children, auth, path, ...rest }: Props) => {
  if (auth === true) {
    return (
      <Route path={path} {...rest}>
        {children}
      </Route>
    );
  }
  return <Page403 />;
  return <Redirect to="/unauthorized" />;
};

export default AuthRoute;
