import './styles.css';

import * as Sentry from '@sentry/react';

import { Checkbox, Layout, Select, Space, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { School, User } from 'screener19-core';
import {
  clearTodaysResponse,
  reset,
  resetRosters,
  setAllStaff,
  setAllStudents,
  setCurrentSchool,
  setDistricts,
  setHealthyStaff,
  setHealthyStudents,
  setInactiveStaff,
  setInactiveStudents,
  setIsolatedStaff,
  setIsolatedStudents,
  setPendingStaff,
  setPendingStudents,
  setQuarantinedStaff,
  setQuarantinedStudents,
  setShowNames,
  setUnknownStaff,
  setUnknownStudents,
} from 'screener19-core/dist/redux';
import { connect, useDispatch } from 'react-redux';

import AddUserModal from 'components/AddUserModal';
import DashboardMenu from 'components/DashboardMenu';
import DashboardUserAccountLinks from 'components/DashboardUserAccountLinks';
import DistrictsApi from 'screener19-core/dist/api/districts';
import Fuse from 'fuse.js';
import RefreshingIndicator from 'components/RefreshingIndicator';
import SchoolsDropDownSelect from 'components/SchoolsDropDownSelect';
import StaffRosters from 'components/StaffRosters/StaffRosters';
import StudentRosters from 'components/StudentRosters';
import UsersApi from 'screener19-core/dist/api/users';
import axiosInstance from 'util/api-util';
import compareDesc from 'date-fns/compareDesc';
import useAsyncEffect from 'use-async-effect';
import { useLocation } from 'react-router-dom';

const { Header, Content, Sider } = Layout;
const { Option } = Select;

const DashBoardRosters = (props: any) => {
  const [collapsed, setCollapsed] = useState(false);
  const [siderBroken, setSiderBroken] = useState(false);
  const [currentMenuKey, setCurrentMenuKey] = useState('');
  const [currentUserFilter, setCurrentUserFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [searchResults, setSearchResults] = useState<Array<any> | undefined>();
  const [showSchoolDropdown, setShowSchoolDropdown] = useState(true);
  const [creatingUser, setCreatingUser] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const dispatch = useDispatch();
  let location = useLocation();

  const options = {
    includeScore: true,
    // Search in `author` and in `tags` array
    keys: ['displayName', '_id', 'email', 'schoolNames', 'studentId'],
    threshold: 0.25,
  };

  const fuse = new Fuse(users, options);

  message.config({
    duration: 2,
    maxCount: 1,
  });

  useAsyncEffect(async () => {
    if (props.user.permissions && props.user.permissions.canViewAdmin) {
      try {
        const api = new DistrictsApi(axiosInstance);
        const districts = await api.getAll();
        if (districts) {
          props.setDistricts(districts);
        }
      } catch (error) {
        Sentry.captureException(error);
        message.error(error.message);
      }
    }

    if (props.user?.email) {
      Sentry.setUser({ email: props.user?.email });

      Sentry.setContext('user', {
        name: props.user?.displaName,
        email: props.user?.email,
        permissions: props.user?.permissions,
      });
    }
  }, []);

  useAsyncEffect(async () => {
    loadUsers();
  }, [currentUserFilter]);

  useAsyncEffect(async () => {
    loadUsers();
  }, [props.dashboard.currentSchool]);

  useEffect(() => {
    const isUserPath = location.pathname.match(
      /^\/dashboard\/(user|students)(\/?)/g
    );
    const isStaffPath = location.pathname.match(/^\/dashboard\/staff(\/?)/g);

    setCurrentMenuKey(location.pathname);
  }, [location]);

  const fetchUsers = async () => {
    const userApi = new UsersApi(axiosInstance);

    const {
      getHealthyUsers,
      getHealthyStaff,
      getInactiveUsers,
      getIsolatedStaff,
      getUsers,
      getMonitoredUsers,
      getNeedsReviewUsers,
      getPendingStaff,
      getQuarantinedUsers,
      getQuarantinedStaff,
      getUnknownUsers,
      getUnknownStaff,
      getInactiveStaff,
      getStaff,
    } = userApi;

    if (
      !props.dashboard.currentSchool &&
      props.user.schools &&
      props.user.schools[0] &&
      props.user.schools[0]._id
    ) {
      const school = props.user.schools[0]._id;
      await onChangeSchool(school);
    }

    const currentSchool = props.dashboard.currentSchool;

    setSearchResults(undefined);
    setUsers([]);

    if (!currentUserFilter) return;

    try {
      setLoading(true);
      let users;
      switch (currentUserFilter) {
        case 'all':
          users = await getUsers(currentSchool);
          props.setAllStudents && props.setAllStudents(users);
          break;
        case 'inactive':
          users = await getInactiveUsers(currentSchool);
          props.setInactiveStudents && props.setInactiveStudents(users);
          break;
        case 'healthy':
          users = await getHealthyUsers(currentSchool);
          props.setHealthyStudents && props.setHealthyStudents(users);
          break;
        case 'pending':
          users = await getNeedsReviewUsers(currentSchool);
          // apply default sort by pending date.
          if (users?.length) {
            users.sort((a: any, b: any) =>
              compareDesc(new Date(b.needsReviewAt), new Date(a.needsReviewAt))
            );
          }
          props.setPendingStudents && props.setPendingStudents(users);
          break;
        case 'quarantined':
          users = await getQuarantinedUsers(currentSchool);

          // apply default sort by quarantine end.
          if (users?.length) {
            users.sort((a: any, b: any) =>
              compareDesc(
                new Date(b.quarantineEndAt),
                new Date(a.quarantineEndAt)
              )
            );
          }
          props.setQuarantinedStudents && props.setQuarantinedStudents(users);
          break;
        case 'monitored':
          users = await getMonitoredUsers(currentSchool);

          // apply default sort by isolation end.
          if (users?.length) {
            users.sort((a: any, b: any) =>
              compareDesc(
                new Date(b.monitoredEndAt),
                new Date(a.monitoredEndAt)
              )
            );
          }
          props.setIsolatedStudents && props.setIsolatedStudents(users);
          break;
        case 'unknown':
          users = await getUnknownUsers(currentSchool);
          props.setUnknownStudents && props.setUnknownStudents(users);
          break;
        case 'staff':
          users = await getStaff(currentSchool);
          props.setAllStaff && props.setAllStaff(users);
          break;
        case 'staff-inactive':
          users = await getInactiveStaff(currentSchool);
          props.setInactiveStaff && props.setInactiveStaff(users);
          break;
        case 'staff-healthy':
          users = await getHealthyStaff(currentSchool);
          props.setHealthyStaff && props.setHealthyStaff(users);
          break;
        case 'staff-pending':
          users = await getPendingStaff(currentSchool);
          // apply default sort by pending date.
          if (users?.length) {
            users.sort((a: any, b: any) =>
              compareDesc(new Date(b.needsReviewAt), new Date(a.needsReviewAt))
            );
          }
          props.setPendingStaff && props.setPendingStaff(users);
          break;
        case 'staff-quarantined':
          users = await getQuarantinedStaff(currentSchool);
          // apply default sort by quarantine end.
          if (users?.length) {
            users.sort((a: any, b: any) =>
              compareDesc(
                new Date(b.quarantineEndAt),
                new Date(a.quarantineEndAt)
              )
            );
          }
          props.setQuarantinedStaff && props.setQuarantinedStaff(users);
          break;
        case 'staff-isolated':
          users = await getIsolatedStaff(currentSchool);
          // apply default sort by isolation end.
          if (users?.length) {
            users.sort((a: any, b: any) =>
              compareDesc(
                new Date(b.monitoredEndAt),
                new Date(a.monitoredEndAt)
              )
            );
          }
          props.setIsolatedStaff && props.setIsolatedStaff(users);
          break;
        case 'staff-unknown':
          users = await getUnknownStaff(currentSchool);
          props.setUnknownStaff && props.setUnknownStaff(users);
          break;

        default:
          break;
      }
      if (users) {
        setUsers(users);
      }
    } catch (error) {
      message.error(error.message);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const loadUsers = async () => {
    await fetchUsers();
  };

  const onSearchUsers = async (query: string) => {
    if (query === '') {
      setSearchResults(undefined);
      return;
    }

    const result = fuse.search(query);
    setSearchResults(result.map((i: any) => i.item));
  };

  const canViewDashboard = () => {
    return (
      !!props.user &&
      !!props.user.permissions &&
      props.user.permissions.canViewDashboard
    );
  };

  const onChangeSchool = async (id: string) => {
    await dispatch(setCurrentSchool(id));
    await props.resetRosters();
  };

  const onCreateUser = async (data: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        setCreatingUser(true);

        const { currentSchool } = props.dashboard;

        if (!currentSchool) {
          message.error('No school selected');
        }

        const userData = {
          ...data,
          displayName: `${data.firstName} ${data.lastName}`,
          schoolId: currentSchool,
          role: data.role || 'user',
        };

        const usersApi = new UsersApi(axiosInstance);
        await usersApi.createUser(userData);
        message.success(`${userData.displayName} has been created`);
        loadUsers();
      } catch (error) {
        message.error(error.message);
      } finally {
        setCreatingUser(false);
        setShowAddUserModal(false);
      }
    });
  };

  const defaultSchool =
    props.dashboard && props.dashboard.currentSchool
      ? props.dashboard.currentSchool
      : props.user.schools && props.user.schools.length
      ? props.user.schools[0] && props.user.schools[0]._id
        ? props.user.schools[0]._id
        : ''
      : '';

  return (
    <Layout
      style={{ minHeight: '100vh' }}
      className={!siderBroken ? 'dashboard__collapsed' : ''}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(c) => setCollapsed(c)}
        onBreakpoint={(b) => setSiderBroken(b)}
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div className="logo" />
        <DashboardMenu
          canViewAdmin={props.user?.permissions?.canViewAdmin}
          canViewSettings={props.user?.isTechAdmin}
          currentMenuKey={location.pathname}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="dashboard__header">
          <div>
            <Space>
              <SchoolsDropDownSelect
                defaultSchool={defaultSchool}
                onChange={onChangeSchool}
                schools={props.user.schools}
                showSchoolDropdown={showSchoolDropdown}
              />
              <Checkbox
                checked={props.dashboard.showNames}
                onChange={(e) => props.setShowNames(e.target.checked)}
              >
                <small>Show Personal Information</small>
              </Checkbox>
            </Space>
          </div>
          <DashboardUserAccountLinks
            user={props.user}
            onLogout={props.onLogout}
          />
        </Header>
        <Content className="content__container">
          <RefreshingIndicator loading={loading} />
          <StudentRosters
            allUsers={props.rosters?.students?.all}
            healthyUsers={props.rosters?.students?.healthy}
            pendingUsers={props.rosters?.students?.pending}
            quarantinedUsers={props.rosters?.students?.quarantined}
            isolatedUsers={props.rosters?.students?.isolated}
            inactiveUsers={props.rosters?.students?.inactive}
            unknownUsers={props.rosters?.students?.unknown}
            canViewDashboard={canViewDashboard()}
            canViewResponses={!!props.user?.permissions?.canViewResponses}
            loading={loading}
            location={location.pathname}
            questions={props.questions}
            setCurrentUserFilter={setCurrentUserFilter}
            onChangeAllUsers={(users) =>
              props.setAllStudents && props.setAllStudents(users)
            }
            onChangePendingUsers={(users) =>
              props.setPendingStudents && props.setPendingStudents(users)
            }
            onChangeHealthyUsers={(users) =>
              props.setHealthyStudents && props.setHealthyStudents(users)
            }
            onChangeUnknownUsers={(users) =>
              props.setUnknownStudents && props.setUnknownStudents(users)
            }
            onChangeQuarantinedUsers={async (users) =>
              props.setQuarantinedStudents &&
              props.setQuarantinedStudents(users)
            }
            onChangeIsolatedUsers={(users) =>
              props.setIsolatedStudents && props.setIsolatedStudents(users)
            }
            onChangeInactiveUsers={(users) =>
              props.setInactiveStudents && props.setInactiveStudents(users)
            }
            onSearchUsers={onSearchUsers}
            users={searchResults || users}
            showNames={props.dashboard.showNames}
          />
          <StaffRosters
            allUsers={props.rosters?.staff?.all}
            healthyUsers={props.rosters?.staff?.healthy}
            pendingUsers={props.rosters?.staff?.pending}
            quarantinedUsers={props.rosters?.staff?.quarantined}
            isolatedUsers={props.rosters?.staff?.isolated}
            inactiveUsers={props.rosters?.staff?.inactive}
            unknownUsers={props.rosters?.staff?.unknown}
            canUpdateRoles={!!props.user?.permissions?.canUpdateRoles}
            canViewDashboard={canViewDashboard()}
            canViewResponses={!!props.user?.permissions?.canViewResponses}
            isTechAdmin={!!props.user?.isTechAdmin}
            loading={loading}
            location={location.pathname}
            questions={props.questions}
            setCurrentUserFilter={setCurrentUserFilter}
            onHeaderButtonClick={() => setShowAddUserModal(true)}
            onChangeAllUsers={(users) =>
              props.setAllStaff && props.setAllStaff(users)
            }
            onChangePendingUsers={(users) =>
              props.setPendingStaff && props.setPendingStaff(users)
            }
            onChangeHealthyUsers={(users) =>
              props.setHealthyStaff && props.setHealthyStaff(users)
            }
            onChangeUnknownUsers={(users) =>
              props.setUnknownStaff && props.setUnknownStaff(users)
            }
            onChangeQuarantinedUsers={async (users) =>
              props.setQuarantinedStaff && props.setQuarantinedStaff(users)
            }
            onChangeIsolatedUsers={(users) =>
              props.setIsolatedStaff && props.setIsolatedStaff(users)
            }
            onChangeInactiveUsers={(users) =>
              props.setInactiveStaff && props.setInactiveStaff(users)
            }
            onSearchUsers={onSearchUsers}
            users={searchResults || users}
            showNames={props.dashboard.showNames}
          />
          <AddUserModal
            loading={creatingUser}
            onCancel={() => setShowAddUserModal(false)}
            onFinish={async (data) => {
              await onCreateUser(data);
            }}
            title={'Add Staff Member'}
            visible={showAddUserModal}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    dashboard: state.dashboard,
    districts: state.districts,
    questions: state.questions,
    rosters: state.rosters,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearTodaysResponse,
  reset,
  resetRosters,
  setAllStaff,
  setAllStudents,
  setCurrentSchool,
  setHealthyStaff,
  setHealthyStudents,
  setInactiveStaff,
  setInactiveStudents,
  setIsolatedStaff,
  setIsolatedStudents,
  setPendingStaff,
  setPendingStudents,
  setQuarantinedStaff,
  setQuarantinedStudents,
  setUnknownStaff,
  setUnknownStudents,
  setDistricts,
  setShowNames,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardRosters);
