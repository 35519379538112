import { District, User } from 'screener19-core';

import { Card } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  district?: District;
  onLogout: () => void;
  user: User;
}

const DistrictLogs = ({ district, onLogout, user }: Props) => {
  let location = useLocation();

  return (
    <div>
      <div className="district-settings__container">
        <Card title="District Logs">
          <ul>
            {district?.importLog?.map((log) => {
              return <li>{log}</li>;
            })}
          </ul>
        </Card>
      </div>
    </div>
  );
};

export default DistrictLogs;
