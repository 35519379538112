import './styles.less';

import {
  Button,
  Card,
  Collapse,
  DatePicker,
  Select,
  Skeleton,
  Space,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FrownTwoTone,
  LeftOutlined,
  RightOutlined,
  SmileTwoTone,
} from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { School, User, UserRole } from 'screener19-core';

import QRCode from 'react-qr-code';
import UserCardStatus from './UserCardStatus';
import { constants } from 'conf/constants';
import { formatPhoneNumber } from 'util/dashboard';

const { Meta } = Card;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props {
  userListBucket: string;
  userList: User[];
  canChangeHomeSchool?: boolean;
  canDeleteUsers: boolean;
  canUpdateRoles: boolean;
  canUpdateStatuses: boolean;
  isTechAdmin: boolean;
  loading: boolean;
  onChangeHomeSchool: (schoolId: string) => void;
  onPressDelete?: (id: string) => void;
  showEditForm: (show: boolean) => void;
  showHealthyForm: (show: boolean) => void;
  showMonitorForm: (show: boolean) => void;
  showQuarantineForm: (show: boolean) => void;
  showNames?: boolean;
  showQrCode?: boolean;
  user?: User;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const UserCard = ({
  canChangeHomeSchool,
  canDeleteUsers,
  userListBucket,
  userList,
  canUpdateRoles,
  canUpdateStatuses,
  loading,
  onChangeHomeSchool,
  isTechAdmin,
  onPressDelete,
  showEditForm,
  showHealthyForm,
  showMonitorForm,
  showQuarantineForm,
  showNames = false,
  showQrCode = true,
  user,
}: Props) => {
  let { id, bucket } = useParams<any>();

  const description = () => {
    const renderStatus = (): any => {
      if (
        !user?.quarantined &&
        !user?.monitored &&
        !user?.needsReview &&
        !user?.respondedToday
      ) {
        return <UserCardStatus label="unknown" color={'default'} />;
      }
      if (
        !user?.quarantined &&
        !user?.monitored &&
        !user?.needsReview &&
        user?.respondedToday
      ) {
        return (
          <UserCardStatus label="healthy" color={constants.HEALTHY_COLOR} />
        );
      }
      if (user?.needsReview) {
        return (
          <UserCardStatus label="pending" color={constants.PENDING_COLOR} />
        );
      }
      if (user?.quarantined) {
        return (
          <UserCardStatus
            label="quarantined"
            onClick={onPressQuarantine}
            color={constants.QUARANTINE_COLOR}
            start={user?.quarantineStartAt}
            end={user?.quarantineEndAt}
            remaining={user?.quarantineRemaining}
          />
        );
      }

      if (user?.monitored) {
        return (
          <UserCardStatus
            label="isolated"
            onClick={onPressMonitor}
            color={constants.ISOLATION_COLOR}
            start={user?.monitoredStartAt}
            end={user?.monitoredEndAt}
            remaining={user?.monitoredRemaining}
          />
        );
      }
    };

    const role =
      user && !!user.role && user.role !== 'user'
        ? user.role.charAt(0).toUpperCase() + user.role.slice(1)
        : '';

    return (
      <>
        <span className="userprofile__email">
          ID: {showNames ? user?.studentId : '[hidden]'}
        </span>
        <br />
        <span className="userprofile__email">
          Email: {showNames ? user?.email : '[hidden]'}
        </span>
        {user?.schoolNames && (
          <>
            <br />
            <span className="userprofile__email">{user?.schoolNames}</span>
          </>
        )}
        {user?.cohort && (
          <>
            <br />
            <span className="userprofile__email">
              Cohort: {showNames ? user?.cohort : '[hidden]'}
            </span>
          </>
        )}
        {user?.mobilePhone && (
          <>
            <br />
            <span className="userprofile__email">
              Mobile Phone:{' '}
              <a href={`tel:${user?.mobilePhone}`}>
                {showNames ? formatPhoneNumber(user?.mobilePhone) : '[hidden]'}
              </a>
            </span>
          </>
        )}
        {user?.homePhone && (
          <>
            <br />
            <span className="userprofile__email">
              Home Phone:{' '}
              <a href={`tel:${user?.homePhone}`}>
                {showNames ? formatPhoneNumber(user?.homePhone) : '[hidden]'}
              </a>
            </span>
          </>
        )}
        {user?.parentPhone && (
          <>
            <br />
            <span className="userprofile__email">
              Parent Phone:{' '}
              <a href={`tel:${user?.parentPhone}`}>
                {showNames ? formatPhoneNumber(user?.parentPhone) : '[hidden]'}
              </a>
            </span>
          </>
        )}
        {!!user?.inactiveStatusCode && (
          <>
            <br />
            <small>
              Inactive Status:{' '}
              <b>
                <code>{user?.inactiveStatusCode}</code>
              </b>
            </small>
          </>
        )}
        {!!role && (
          <>
            <br />
            <small>{role}</small>
          </>
        )}
        {user?.isTechAdmin && (
          <>
            <br />
            <small>
              <b>Tech Admin</b>
            </small>
          </>
        )}
        {renderStatus()}
      </>
    );
  };

  const title = (
    <>
      <span className="userprofile__identifier">
        {showNames ? user?.displayName : user?.hiddenName}
      </span>
    </>
  );

  const onPressEdit = () => {
    if (showEditForm) {
      showEditForm(true);
    }
  };

  const onPressHealthy = () => {
    if (showHealthyForm) {
      showHealthyForm(true);
    }
  };

  const onPressMonitor = () => {
    if (showMonitorForm) {
      showMonitorForm(true);
    }
  };

  const onPressQuarantine = () => {
    if (showQuarantineForm) {
      showQuarantineForm(true);
    }
  };

  let actions: any[] = [];
  if (canUpdateStatuses) {
    actions = [
      <>
        <SmileTwoTone
          key="smile"
          twoToneColor={constants.HEALTHY_GREEN_COLOR}
          onClick={onPressHealthy}
        />
        <small>
          <a onClick={onPressHealthy}>Healthy</a>
        </small>
      </>,
      <>
        <FrownTwoTone
          key="frown"
          twoToneColor={constants.QUARANTINE_COLOR}
          onClick={onPressQuarantine}
        />
        <small>
          <a onClick={onPressQuarantine}>Quarantine</a>
        </small>
      </>,
      <>
        <FrownTwoTone
          key="meh"
          twoToneColor={constants.ISOLATION_COLOR}
          onClick={onPressMonitor}
        />
        <small>
          <a onClick={onPressMonitor}>Isolate</a>
        </small>
      </>,
    ];
  }

  if (canUpdateRoles || isTechAdmin) {
    actions.push(
      <>
        <EditOutlined key="edit" onClick={onPressEdit} />
        <small>
          <a onClick={onPressEdit}>Edit</a>
        </small>
      </>
    );
  }

  if (canDeleteUsers || isTechAdmin) {
    actions.push(
      <>
        <DeleteOutlined
          key="delete"
          onClick={() => onPressDelete && onPressDelete(id)}
        />
        <small>
          <a onClick={() => onPressDelete && onPressDelete(id)}>Delete</a>
        </small>
      </>
    );
  }

  const renderNextPreviousButtons = () => {
    if (userList?.length > 0) {
      const userIndexInList = userList?.findIndex((u: User) => u._id === id);
      let previousUser: User | null = null;
      let nextUser: User | null = null;
      if (userIndexInList > 0) {
        previousUser = userList[userIndexInList - 1];
      }

      if (userIndexInList + 1 < userList.length) {
        nextUser = userList[userIndexInList + 1];
      }

      return (
        <div className="userprofile__list-buttons">
          <Space>
            {previousUser && (
              <Link to={`/dashboard/user/${previousUser?._id}/${bucket}`}>
                <Button icon={<LeftOutlined />} />
              </Link>
            )}
            {!previousUser && (
              <>
                <Button icon={<LeftOutlined />} disabled />{' '}
              </>
            )}
          </Space>
          <div className="userprofile__list-count">
            <span>{bucket}</span>
            <p>
              {userIndexInList + 1} of {userList.length}
            </p>
          </div>
          <Space>
            {nextUser && (
              <Link to={`/dashboard/user/${nextUser?._id}/${bucket}`}>
                <Button icon={<RightOutlined />} />
              </Link>
            )}
            {!nextUser && (
              <>
                <Button icon={<RightOutlined />} disabled />
              </>
            )}
          </Space>
        </div>
      );
    }
  };

  return (
    <Card className="userprofile__card" actions={actions}>
      {renderNextPreviousButtons()}
      <Skeleton loading={loading} paragraph={{ rows: 1 }}>
        <div className="userprofile__card-data">
          <div className="userprofile__card-name">
            <div className="userprofile__identifier">
              {showNames ? user?.displayName : user?.hiddenName}
            </div>
            <div className="userprofile__description">{description()}</div>
            {canChangeHomeSchool && (
              <div className="userprofile__homeschooldropdown">
                <h5>Home School: </h5>
                <Select
                  className="dashboard__header-select"
                  defaultValue={
                    user?.homeSchool?._id ||
                    (user?.schools && user?.schools[0]._id)
                  }
                  onChange={(school) => onChangeHomeSchool(school)}
                  dropdownMatchSelectWidth={false}
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {user?.schools &&
                    user?.schools.map((school: School) => {
                      return <Option value={school._id}>{school.name}</Option>;
                    })}
                </Select>
              </div>
            )}
          </div>
          <div>
            {showQrCode && user && user?.role !== UserRole.USER && (
              <Link to={`/dashboard/user/${id}/qrcode`}>
                <QRCode value={user?._id} size={40} />
              </Link>
            )}
          </div>
        </div>
      </Skeleton>
    </Card>
  );
};

export default UserCard;
