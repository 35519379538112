import React from 'react';
import './styles.css';

interface Props {
  children?: any;
}

const HeaderText = ({ children }: Props) => {
  return <h1 className="header-label">{children}</h1>;
};

export default HeaderText;
