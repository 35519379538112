import './styles.less';

import { Col, Result, Row, message } from 'antd';
import { History, User } from 'screener19-core';
import { LoadingOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import React, { useState } from 'react';
import { fetchQuestions, setUser } from 'screener19-core/dist/redux';

import CheckInApi from 'screener19-core/dist/api/checkin';
import Header from 'components/Header';
import UsersApi from 'screener19-core/dist/api/users';
import axiosInstance from 'util/api-util';
import { connect } from 'react-redux';
import { constants } from 'conf/constants';
import { format } from 'date-fns';
import useAsyncEffect from 'use-async-effect';
import { useParams } from 'react-router-dom';

interface Props {
  user: User;
  onLogout: () => void;
}

const CheckInSelf = ({ user, onLogout }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [history, setHistory] = useState<History | undefined>(undefined);
  const [student, setStudent] = useState<User | undefined>(undefined);
  const [staff, setStaff] = useState<User | undefined>(undefined);

  let { id } = useParams<any>();
  const api = new CheckInApi(axiosInstance);
  const userApi = new UsersApi(axiosInstance);

  useAsyncEffect(async () => {
    await fetchStaff();
    window.localStorage.removeItem(constants.LOGIN_REDIRECT_STORAGE_KEY);
  }, []);

  const fetchStaff = async () => {
    const staff = await userApi.getUserForCheckin(id);
    setStaff(staff);
  };

  const fetchCheckIn = async () => {
    setLoading(true);
    try {
      const _user = await api.checkinSelfWithStaff(id);
      setError('');
      setStudent(_user?.student);
      setStaff(_user?.staff);
      setHistory(_user?.history);
    } catch (error) {
      console.log(error);

      const errorMsg =
        error.response && error.response.data && error.response.data.data
          ? error.response.data.data
          : error.message;

      message.error(errorMsg);
      setError(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  const renderLoading = () => {
    if (loading) {
      return (
        <Row className="checkin__row">
          <Col xl={4} lg={8} md={16} sm={20} xs={20} className="">
            <LoadingOutlined style={{ color: 'blue', fontSize: 24 }} spin />
            <div className="checkin__loading ant-result-title">
              Checking you in...
            </div>
          </Col>
        </Row>
      );
    }
  };

  const renderPrompt = () => {
    if (!loading && !student) {
      return (
        <Row className="checkin__row">
          <Col xl={4} lg={8} md={16} sm={20} xs={20} className="">
            <div className="ant-result-title">
              Do you want to check in with{' '}
              {staff ? (
                staff.displayName
              ) : (
                <LoadingOutlined className="checkin__inline-spinner" spin />
              )}
              ?
            </div>
            <Row>
              <div className="checkin__buttons">
                <div className="checkin__btn" onClick={fetchCheckIn}>
                  Yes
                </div>
                <a href="/">No, take me back to the screener</a>
              </div>
            </Row>
          </Col>
        </Row>
      );
    }
  };

  const renderResults = () => {
    if (student) {
      let result;
      if (student.quarantined || student.monitored || student.needsReview) {
        // In quarantine or isolation
        result = (
          <Result
            status="error"
            title={'Check In Failed'}
            subTitle={
              <>
                <div>Station: {staff?.displayName}</div>
                <div>
                  {history?.createdAt &&
                    format(new Date(history.createdAt), 'p')}
                </div>
              </>
            }
          ></Result>
        );
      } else if (student.respondedToday) {
        // "healthy"
        result = (
          <Result
            status="success"
            title={`Checked In`}
            subTitle={
              <>
                <div>Station: {staff?.displayName}</div>
                <div>
                  {history?.createdAt &&
                    format(new Date(history.createdAt), 'p')}
                </div>
              </>
            }
          ></Result>
        );
      } else {
        // "unknown" - no screener from today
        result = (
          <div className="ant-result">
            <div className="ant-result-icon">
              <QuestionCircleTwoTone className="checkin__result-icon" />
              <div className="ant-result-title">Screener Not Filled Out</div>
              <div className="ant-result-subtitle">
                <div>Station: {staff?.displayName}</div>
                <div>
                  {history?.createdAt &&
                    format(new Date(history.createdAt), 'p')}
                </div>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="checkin__results-container">
          <Row className="checkin__row">
            <Col
              xl={4}
              lg={8}
              md={16}
              sm={20}
              xs={20}
              className="checkin__result-col"
            >
              {result}
            </Col>
          </Row>
          <a className="checkin__reset" onClick={reset}>
            Check-in again
          </a>
        </div>
      );
    }
  };

  const reset = () => {
    setStudent(undefined);
    setHistory(undefined);
  };

  return (
    <>
      <div className="checkin__header">
        <Header user={user} showLogo={false} onLogout={onLogout} />
      </div>
      <div className="checkin__container">
        <div className="checkin__logo">
          <img
            src={`${process.env.REACT_APP_S3_URL}/assets/images/screener19-logo-v2.png`}
          />
        </div>
        <div className="checkin__results checkin__self">
          {renderPrompt()}
          {renderLoading()}
          {renderResults()}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = { fetchQuestions, setUser };

export default connect(mapStateToProps, mapDispatchToProps)(CheckInSelf);
