import './styles.less';

import React from 'react';

interface Props {
  loading?: boolean;
}

const RefreshingIndicator = ({ loading = false }: Props) => {
  return (
    <div
      className={`dashboard__list-refreshing ${
        loading
          ? 'dashboard__list-refreshing-visible'
          : 'dashboard__list-refreshing-hidden'
      }`}
    >
      Refreshing data...
    </div>
  );
};

export default RefreshingIndicator;
