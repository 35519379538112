import './styles.css';

import * as Sentry from '@sentry/react';

import { Alert, Spin, message, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
  fetchQuestions,
  reset,
  setUser,
  updateAnswers,
} from 'screener19-core/dist/redux';

import CustomScreenerHeader from 'components/CustomScreenerHeader';
import Header from 'components/Header';
import Home from 'components/Home';
import { Question } from 'screener19-core';
import ResponsesApi from 'screener19-core/dist/api/responses';
import Results from 'components/Results';
import ScreenerConfirmation from 'components/ScreenerConfirmation';
import ScreenerSteps from 'components/ScreenerSteps';
import StepHeader from 'components/StepHeader';
import StepTrueFalse from 'components/Steps/StepTrueFalse';
import axiosInstance from 'util/api-util';
import { constants } from 'conf/constants';
import { findTodaysResponse } from 'screener19-core';
import { format } from 'date-fns';
import { useAsyncEffect } from 'use-async-effect';

const Screener = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  let { key } = useParams<any>();
  let containerRef = useRef(null);

  const [finishedScreener, setFinishedScreener] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  const { user } = props;

  const checkForPositiveResponse = (questions: any) => {
    let positive = {
      quarantine: false,
      warn: false,
    };

    for (const question of questions as any) {
      for (const answer of question.answers as any) {
        if (answer.selected && answer.quarantine) {
          positive.quarantine = true;
          break;
        }
        if (answer.selected && answer.warn) {
          positive.warn = true;
          break;
        }
      }
    }

    return positive;
  };

  useEffect(() => {
    if (user?.responses) {
      let todaysResponse = findTodaysResponse(user?.responses);
      if (todaysResponse && key) {
        history.push('/');
      }
    }
  }, [user?.responses]);

  useEffect(() => {
    if (location?.search?.search('logged-in') !== -1) {
      const { localStorage } = window;
      const loginRedirect = localStorage.getItem(
        constants.LOGIN_REDIRECT_STORAGE_KEY
      );
      if (loginRedirect) {
        history.push(loginRedirect);
        return;
      }

      // if staff are not using screener, take to dashboard if canViewDashboard
      if (
        !user?.district?.enableStaffDashboard &&
        user?.permissions?.canViewDashboard
      ) {
        history.push('/dashboard');
        return;
      }

      // if staff are not using screener, take to checkin if canViewCheckin
      if (
        !user?.district?.enableStaffDashboard &&
        user?.permissions?.canViewCheckin
      ) {
        history.push('/checkin');
        return;
      }
    }

    window.localStorage.removeItem(constants.LOGIN_REDIRECT_STORAGE_KEY);
  }, [location, user]);

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user._id });
    }
  }, [user]);

  useEffect(() => {
    // get number of answered questions
    const answered = props.questions.filter((q: any) => !!q.answered);

    // get current question from key in url param
    const currentQuestion = props.questions.find(
      (q: Question) => q.key === key
    );

    // if (!currentQuestion) {
    //   history.push('/');
    //   return;
    // }

    // prevent jumping ahead in the screener
    if (currentQuestion && parseInt(currentQuestion.order) > answered.length) {
      history.push('/');
    }
  }, [props.questions]);

  useEffect(() => {
    const currentQuestion = props.questions.find(
      (q: Question) => q && q.key === key
    );
    if (currentQuestion) {
      setStep(currentQuestion.order);
    }
    const containerElem = (containerRef.current as unknown) as HTMLElement;
    if (containerElem) {
      containerElem.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      window.scrollTo(0, 0);
    }

    // logout user if no token exists
    if (key && !props.jwt && props.onLogoutSuccess) {
      // props.onLogoutSuccess();
      notification.warn({
        message: 'Your login session has expired. Please login again.',
        duration: 0,
      });
    }
  }, [key]);

  useAsyncEffect(async () => {
    if (finishedScreener) {
      try {
        window.scrollTo(0, 0);
        setLoading(true);

        const api = new ResponsesApi(axiosInstance);
        const user: any = await api.add({
          date: new Date(),
          questions: props.questions,
          ...checkForPositiveResponse(props.questions),
          createdAt: new Date(),
          updatedAt: new Date(),
        });

        if (user && user._id) {
          props.setUser(user);
        }

        history.push('/');
      } catch (error) {
        Sentry.captureException(error, { extra: { jwt: props.jwt } });
        console.log(error.response);

        notification.error({
          message: error.response?.data?.data || error.message,
          duration: 10,
        });
      } finally {
        setLoading(false);
        setFinishedScreener(false);
      }
    }
  }, [finishedScreener]);

  const onChangeSelection = async (index: number, items: any) => {
    await dispatch(updateAnswers({ index, items }));
  };

  const onChangeStep = async (stepNumber: number) => {
    if (stepNumber > step) return;
    const question = props.questions.find(
      (q: Question) => q.order === stepNumber
    );

    if (question) {
      history.push(`/screener/${question.key}`);
    }
  };

  const onClearResponse = async () => {
    try {
      const api = new ResponsesApi(axiosInstance);
      await api.clearTodays();
    } catch (error) {
      message.error(error.message);
    }
    // await dispatch(clearTodaysResponse());
    await props.reset({});
  };

  const onPressNext = (step: number) => {
    const question = props.questions.find((q: Question) => q.order === step);
    let done = false;

    for (const answer of question.answers) {
      if (
        answer.selected &&
        (answer.finished || step === props.questions.length - 1)
      ) {
        done = true;
      }
    }

    // if done then submit response
    if (done) {
      history.push('/screener/confirm');
      return;
    }

    // find next step and navigate to it
    const nextStepNumber = step + 1;
    const nextQuestion = props.questions.find(
      (q: Question) => q.order === nextStepNumber
    );

    if (nextQuestion) {
      history.push(`/screener/${nextQuestion.key}`);
    }
  };

  if (!user) {
    return (
      <div>
        <Home onLogout={props.onLogoutSuccess} />
      </div>
    );
  }

  const renderAlerts = () => {
    let alert;

    if (user.quarantined) {
      alert = (
        <Alert
          className="screener__alert"
          message={
            <span>
              <b>
                You are currently in quarantine through{' '}
                {format(new Date(user.quarantineEndAt), 'LLL do')}.
              </b>{' '}
              <br />
              Please fill out the screening but do not come to school until you
              have been cleared by a school nurse.
            </span>
          }
          type="error"
          showIcon
        />
      );
    }
    if (user.monitored) {
      alert = (
        <Alert
          className="screener__alert"
          message={
            <span>
              <b>
                You are currently in isolation through{' '}
                {format(new Date(user.monitoredEndAt), 'LLL do')}.
              </b>{' '}
              <br />
              Please fill out the screening but do not come to school until you
              have been cleared by a school nurse.
            </span>
          }
          type="warning"
          showIcon
        />
      );
    }
    if (user.needsReview) {
      alert = (
        <Alert
          className="screener__alert"
          message={
            <span>
              <b>Your responses have triggered a nursing review.</b> <br />
              Please fill out the screening but do not come to school until you
              have been cleared by a school nurse.
            </span>
          }
          type="warning"
          showIcon
        />
      );
    }

    if (alert) {
      return <div className="screener__alerts">{alert}</div>;
    }
  };

  const renderConfirmation = () => {
    return (
      <Route path="/screener/confirm">
        <ScreenerConfirmation
          questions={props.questions}
          user={user}
          onPressRestart={() => props.reset({})}
          onPressSubmit={() => setFinishedScreener(true)}
        />
      </Route>
    );
  };

  let todaysResponse = findTodaysResponse(user.responses);

  const renderResults = () => {
    return (
      <Route exact path="/">
        {todaysResponse && (
          <>
            <div className="screener__header">
              <Header user={user} onLogout={props.onLogoutSuccess} />
            </div>
            <Results
              response={todaysResponse}
              onPressDone={onClearResponse}
              user={user}
            />
          </>
        )}
        {!todaysResponse && props.questions && props.questions.length > 0 && (
          <Redirect to={`/screener/${props.questions[0].key}`} />
        )}
      </Route>
    );
  };

  const renderScreener = () => {
    return (
      <Route path="/screener/*">
        <div className="screener__header">
          <Header user={user} onLogout={props.onLogoutSuccess} />
          {renderAlerts()}
        </div>
        <Spin spinning={loading}>
          <div className="container-tree" ref={containerRef}>
            <CustomScreenerHeader user={user} district={user.district} />
            <div className="container-inner-tree">
              <ScreenerSteps
                currentStep={step}
                onChangeStep={onChangeStep}
                questions={props.questions}
              />
              {renderConfirmation()}
              {props.questions.map((question: Question, i: number) => {
                const path = `/screener/${question.key}`;
                return (
                  <Route key={question.order} exact path={path}>
                    <div key={question.order}>
                      <StepHeader
                        label={question.text}
                        description={question.description}
                      />
                      <StepTrueFalse
                        multiselect={question.type === 'MultipleChoice'}
                        items={question.answers}
                        onChange={(items: any) =>
                          onChangeSelection(question.order, items)
                        }
                        onPressNext={onPressNext}
                        stepIndex={question.order}
                        noneIndex={
                          question.noneIndex !== undefined
                            ? question.noneIndex
                            : question.answers?.length - 1
                        }
                        userIsQuarantinedOrIsolated={
                          user.quarantined || user.monitored || user.needsReview
                        }
                      />
                    </div>
                  </Route>
                );
              })}
            </div>
          </div>
        </Spin>
      </Route>
    );
  };

  return (
    <>
      {renderResults()}
      {renderScreener()}
      <div className="screener__footer">
        <a href="/privacy.html">Privacy Policy</a> |{' '}
        <a href="/terms.html">Terms of Use</a>
      </div>
    </>
  );
};

const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    questions: state.questions,
    user: state.user,
    jwt: state.auth?.jwt || window.localStorage.JWT || '',
  };
};

const mapDispatchToProps = { fetchQuestions, reset, setUser };

export default connect(mapStateToProps, mapDispatchToProps)(Screener);
