import './styles.css';

import {
  Form,
  Input,
  Space,
  Spin,
  Table,
  Tag,
} from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { School, User } from 'screener19-core';

import { constants } from 'conf/constants';
import { debounce } from 'throttle-debounce';

const { Search } = Input;

interface Props {
  loading: boolean;
  users: Array<User>;
  loadData: () => void;
  onFinish?: (school: School, values: any) => void;
  onSearch: (query: string) => void;
}

const AdminUsers = ({
  loading,
  users,
  onFinish,
  onSearch,
  loadData,
}: Props) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [showForm, setShowForm] = useState(false);

  const onSearchUsers = debounce(200, (query: string) => {
    if (onSearch) {
      onSearch(query);
    }
  });

  useEffect(() => {
    loadData();
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      sorter: (a: any, b: any) => a.displayName.localeCompare(b.displayName),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    {
      title: 'Status',
      dataIndex: 'quarantined',
      key: 'quarantined',
      render: (text: string, record: any) => {
        const status = () => {
          const clear = (
            <Tag className="userprofile__badge" color={constants.HEALTHY_COLOR}>
              healthy
            </Tag>
          );
          const unknown = (
            <Tag className="userprofile__badge" color={'default'}>
              unknown
            </Tag>
          );
          const quarantine = (
            <Tag
              className="userprofile__badge"
              color={constants.QUARANTINE_COLOR}
            >
              quarantined
            </Tag>
          );
          const monitored = (
            <div>
              <Tag
                className="userprofile__badge"
                color={constants.ISOLATION_COLOR}
              >
                isolated
              </Tag>
            </div>
          );
          const needsReview = (
            <div>
              <Tag
                className="userprofile__badge"
                color={constants.PENDING_COLOR}
              >
                pending
              </Tag>
            </div>
          );

          return (
            <Space direction="vertical">
              {!record?.quarantined &&
                !record?.monitored &&
                !record?.needsReview &&
                !record.respondedToday &&
                unknown}
              {!record?.quarantined &&
                !record?.monitored &&
                !record?.needsReview &&
                record.respondedToday &&
                clear}
              {record?.quarantined && quarantine}
              {record?.monitored && monitored}
              {record?.needsReview && needsReview}
            </Space>
          );
        };

        return status();
      },
    },
    {
      title: 'Schools',
      dataIndex: 'schoolNames',
      key: 'schoolNames',
      sorter: (a: any, b: any) => a.schoolNames.localeCompare(b.schoolNames),
    },
  ];

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const validateMessages = {
    required: '${label} is required!',
  };

  return (
    <div className="dashboard__list">
      <Spin spinning={loading}>
        <div className="districts__header">
          <h1 className="header-label">Users</h1>
          <div className="listheader__searchactions">
            <Search
              className="listheader__searchbar"
              placeholder="Search"
              onSearch={onSearchUsers}
              onChange={(e: any) => onSearchUsers(e.target.value)}
              enterButton
            />
          </div>
        </div>
        <Table
          dataSource={users}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`/dashboard/user/${record._id}`);
              }, // click row
            };
          }}
          rowClassName="userslist_row"
        />
      </Spin>
    </div>
  );
};

export default AdminUsers;
