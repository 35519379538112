import './styles.less';

import { Button, DatePicker, Form, Modal, Space } from 'antd';
import React, { useEffect, useState } from 'react';

import moment from 'moment';

const { RangePicker } = DatePicker;

interface Props {
  clearButtonTitle: String;
  label: String;
  onCancel: () => void;
  onFinish: (values: any) => void;
  onPressClear: () => void;
  visible: boolean;
  start?: Date;
  end?: Date;
  title: String;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const UserFormModal = ({
  clearButtonTitle,
  label,
  start,
  end,
  onPressClear,
  onFinish,
  onCancel,
  visible,
  title,
}: Props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [canceling, setCanceling] = useState(false);

  useEffect(() => {
    if (visible) {
      setCanceling(false);
      setLoading(false);
      if (start && end) {
        form.setFieldsValue({ range: [moment(start), moment(end)] });
      }
    }
  }, [visible]);

  const validateMessages = {
    required: '${label} is required!',
  };

  const titleText = start && end ? `Update ${title}` : `Start ${title}`;

  return (
    <Modal
      title={title}
      onCancel={onCancel}
      footer={null}
      visible={visible}
      destroyOnClose={true}
    >
      <Form
        {...layout}
        preserve={false}
        form={form}
        name="control-hooks"
        onFinish={async (values: any) => {
          setLoading(true);
          await onFinish(values);
          setLoading(false);
        }}
        validateMessages={validateMessages}
      >
        <Form.Item name="range" label={label} rules={[{ required: true }]}>
          <RangePicker />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              {titleText}
            </Button>
            {start && (
              <Button
                danger
                onClick={async () => {
                  setCanceling(true);
                  await onPressClear();
                  setCanceling(false);
                }}
                loading={canceling}
              >
                {clearButtonTitle}
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserFormModal;
