import './styles.less';

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
} from 'antd';
import { Note, User } from 'screener19-core';
import React, { useEffect, useState } from 'react';

import moment from 'moment';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface Props {
  onCancel: () => void;
  onDelete?: (id: string) => void;
  onFinish: (values: any) => void;
  onUpdate: (noteId: string, values: any) => void;
  visible: boolean;
  user?: User;
  note?: Note;
}

const UserAddTestFormModal = ({
  onFinish,
  onCancel,
  onDelete,
  onUpdate,
  user,
  visible,
  note,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [title, setTitle] = useState('Add COVID-19 Test');
  const [testResult, setTestResult] = useState<string | undefined>();

  useEffect(() => {
    if (visible) {
      setDeleting(false);
      setLoading(false);
      if (note) {
        setTitle('Update COVID-19 Test');

        form.setFieldsValue({
          ...note,
          covidTestDate: moment(note.covidTestDate),
          covidTestResultDate: moment(note.covidTestResultDate),
        });

        setTestResult(note.covidTestResult);
      }

      // set default primary school value for test
      form.setFieldsValue({
        school: note?.school || user?.schools[0]?._id,
      });
    }
  }, [visible]);

  const validateMessages = {
    required: '${label} is required',
  };

  const onFinishForm = async (values: any) => {
    setLoading(true);
    const data = {
      ...values,
      covidTestResult: testResult,
      district: user?.district,
      type: 'test',
    };

    if (note) {
      if (onUpdate) {
        onUpdate(note._id, data);
      }
      return;
    }

    if (onFinish) {
      onFinish(data);
    }
  };

  const renderIncidentForm = () => {
    return (
      <>
        <Row>
          <Col span={7}>
            <Form.Item
              required
              name="covidTestDate"
              label="COVID-19 Test Date"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Form.Item
              name="covidTestResultDate"
              label="COVID-19 Results Date"
              rules={[{ required: !!testResult }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="covidTestResult" label="COVID-19 Test Result">
              <Checkbox
                checked={testResult === 'positive'}
                value="positive"
                onChange={(e) =>
                  setTestResult(e.target.checked ? e.target.value : '')
                }
              >
                Positive
              </Checkbox>
              <Checkbox
                checked={testResult === 'negative'}
                value="negative"
                onChange={(e) =>
                  setTestResult(e.target.checked ? e.target.value : '')
                }
              >
                Negative
              </Checkbox>
              <Checkbox
                checked={testResult === 'undetermined'}
                value="undetermined"
                onChange={(e) =>
                  setTestResult(e.target.checked ? e.target.value : '')
                }
              >
                Undetermined
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              name="covidTestAdministeredBy"
              label="Test Administered By"
            >
              <Input
                name="covidTestAdministeredBy"
                placeholder="Enter Test Administrator"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="otherDetails" label="Other Details">
              <Input.TextArea
                autoSize={{ minRows: 5, maxRows: 10 }}
                name="otherDetails"
                placeholder="Enter any other relevant details"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="school"
              label="Primary School"
              rules={[{ required: true }]}
            >
              <Select>
                {user?.schools.map((item: any, index: number) => {
                  return (
                    <Select.Option value={item._id}>{item.name}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Modal
      title={title}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          Modal.confirm({
            title: (
              <div>
                <span>Are you sure you want to close the form?</span>
                <small className="incidentform__confirm-desc">
                  You will lose any unsaved changes.
                </small>
              </div>
            ),
            onOk: onCancel,
          });
        } else {
          onCancel();
        }
      }}
      footer={null}
      visible={visible}
      destroyOnClose={true}
      width={800}
      maskClosable={true}
    >
      <Form
        layout="vertical"
        preserve={false}
        form={form}
        name="control-hooks"
        onFinish={onFinishForm}
        validateMessages={validateMessages}
      >
        {renderIncidentForm()}
        <Form.Item>
          <Space>
            <Button loading={loading} type="primary" htmlType="submit">
              {note ? 'Update Test' : 'Save Test'}
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this test?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                if (note && onDelete) {
                  setDeleting(true);
                  onDelete(note._id);
                }
              }}
            >
              {note && (
                <Button loading={deleting} type="default" htmlType="submit">
                  Delete
                </Button>
              )}
            </Popconfirm>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserAddTestFormModal;
