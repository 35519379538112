import { Button, Checkbox, DatePicker, Select, Space, Table } from 'antd';
import { History, User } from 'screener19-core';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { filterAndSortTableData, generateCSV } from 'util/export-util';
import moment, { Moment } from 'moment';

import { ExportOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import Search from 'antd/lib/input/Search';
import { debounce } from 'throttle-debounce';
import format from 'date-fns/format';

const { Option } = Select;

interface Props {
  currentDate?: Moment;
  loading: boolean;
  includeStudents: boolean;
  includeStaff: boolean;
  onChangeDate: (date: Moment) => void;
  onChangeStaffFilter: (value: boolean) => void;
  onChangeStudentsFilter: (value: boolean) => void;
  onChangeStaff: (userIDs: string[]) => void;
  onFetchUsers: () => void;
  onSearchUsers: (query: string) => Promise<void>;
  paginationLabel?: string;
  users: User[];
  searchResults?: User[];
  showNames: boolean;
  staff?: User[];
  userTypeUrlKey?: string;
}

const CheckedInLog = ({
  currentDate,
  loading,
  includeStaff,
  includeStudents,
  onChangeDate,
  onChangeStaffFilter,
  onChangeStudentsFilter,
  onChangeStaff,
  onFetchUsers,
  onSearchUsers,
  paginationLabel = 'students',
  users,
  searchResults,
  showNames,
  staff,
  userTypeUrlKey = 'user',
}: Props) => {
  const history = useHistory();

  const onSearch = debounce(100, (query: string) => {
    if (onSearchUsers) {
      onSearchUsers(query);
    }
  });

  useEffect(() => {
    if (onFetchUsers) {
      onFetchUsers();
    }
  }, []);

  const exportCSV = () => {
    const exportData = filterAndSortTableData(users as any, columns);
    generateCSV(exportData);
  };

  let columns = [
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a: History, b: History) =>
        new Date(a.createdAt) < new Date(b.createdAt),
      render: (text: string, record: History) => {
        return record?.createdAt
          ? format(new Date(record.createdAt), 'Pp')
          : '';
      },
      width: '25%',
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '35%',
      sorter: (a: History, b: History) =>
        a.user?.displayName?.localeCompare(b.user?.displayName),
      render: (text: string, record: History) => {
        const { user } = record;
        const link = `/dashboard/${userTypeUrlKey}/${user?._id}`;
        if (showNames) {
          return (
            <Link
              onClick={(e) => {
                e.stopPropagation();
              }}
              to={link}
            >
              {user?.displayName}
            </Link>
          );
        }

        return (
          <Link
            onClick={(e) => {
              e.stopPropagation();
            }}
            to={link}
          >
            {user?.hiddenName}
          </Link>
        );
      },
    } as any,
    {
      title: 'ID',
      dataIndex: 'studentId',
      key: 'studentId',
      sorter: (a: History, b: History) =>
        a.user?.studentId?.localeCompare(b.user?.studentId),
      render: (text: string, record: History) => {
        const { user } = record;
        return user?.studentId;
      },
    } as any,
    {
      title: 'Checked-in With',
      dataIndex: 'addedByUser',
      key: 'addedByUser',
      width: '20%',
      sorter: (a: History, b: History) =>
        a.addedByUser?.displayName.localeCompare(b.addedByUser?.displayName),
      render: (text: string, record: History) => {
        const { addedByUser } = record;
        const link = `/dashboard/${userTypeUrlKey}/${addedByUser?._id}`;
        if (showNames) {
          return (
            <Link
              onClick={(e) => {
                e.stopPropagation();
              }}
              to={link}
            >
              {addedByUser?.displayName}
            </Link>
          );
        }

        return (
          <Link
            onClick={(e) => {
              e.stopPropagation();
            }}
            to={link}
          >
            {addedByUser?.hiddenName}
          </Link>
        );
      },
    } as any,
  ];

  return (
    <div className="dashboard__list">
      <h3>Select Check-In Date</h3>
      <Space>
        <Form.Item name="checkinDate">
          <DatePicker
            defaultValue={currentDate ? currentDate : moment()}
            onChange={(date) => {
              if (date) {
                onChangeDate(date);
              }
            }}
          />
        </Form.Item>
        <Form.Item name="userFilters">
          <Checkbox
            checked={includeStudents}
            value="students"
            onChange={(e) => {
              onChangeStudentsFilter(e.target.checked);
            }}
          >
            Students
          </Checkbox>
          <Checkbox
            checked={includeStaff}
            value="staff"
            onChange={(e) => {
              onChangeStaffFilter(e.target.checked);
            }}
          >
            Staff
          </Checkbox>
        </Form.Item>
        {staff && staff.length > 0 && (
          <Form.Item name="staffMember">
            <Select
              showSearch
              placeholder="Select staff members"
              onChange={(userIDs: string[]) => {
                onChangeStaff(userIDs);
              }}
              mode="multiple"
              style={{ width: '300px' }}
            >
              {staff?.map((item: any, index: number) => {
                return <Option value={item._id}>{item.displayName}</Option>;
              })}
            </Select>
          </Form.Item>
        )}
      </Space>
      <div>
        <div className="userslist__header">
          <div>
            <h1 className="userslist__headerlabel">Checked-in Report</h1>
            <small>Check-in activity log</small>
          </div>
          <div className="userslist__header-col">
            <div className="userslist__header-button">
              <Button
                type="primary"
                onClick={exportCSV}
                icon={<ExportOutlined />}
              >
                Export to CSV
              </Button>
            </div>
            <Search
              className="userslist__search"
              placeholder="Search"
              onSearch={onSearch}
              onChange={(e: any) => {
                if (onSearch) {
                  onSearch(e.target.value);
                }
              }}
              enterButton
            />
          </div>
        </div>
        <Table
          className="userslist__table"
          dataSource={searchResults || users}
          loading={loading}
          columns={columns}
          onRow={(record: any, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`/dashboard/${userTypeUrlKey}/${record.user._id}`);
              },
            };
          }}
          rowClassName="userslist_row"
          pagination={{
            showSizeChanger: true,
            showTotal: (total: number, range: [number, number]) => {
              return `Showing ${range[0]}-${range[1]} of ${total} ${paginationLabel}`;
            },
          }}
        />
      </div>
    </div>
  );
};

export default CheckedInLog;
