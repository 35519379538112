import './styles.css';

import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Spin,
} from 'antd';
import { District, School } from 'screener19-core';
import React, { useEffect, useState } from 'react';

import SchoolsTable from 'components/SchoolsTable';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

interface Props {
  loading: boolean;
  data: Array<School>;
  districts: Array<District>;
  loadData?: () => void;
  loadDistricts?: () => void;
  onDelete: (id: string) => void;
  onFinish: (values: any) => void;
  onUpdate: (id: string, values: any) => void;
}

const AdminSchools = ({
  loading,
  data,
  districts,
  onDelete,
  onFinish,
  onUpdate,
  loadData,
  loadDistricts,
}: Props) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [showForm, setShowForm] = useState(false);
  const [school, setSchool] = useState<School>();
  const [newDistrictId, setNewDistrictId] = useState<string | undefined>();

  useEffect(() => {
    if (loadData) {
      loadData();
    }
    if (loadDistricts) {
      loadDistricts();
    }
  }, []);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const validateMessages = {
    required: '${label} is required!',
  };

  const onAddSchool = (values: any) => {
    console.log('values', values);

    if (school && onUpdate) {
      onUpdate(school._id, { ...values, district: newDistrictId });
    } else if (onFinish) {
      onFinish({ ...values, district: newDistrictId });
    }
    setShowForm(false);
    setNewDistrictId(undefined);
  };

  const onClickEdit = async (school: School) => {
    setSchool(school);
    form.setFieldsValue(school);
    setShowForm(true);
  };

  const onClickDelete = async (id: string) => {
    onDelete(id);
  };

  const onCloseForm = () => {
    setShowForm(false);
    setSchool(undefined);
  };

  const renderFormButton = () => {
    const btn = school ? 'Update School' : 'Add School';

    return (
      <Button type="primary" htmlType="submit">
        {btn}
      </Button>
    );
  };

  return (
    <div className="dashboard__list">
      <Spin spinning={loading}>
        <div className="districts__header">
          <h1 className="header-label">Schools</h1>
          <Button type="primary" onClick={() => setShowForm(true)}>
            Add School
          </Button>
        </div>
        <SchoolsTable
          data={data}
          onClickDelete={onClickDelete}
          onClickEdit={onClickEdit}
          onClickRow={(id) => history.push(`/admin/schools/${id}`)}
        />
      </Spin>
      <Modal
        title={'Add School'}
        onCancel={onCloseForm}
        footer={null}
        visible={showForm}
        destroyOnClose={true}
      >
        <Form
          {...layout}
          preserve={false}
          form={form}
          name="control-hooks"
          onFinish={onAddSchool}
          validateMessages={validateMessages}
        >
          {!school && (
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-col-8 ant-form-item-label">
                <label className="ant-form-item-required" title="Name">
                  District
                </label>
              </div>
              <div className="ant-col ant-col-16 ant-form-item-control">
                <div className="ant-form-item-control-input">
                  <div className="ant-form-item-control-input-content">
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select a district"
                      optionFilterProp="name"
                      onChange={(districtId: string) => {
                        setNewDistrictId(districtId);
                      }}
                      filterOption={(input, option) => {
                        if (option) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                        return false;
                      }}
                    >
                      {districts.map((item: any, index: number) => {
                        return (
                          <Option key={`district-${index}`} value={item._id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Form.Item name="name" label={'Name'} rules={[{ required: true }]}>
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="address"
            label={'Address'}
            rules={[{ required: false }]}
          >
            <Input placeholder="Address" />
          </Form.Item>
          <Form.Item
            name="addressCity"
            label={'City'}
            rules={[{ required: false }]}
          >
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item
            name="addressState"
            label={'State'}
            rules={[{ required: false }]}
          >
            <Input placeholder="State" />
          </Form.Item>
          <Form.Item
            name="addressZip"
            label={'Zip'}
            rules={[{ required: false }]}
          >
            <Input placeholder="Zip" />
          </Form.Item>
          <Form.Item {...tailLayout}>{renderFormButton()}</Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminSchools;
