import './styles.css';

import { Button, Form, Input, Modal, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';

import { District } from 'screener19-core';
import { useHistory } from 'react-router-dom';

interface Props {
  loading: boolean;
  districts: Array<District>;
  loadData: () => void;
  onFinish: (values: any) => void;
}

const AdminDistricts = ({ loading, districts, onFinish, loadData }: Props) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
  ];

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const validateMessages = {
    required: '${label} is required!',
  };

  return (
    <div className="dashboard__list">
      <Spin spinning={loading}>
        <div className="districts__header">
          <h1 className="header-label">Districts</h1>
          <Button type="primary" onClick={() => setShowForm(true)}>
            Add District
          </Button>
        </div>
        <Table
          dataSource={districts}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                console.log(record);
                history.push(`/admin/districts/${record._id}`);
              }, // click row
            };
          }}
          rowClassName="userslist_row"
        />
      </Spin>
      <Modal
        title={'Add District'}
        onCancel={() => setShowForm(false)}
        footer={null}
        visible={showForm}
        destroyOnClose={true}
      >
        <Form
          {...layout}
          preserve={false}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item name="name" label={'Name'} rules={[{ required: true }]}>
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Add District
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminDistricts;
